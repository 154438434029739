import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";

import {ApiSearchService} from "../services/api-search.service";
import {SelectCriteria} from "../model";
import {LogService} from "../services/log.service";
import {ProgressService} from "../services/progress.service";

declare var $: any;

const DEFAULT_SEARCH_TYPE = "name";

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() format: "full"|"mini" = "full";
  searchForm: FormGroup = new FormGroup({
    searchTerm: new FormControl(''),
    searchCriteria: new FormControl( DEFAULT_SEARCH_TYPE)
  });

  searchTerm = '';
  searchType = DEFAULT_SEARCH_TYPE;

  searchCriteria: Map<string, any[]> = new Map([
    ["artists", [{id: SelectCriteria.NAME, label: "Name"}, {id: SelectCriteria.ISNI, label: "ISNI"}, {id: SelectCriteria.IPI, label: "IPI"}, {id: SelectCriteria.IPN, label: "IPN"},
      {id: SelectCriteria.DISCOGS_ID, label: "Discogs ID"}, {id: SelectCriteria.MUSICBRAINZ_ID, label: "MusicBrainz ID"},
      { id: SelectCriteria.APPLE_ID, label: "Apple ID"}, {id: SelectCriteria.SPOTIFY_ID, label: "Spotify ID"}, { id: SelectCriteria.AMAZON_ID, label: "Amazon ID"},
      { id: SelectCriteria.WIKIDATA_ID, label: "Wikidata ID"}, {id: SelectCriteria.DEEZER_ID, label: "Deezer ID"}
    ]],
    ["recordings", [{id: SelectCriteria.ISRC, label: "ISRC"}]],
    ["releases", [{id: SelectCriteria.UPC, label: "UPC"}]],
    ["works", [{id: SelectCriteria.ISWC, label: "ISWC"}, {id: SelectCriteria.BOWI, label: "BOWI"}]]
  ]);


  contactMessage = '';
  isniRegex = /^\t?\n?\r?\d{4}\ ?\d{4}\ ?\d{4}\ ?\d{3}[\dxX]\t?\n?\r?$/;
  isrcRegex = /^\t?\n?\r?[A-Za-z]{2}-?\w{3}-?\d{2}-?\d{5}\t?\n?\r?$/;          // CA-ZZ0-13-00001
  upcRegex = /^\t?\n?\r?\d{12}\t?\n?\r?$/;                               // 190295245214
  iswcRegex = /^\t?\n?\r?T-?\d{3}\.?\d{3}\.?\d{3}-?\d\t?\n?\r?$/;   // T-905.029.737-5 or T0030546372
  bowiRegex = /\t?\n?\r?B{1}[0-1]{1}-?[\da-zA-Z]{7}\d{1}-?[\da-zA-Z]{1,2}\t?\n?\r?/; // B1FE43TU790 or B1-FE43TU79-0
  musicBrainzIdRegex = /\b[0-9a-f]{8}\b-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-\b[\da-f]{12}\b\t?\n?\r?/;
  deezerIdRegex = /^\t?\n?\r?\d{10}\t?\n?\r?$/;

  constructor(private apiSearchService: ApiSearchService, private progressService: ProgressService, private logService: LogService, private router: Router) {}

  ngOnInit(): void {
    this.initSelectIds();

    this.progressService.progressTopic.subscribe(() => {
      this.searchType = DEFAULT_SEARCH_TYPE;
      this.searchTerm = '';
    });
  }

  isIsni(searchTerm: string|null): boolean {
    return searchTerm !== null && this.isniRegex.test(searchTerm);
  }
  isMusicBrainzId(searchTerm: string|null): boolean {
    return searchTerm !== null && this.musicBrainzIdRegex.test(searchTerm);
  }

  isIsrc(searchTerm: string|null): boolean {
    return searchTerm !== null && this.isrcRegex.test(searchTerm);
  }
  isIswc(searchTerm: string|null): boolean {
    return searchTerm !== null && this.iswcRegex.test(searchTerm);
  }
  isBowi(searchTerm: string|null): boolean {
    return searchTerm !== null && this.bowiRegex.test(searchTerm);
  }
  isUpc(searchTerm: string|null): boolean {
    return searchTerm !== null && this.upcRegex.test(searchTerm);
  }
  isDeezerId(searchTerm: string|null): boolean {
    return searchTerm !== null && this.deezerIdRegex.test(searchTerm);
  }

  initSelectIds(): void {
    if (typeof($) !== "undefined") {
      const select = $('.select2');
      if (select && typeof select.select2 === "function") { select.select2(); }
    }
  }


  placeholder() {
    const searchCriteria = this.searchType;
    if (searchCriteria === SelectCriteria.NAME) { return "Ex: Dua Lipa"; }
    if (searchCriteria === SelectCriteria.ISNI) { return "Ex: 0000000462998427 or 0000 0004 6299 8427"; }
    if (searchCriteria === SelectCriteria.IPI) { return "Ex: 00798414000"; }
    if (searchCriteria === SelectCriteria.IPN) { return "Ex: xxxxx"; }
    if (searchCriteria === SelectCriteria.DISCOGS_ID) { return "Ex: 123456"; }
    if (searchCriteria === SelectCriteria.MUSICBRAINZ_ID) { return "Ex: 6f1a58bf-9b1b-49cf-a44a-6cefad7ae04f"; }
    if (searchCriteria === SelectCriteria.APPLE_ID) { return "Ex: ID1031397873"; }
    if (searchCriteria === SelectCriteria.SPOTIFY_ID) { return "Ex: 6M2wZ9GZgrQXHCFfjv46we"; }
    if (searchCriteria === SelectCriteria.WIKIDATA_ID) { return "Ex: 4537597"; }
    if (searchCriteria === SelectCriteria.AMAZON_ID) { return "Ex: 4537597"; }
    if (searchCriteria === SelectCriteria.DEEZER_ID) { return "Ex: 0123456789"; }
    if (searchCriteria === SelectCriteria.ISRC) { return "Ex: 4537597"; }
    if (searchCriteria === SelectCriteria.ISWC) { return "Ex: T0045882345 or T-004.588.234-5"; }
    if (searchCriteria === SelectCriteria.BOWI) { return "Ex: B1ZKXYPB380 or B1-ZKXYPB38-0"; }
    if (searchCriteria === SelectCriteria.UPC) { return "Ex: 5059460071841"; }
    return ;
  }

  onChange(): void
  {
    const value = this.getSearchTerm();
    if (this.isIsni(value)) { return this.setSearchType(SelectCriteria.ISNI); }
    if (this.isIsrc(value)) { return this.setSearchType(SelectCriteria.ISRC); }
    if (this.isIswc(value)) { return this.setSearchType(SelectCriteria.ISWC); }
    if (this.isBowi(value)) { return this.setSearchType(SelectCriteria.BOWI); }
    if (this.isUpc(value)) {  return this.setSearchType(SelectCriteria.UPC); }
    if (this.isDeezerId(value)) {  return this.setSearchType(SelectCriteria.DEEZER_ID);  }
    if (this.isMusicBrainzId(value)) { return this.setSearchType(SelectCriteria.MUSICBRAINZ_ID); }
    this.setSearchType(SelectCriteria.NAME);
  }
  setSearchType(selectCriteria: SelectCriteria) {
    this.searchForm.get('searchCriteria')?.setValue(selectCriteria);
    this.searchType = selectCriteria;
  }

  getSearchTerm(): string|null{
    return this.searchForm.get('searchTerm')?.value;
  }

  search(): void {
    const searchTerm = this.getSearchTerm();
    if (searchTerm != null && searchTerm !== "") {
      const searchCriteria = this.searchForm.get('searchCriteria')!.value;
      this.logService.recordEntitySearch(searchCriteria, searchTerm);
      this.router.navigate(['/app-home', searchCriteria, searchTerm]);
    }
  }

  getCriteriaLabelById(id: string): string {
    let label = "";
    this.searchCriteria.forEach((value: any[], key: string) => {
      const criteria = value.find((crit: any) => crit.id === id);
      if (criteria) { label = criteria.label; }
    });
    return label;
  }
}
