<!--<div *ngIf="context == 'home'" class="centered">-->
<!--  <img src="assets/images/quansic_explorer.png" height="150px" />-->
<!--</div>-->
<!--<div *ngIf="context == 'home'" class="centered mt-3">-->
<!--  <h1 class="neo-title" style="height: 200px; margin-bottom:-50px">Welcome</h1>-->
<!--</div>-->

<!--<form [formGroup]="searchForm" [ngClass]="{'centered': context === 'home'}" class="mt-5">-->
<!--  <div class=" search mb-3 mt-3" [ngClass]="{'ms-3': context === 'search'}">-->
<!--    &lt;!&ndash;    <div class="col-1">&ndash;&gt;-->
<!--    <select id="searchCriteria" formControlName="searchCriteria" >-->
<!--      <optgroup label="Party">-->
<!--        <option value="name" style="background-color:#bd362f;">Name</option>-->
<!--        <option value="isni">ISNI</option>-->
<!--        <option value="ipi">IPI</option>-->
<!--        <option value="ipn">IPN</option>-->
<!--        <option value="discogs-id">Discogs ID</option>-->
<!--        <option value="musicbrainz-id">MusicBrainz ID</option>-->
<!--        <option value="apple-id">Apple ID</option>-->
<!--        <option value="spotify-id">Spotify ID</option>-->
<!--        <option value="wikidata-id">Wikidata ID</option>-->
<!--        <option value="amazon-id">Amazon ID</option>-->
<!--      </optgroup>-->
<!--      <optgroup label="Release">-->
<!--        <option value="upc">UPC</option>-->
<!--      </optgroup>-->
<!--      <optgroup label="Recording">-->
<!--        <option value="isrc">ISRC</option>-->
<!--      </optgroup>-->
<!--      <optgroup label="Work">-->
<!--        <option value="bowi">BOWI</option>-->
<!--        <option value="iswc">ISWC</option>-->
<!--      </optgroup>-->
<!--    </select>-->
<!--    <input type="text" class="form-control neo-input" [placeholder]="placeholder()" id="searchTerm" required formControlName="searchTerm" (keydown.enter)="search()" (keyup)="onChange()" (paste)="onChange()"/>-->
<!--    <button class="neo-btn neo-btn-out px-3" id="searchButton" (click)="search()">Search</button>-->
<!--  </div>-->
<!--</form>-->


<form  class="search" [formGroup]="searchForm" (submit)="search()">
  <div class="full" *ngIf="format === 'full'">
    <div class="two-col">
      <div class="left">
        <h1>Global Music Repertoire</h1>

        <p style="margin-top: 80px;">Data Explorer is a 100% ID coverage searching tool powered by Quansic which allows  you to navigate through the global music repertoire</p>
        <p>You can connect all artists with their releases and recordings, whether they are main artists or non-featured artists andx know the linkecd IDs such as ISNI, ISRC, UPC, IPI, IPN, etc</p>
        <p>Every month 10'000 new IDs are added to our platform. You can have access to Data Explorer via an API. To have more information you can contact our technical team who will be glad to help you.</p>
        <p>Enjoy your exploration</p>
      </div>
      <div class="right">
        <div style="flex-grow: 1"></div>
        <div class="search-container ">
          <div class="search-input">
            <mat-form-field appearance="fill">
              <input matInput placeholder="" name="searchTerm" required formControlName="searchTerm" (keydown.enter)="search()" (keyup)="onChange()" (paste)="onChange()"/>
            </mat-form-field>
            <button mat-flat-button color="primary" class="btn-search large transparent" type="submit">
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>

          <div class="tags-container">
            <div>
              <div class="tags" *ngFor="let criteriaGroup of searchCriteria | keyvalue">
                <div>{{criteriaGroup.key | titlecase}}</div>
                <ul>
                  <li *ngFor="let criteria of criteriaGroup.value" [ngClass]="{'active': searchType === criteria.id}" (click)="setSearchType(criteria.id)">
                    {{criteria.label}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style="flex-grow: 1"></div>
      </div>
    </div>
  </div>

  <div class="mini" *ngIf="format === 'mini'">
      <mat-form-field appearance="outline" class="search-criteria">
        <mat-select formControlName="searchCriteria" name="Artists">
          <mat-optgroup label="Artists">
            <mat-option *ngFor="let crit of searchCriteria.get('artists')" [value]="crit.id">
              {{crit.label}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Releases">
            <mat-option *ngFor="let crit of searchCriteria.get('releases')" [value]="crit.id">
              {{crit.label}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Recordings">
            <mat-option *ngFor="let crit of searchCriteria.get('recordings')" [value]="crit.id">
              {{crit.label}}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup label="Works">
            <mat-option *ngFor="let crit of searchCriteria.get('works')" [value]="crit.id">
              {{crit.label}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="search-term">
        <input matInput placeholder="" name="searchTerm" required formControlName="searchTerm" (keydown.enter)="search()" (keyup)="onChange()" (paste)="onChange()"/>
      </mat-form-field>
      <button mat-flat-button color="primary" class="btn-search transparent" (click)="search()">
        <mat-icon>arrow_forward</mat-icon>
      </button>
  </div>
</form>

