<table-empty *ngIf="dataSource.data.length == 0" entity="Work"></table-empty>

<div [ngClass]="{'hidden': works.length == 0}">
  <table-controls></table-controls>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="q2">
      <mat-header-cell *matHeaderCellDef mat-sort-header="q2Score"> Q2 </mat-header-cell>
      <mat-cell *matCellDef="let work">{{work.q2Score}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="bowi">
      <mat-header-cell *matHeaderCellDef> BOWI </mat-header-cell>
      <mat-cell *matCellDef="let work">{{work.bowi}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="iswc">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ISWC </mat-header-cell>
      <mat-cell *matCellDef="let work"> {{work.iswc}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Work Title </mat-header-cell>
      <mat-cell *matCellDef="let work">
        <a [routerLink]="['/app-home', 'bowi', work.bowi]" *ngIf="work.bowi">{{work.title}}</a>
        <a [routerLink]="['/app-home', 'iswc', work.iswc]" *ngIf="work.bowi == null && work.iswc">{{work.title}}</a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="artist" >
      <mat-header-cell *matHeaderCellDef mat-sort-header> Artist / Role </mat-header-cell>
      <mat-cell *matCellDef="let work">
        <div *ngFor="let artist of work.contributors" class="contributors">
          <party-link [party]="artist"></party-link><mat-icon>arrow_forward</mat-icon> {{artist.role}}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row matRipple *matRowDef="let work; columns: columns;" ></mat-row>
  </mat-table>
</div>





<!-- <div>
  <div class="tab-title d-inline">{{rows.length}} / {{works.length}} Works</div>
  <div class="ms-2 d-inline float-end" >
    <img src="assets/images/icons/excel.png" class="ms-2" width="30" (click)="exportExcel()"/>
    <img src="assets/images/icons/csv.png" class="ms-2" width="30" (click)="exportCSV()"/>
  </div>
  <table class="table table-bordered">
    <thead>
    <tr>
      <th class="width-1x">BOWI</th>
      <th class="width-1x">ISWC</th>
      <th>Work Title</th>
      <th>Artist Role</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="width-1x"><input type="text" placeholder="Search by BOWI" [(ngModel)]="filterBy.bowi" (keyup)="filter()"/> </td>
      <td class="width-1x"><input type="text" placeholder="Search by ISWC" [(ngModel)]="filterBy.iswc" (keyup)="filter()"/> </td>
      <td><input type="text" placeholder="Search by title" [(ngModel)]="filterBy.title" (keyup)="filter()"/> </td>
      <td><input type="text" placeholder="Search by role" [(ngModel)]="filterBy.role" (keyup)="filter()"/> </td>
    </tr>
    </tbody>
  </table>
  <table class="table table-bordered scroll">
    <tbody>
    <tr *ngFor="let work of rows">
      <td class="width-1x">
        <a *ngIf="work.bowi" [routerLink]="['/app-home', 'bowi', work.bowi]">{{work.bowi}}</a>
      </td>
      <td class="width-1x">
        <a *ngIf="work.iswc" [routerLink]="['/app-home', 'iswc', work.iswc]">{{work.iswc}}</a>
      </td>
      <td>
        <a [routerLink]="['/app-home', (work.bowi)?'bowi':'iswc', (work.bowi)?work.bowi:work.iswc]">{{work.title}}</a>
      </td>
      <td>
        {{work.role}}
      </td>
    </tr>
    </tbody>
  </table>
</div>
 -->
