import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot} from "@angular/router";
import {LoginService} from "./login.service";
import {Observable, of} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(protected router: Router, protected loginService: LoginService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
      if (route.queryParams['key'] === null && !this.loginService.isAuthenticated()) {
        this.router.navigate(['/app-login']);
        return false;
      }
      return true;
  }
}
