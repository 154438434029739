import { ExportService } from '../services/export.service';
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Artist } from '../model';

export type ExportRowsFunction = ((artistName: string, data: any[]) => void)

export type DialogRowsExportData = {
  entityName: string
  exportType: string
  rows: any[]
  exportFn: {
    excel?: ExportRowsFunction,
    csv?: ExportRowsFunction,
    json?: ExportRowsFunction,
  }
}

@Component({
  selector: 'dialog-rows-export',
  templateUrl: './dialog-rows-export.component.html'
})
export class DialogRowsExportComponent {
  constructor(
    private exportService: ExportService,
    public dialogRef: MatDialogRef<DialogRowsExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogRowsExportData) {}

  exportAsExcel() {
    if(this.data.exportFn?.excel){
      this.data.exportFn.excel?.call(this.exportService, this.data.entityName, this.data.rows);
      this.dialogRef.close();
    }
  }

  exportAsCSV() {
    if(this.data.exportFn?.csv){
      this.data.exportFn.csv.call(this.exportService, this.data.entityName, this.data.rows);
      this.dialogRef.close();
    }
  }

  exportAsJson() {
    if(this.data.exportFn.json){
      this.data.exportFn.json.call(this.exportService, this.data.entityName, this.data.rows);
      this.dialogRef.close();
    }
  }

  canExportExcel() {
    return this.data.exportFn.excel !== undefined;
  }

  canExportCSV() {
    return this.data.exportFn.csv !== undefined;
  }

  canExportJson() {
    return this.data.exportFn.json !== undefined;
  }
}
