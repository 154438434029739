import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {Subscription, throwError} from "rxjs";
import {HttpHelperService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private bffApiUrl = this.httpHelperService.getBffDomain();  // URL to web api

  constructor(private http: HttpClient, private httpHelperService: HttpHelperService) {}

  recordLogin(): Subscription {
    return this.http.post(`${this.bffApiUrl}/api/log/login`, {}, {withCredentials: true}).pipe(
     catchError((error) => {
       console.error(error);
       return throwError(error);
     })
    ).subscribe(() => {});
  }

  recordLogout(): Subscription {
    return this.http.post(`${this.bffApiUrl}/api/log/logout`, {}, {withCredentials: true}).pipe(
      catchError((error) => {
        console.error(error);
        return throwError(error);
      })
    ).subscribe(() => {});
  }

  recordArtistDisplay(idType: string, idValue: string): Subscription {
    return this.recordEntityDisplay("Artist", idType, idValue);
  }
  recordRecordingDisplay(idType: string, idValue: string): Subscription {
    return this.recordEntityDisplay("Recording", idType, idValue);
  }
  recordReleaseDisplay(idType: string, idValue: string): Subscription {
    return this.recordEntityDisplay("Release", idType, idValue);
  }
  recordWorkDisplay(idType: string, idValue: string): Subscription {
    return this.recordEntityDisplay("Work", idType, idValue);
  }
  recordEntityDisplay(entityType: string, idType: string, idValue: string): Subscription {
    return this.http.post(`${this.bffApiUrl}/api/log/entityDisplay`, {
      entityType,
      idType,
      idValue
    }, {withCredentials: true}).pipe(
      catchError((error) => {
        console.error(error);
        return throwError(error);
      })
    ).subscribe(() => {});
  }

  recordEntitySearch(entityType: string, searchTerm: string): Subscription {
    return this.http.post(`${this.bffApiUrl}/api/log/entitySearch`, {
      entityType,
      searchTerm
    }, {withCredentials: true}).pipe(
      catchError((error) => {
        console.error(error);
        return throwError(error);
      })
    ).subscribe(() => {});
  }
}
