<div class='mat-typography'>
  <h1 mat-dialog-title>{{coverData.title}}</h1>
  <div mat-dialog-content>
    <img [src]='coverData.cover' width='auto' height='100%'/>
    <div *ngIf='licenseInfo !== null'>
      <div style="margin-top: 10px">
        <a [href]="licenseInfo.link" target="_blank">{{licenseInfo.label}}</a>
      </div>
      <div *ngIf="licenseInfo.attribution !== null">{{licenseInfo.attribution}}</div>
    </div>
    <div style="margin-top: 1em" *ngIf="isWikimediaImage(coverData.originalUrl)">
      <a class="link" [href]="coverData.originalUrl" target="_blank" >
        Open on Wikimedia Commons to list authors
      </a>
    </div>
  </div>
</div>
