import { Component, inject, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map } from "rxjs";
import { ConfirmDialog, ConfirmDialogData } from "src/app/edit/confirm-dialog/confirm-dialog.component";
import { InlineEditService } from "src/app/services/inline-edit.service";
import { Recording, Work } from "../../model";




@Component({
  selector: 'dialog-crtc',
  templateUrl: './dialog-crtc.component.html',
  styleUrls: ['./dialog-crtc.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogCrtcComponent {
    inlineEditService = inject(InlineEditService)
    dialog = inject(MatDialog)
    dialogRef = inject(MatDialogRef<DialogCrtcComponent>)
    data = inject(MAT_DIALOG_DATA);

    recording!: Recording
    works: Work[] = []
    crComments: any = {}

    editMode$ = this.inlineEditService.editMode$
    changeRequestsCount$ = this.inlineEditService.changeRequests$.pipe(
      map(crs => crs.length),
      map(count => (count > 0 ? count : null))
    )

    ngOnInit() {
      this.recording = this.data.recording;
      if(this.data.works) {
        this.works = this.data.works;
      }
    }

    commitAllChangeCandidates() {
      // crComments has been populated with the comments in the change-requests component
      this.inlineEditService.commitAllChangeCandidates({...this.crComments});
      this.dialogRef.close()
    }

    discardAllChangeCandidates() {
      this.dialog.open(ConfirmDialog, {
        data: {
          title: 'Discard all change candidates',
          content: 'Are you sure you want to discard all change candidates ?',
          action: {
            label: 'Discard All Changes',
            callback: () => {
              this.inlineEditService.discardAllChangeCandidates()
              this.dialogRef.close()
            }
          }
        } as ConfirmDialogData
      })
    }

    cancelAndClose() {
      if(this.inlineEditService.changeRequests.length > 0)
        this.discardAllChangeCandidates()
      else this.dialogRef.close()
    }
}


