import { RecordingOfWork, WorkOfRecording } from './../model';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import {Artist, Identifiers, NameVariant, Recording, Release, Work} from "../model";
import {DurationPipe} from "../pipes/duration.pipe";


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_TYPE = 'text/plain;charset=utf-8';
const CSV_EXTENSION = '.csv';
const JSON_TYPE = 'text/json;charset=utf-8';
const JSON_EXTENSION = '.json';

const RECORDING_HEADERS = ['isrc', 'apple track id', 'spotify track id', 'title', 'subtitle', 'duration', 'main artists', 'performers'];
const RELEASE_HEADERS = ["upc", "type", "title", "year", "label"];
const WORK_HEADERS = ["bowi", "iswc", "title", "artist role"];
const CONTRIBUTORS_HEADERS = ["name", "role"]
const RECORDING_COL_WIDTH = [15, 15, 30, 30, 25, 10, 20, 20];
const RELEASE_COL_WIDTH = [15, 15, 40, 5, 30];
const WORK_COL_WIDTH = [15, 15, 40, 30];

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  exportCsv$ = new Subscription();
  exportExcel$ = new Subscription();

  constructor(private durationPipe: DurationPipe) {

  }

  getMainArtists(recording: Recording):string|undefined{
    return recording.mainArtists?.map((ma: Artist) => {return ma.name; }).join("|")
  }

  getPerformers(recording: Recording):string|undefined{
    return recording.performers?.map((p: Artist) => { return p.name + " (" + p.role + ")"}).join("|")
  }

  mapRecordings(recordings: Recording[]) {
    const result = recordings.map((rec) => {
      return [rec.isrc, rec.appleId, rec.spotifyId, rec.title, rec.subtitle, this.durationPipe.transform(rec.duration), this.getMainArtists(rec), this.getPerformers(rec)];
    });
    result.unshift(RECORDING_HEADERS);
    return result;
  }

  mapRecordingsOfWork(recordings: RecordingOfWork[]) {
    const result = recordings.map((rec) => {
      return [rec.isrc, rec.appleId, rec.spotifyId, rec.title, rec.subtitle, this.durationPipe.transform(rec.duration), this.getMainArtists(rec), this.getPerformers(rec), rec.q2Score];
    });
    result.unshift([...RECORDING_HEADERS, 'q2Score']);
    return result;
  }

  mapReleases(releases: Release[]){
    const result = releases.map((rel) => {
      return [rel.upc, rel.type, rel.title, rel.year, rel.label];
    });
    result.unshift(RELEASE_HEADERS);
    return result;
  }

  mapWorks(works: Work[]){
    const result =  works.map((work) => {
      return [work.bowi, work.iswc,  work.title, work.role];
    });
    result.unshift(WORK_HEADERS);
    return result;
  }

  mapWorksOfRecording(works: WorkOfRecording[]){
    const result =  works.map((work) => {
      return [work.bowi, work.iswc,  work.title, work.role, work.q2Score];
    });
    result.unshift([...WORK_HEADERS, 'q2Score']);
    return result;
  }

  toRecordingsWorksheet(recordings:Recording[]){
    let result = this.mapRecordings(recordings);
    let ws = XLSX.utils.aoa_to_sheet(result);
    ws['!cols'] = RECORDING_COL_WIDTH.map(col => {return {width: col}});
    return ws;
  }

  toRecordingsOfWorkWorksheet(recordings:Recording[]){
    let result = this.mapRecordingsOfWork(recordings);
    let ws = XLSX.utils.aoa_to_sheet(result);
    ws['!cols'] = [...RECORDING_COL_WIDTH, 20].map(col => {return {width: col}});
    return ws;
  }

  toReleasesWorksheet(releases:Release[]){
    let result = this.mapReleases(releases);
    let ws = XLSX.utils.aoa_to_sheet(result);
    ws['!cols'] = RELEASE_COL_WIDTH.map(col => {return {width: col}});
    return ws;
  }

  toWorksWorksheet(works:Work[]){
    let result = this.mapWorks(works);
    let ws = XLSX.utils.aoa_to_sheet(result);
    ws['!cols'] = WORK_COL_WIDTH.map(col => {return {width: col}});
    return ws;
  }

  toWorksOfRecordingWorksheet(works:Work[]){
    let result = this.mapWorksOfRecording(works);
    let ws = XLSX.utils.aoa_to_sheet(result);
    ws['!cols'] = [...WORK_COL_WIDTH, 20].map(col => {return {width: col}});
    return ws;
  }

  toContributorsWorksheet(contributors: Artist[]){
    let result: any[] = [CONTRIBUTORS_HEADERS];
    if(contributors) result = result.concat(contributors.map(a => [a.name, a.role]))
    let ws = XLSX.utils.aoa_to_sheet(result);
    ws['!cols'] = [20, 100].map(col => {return {width: col}});
    return ws;
  }

  public exportAsExcelTable(worksheet: XLSX.WorkSheet, excelFileName: string): void {
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    ExportService.saveAsFile(excelBuffer, excelFileName, EXCEL_TYPE, EXCEL_EXTENSION);
  }

  public exportAsCSVFile(lines: any[], csvFileName: string): void {
    const separator = ",";
    const csvContent =
      lines.map((line) => {
        return line.map((cell:any) => {
          if(typeof cell === "undefined" || cell == null) cell = "";
          return "\"" + cell + "\"";
        }).join(separator);
      }).join("\n");

    ExportService.saveAsFile(csvContent, csvFileName, CSV_TYPE, CSV_EXTENSION);
  }

  static saveAsFile(buffer: any, fileName: string, type:string, extension:string): void {
    const data: Blob = new Blob([buffer], {type: type});
    FileSaver.saveAs(data, fileName + ' export ' + new  Date().getTime() + extension);
  }

  artistToArray(artist:Artist){
    return [
      ["Name", artist.name],
      ["Type", artist.type],
      ["Gender", artist.gender],
      ["Comments", artist.comments],
      ["Birth date", artist.birthdate],
      ["Death date", artist.deathdate],
      ["Nationality", artist.nationality]
    ]
  }

  identifiersToArray(identifiers:Identifiers){
    return [
      ["ISNI",            identifiers.isnis?.join(",")],
      ["IPI",             identifiers.ipis?.join(",")],
      ["IPN",             identifiers.ipns?.join(",")],
      ["Discogs ID",      identifiers.discogsIds?.join(",")],
      ["MusicBrainz ID",  identifiers.musicBrainzIds?.join(",")],
      ["Apple ID",        identifiers.appleIds?.join(",")],
      ["Spotify ID",      identifiers.spotifyIds?.join(",")],
      ["Wikidata ID",     identifiers.wikidataIds?.join(",")],
      ["Amazon ID",       identifiers.amazonIds?.join(",")],
      ["Deezer ID",       identifiers.deezerIds?.join(",")],
      ["Merged ID",       identifiers.mergedIsnis?.join(",")]
    ]
  }

  recordingInformationToArray(recording: Recording) {
    return [
      ["Title",               recording.title],
      ["Subtitle",            recording.subtitle],
      ["Duration",            this.durationPipe.transform(recording.duration)],
      ["Year of Recording",   recording.year?.toString()],
      ["Country of Recording",recording.country],
      ["P line",              recording.pLine],
    ]
  }

  recordingIdentifiersToArray(recording: Recording) {
    return [
      ["ISRC",            recording.isrc],
      ["SpotifyTrackId",  recording.spotifyId],
      ["AppleTrackId",    recording.appleId],
    ]
  }

  releaseInformationToArray(release: Release) {
    return [
      ["Title",    release.title],
      ["Type",     release.type],
      ["Year",     release.year],
      ["Label",    release.label],
      ["Country",  release.country],
    ]
  }

  releaseIdentifiersToArray(release: Release) {
    return [
      ["UPC",      release.upc],
    ]
  }

  workIdentifiersToArray(work: Work) {
    return [
      ["ISWC",      work.iswc],
      ["BOWI",      work.bowi],
    ]
  }

  workInformationToArray(work: Work) {
    return [
      ["Title",    work.title],
    ]
  }

  relationshipsToArray(artist:Artist) {
    const type = artist.type?.toLowerCase() == "person" ? "Is Member Of" : "Has Member";
    const rel = artist.type?.toLowerCase() == "person" ? artist.relationships?.isMemberOf : artist.relationships?.hasMember;
    let result = [[type,""]];
    if(rel) {
      rel.forEach((r) => {
        result.push(["", r.name]);
      })
    }
    return result;
  }

  nameVariantsToArray(nameVariants:NameVariant[]):string[][]{
    let result:string[][] = [];
    if(nameVariants) {
      nameVariants.forEach((nv) => {
        result.push([nv.fullname]);
      });
    }
    return result;
  }


  /** Public export methods */
  public exportRecordingsAsCSV(artistName:string, data:Recording[]) {
    const lines = this.mapRecordings(data);
    this.exportAsCSVFile(lines, artistName + " recordings");
  }

  public exportReleasesAsCSV(artistName:string, data:Release[]) {
    const lines = this.mapReleases(data);
    this.exportAsCSVFile(lines, artistName + " releases");
  }

  public exportWorksAsCSV(artistName:string, data:Work[]) {
    const lines = this.mapWorks(data);
    this.exportAsCSVFile(lines, artistName + " works");
  }

  public exportWorksOfRecordingAsCSV(artistName:string, data:WorkOfRecording[]) {
    const lines = this.mapWorksOfRecording(data);
    this.exportAsCSVFile(lines, artistName + " works");
  }

  public exportRecordingsOfWorkAsCSV(artistName:string, data:RecordingOfWork[]) {
    const lines = this.mapRecordingsOfWork(data);
    this.exportAsCSVFile(lines, artistName + " recordings");
  }

  public exportRecordingsAsExcel(artistName:string, data:Recording[]) {
    const ws = this.toRecordingsWorksheet(data);
    this.exportAsExcelTable(ws, artistName + " recordings");
  }

  public exportReleasesAsExcel(artistName:string, data:Release[]) {
    const ws = this.toReleasesWorksheet(data);
    this.exportAsExcelTable(ws, artistName + " releases");
  }

  public exportWorksAsExcel(artistName:string, data:Work[]) {
    const ws = this.toWorksWorksheet(data);
    this.exportAsExcelTable(ws, artistName + " works");
  }

  public exportWorksOfRecordingAsExcel(artistName:string, data:WorkOfRecording[]) {
    const ws = this.toWorksOfRecordingWorksheet(data);
    this.exportAsExcelTable(ws, artistName + " works");
  }

  public exportRecordingsOfWorkAsExcel(artistName:string, data:RecordingOfWork[]) {
    const ws = this.toRecordingsOfWorkWorksheet(data);
    this.exportAsExcelTable(ws, artistName + " works");
  }

  exportPartyAsJson(party: Artist, releases:any[], recordings:any[], works: any[], excelFileName: string): void {
    const exportJson = {
      ...party,
      releases,
      recordings,
      works
    }

    ExportService.saveAsFile(JSON.stringify(exportJson, null, 4), party.name, JSON_TYPE, JSON_EXTENSION);
  }

  public exportPartyAsExcel(party: Artist, releases:any[], recordings:any[], works: any[], excelFileName: string): void {
    const ids = this.identifiersToArray(party.ids);
    const artist = this.artistToArray(party);
    const relationships = this.relationshipsToArray(party);
    const nameVariants = this.nameVariantsToArray(party.namevariants||[]);

    const worksheet_ids: XLSX.WorkSheet = this.formatIdentifiersWorksheet(XLSX.utils.aoa_to_sheet(ids));
    const worksheet_artist: XLSX.WorkSheet = this.formatArtistWorksheet(XLSX.utils.aoa_to_sheet(artist));
    const worksheet_namevariants: XLSX.WorkSheet = this.formatNameVariantsWorksheet(XLSX.utils.aoa_to_sheet(nameVariants));
    const worksheet_relationships: XLSX.WorkSheet = this.formatRelationshipsWorksheet(XLSX.utils.aoa_to_sheet(relationships));
    const worksheet_recordings: XLSX.WorkSheet = this.toRecordingsWorksheet(recordings);
    const worksheet_releases: XLSX.WorkSheet = this.toReleasesWorksheet(releases);
    const worksheet_works: XLSX.WorkSheet = this.toWorksWorksheet(works);

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet_artist, "Artist");
    XLSX.utils.book_append_sheet(workbook, worksheet_ids, "Identifiers");
    XLSX.utils.book_append_sheet(workbook, worksheet_relationships, "Relationships");
    XLSX.utils.book_append_sheet(workbook, worksheet_namevariants, "Name Variants");
    XLSX.utils.book_append_sheet(workbook, worksheet_releases, "Releases");
    XLSX.utils.book_append_sheet(workbook, worksheet_recordings, "Recordings");
    XLSX.utils.book_append_sheet(workbook, worksheet_works, "Works");

    XLSX.writeFile(workbook, excelFileName + EXCEL_EXTENSION);
  }

  exportRecordingAsExcel(recording: Recording, works: WorkOfRecording[], releases: Release[], mainArtists: Artist[], performers: Artist[], excelFileName: string) {
    const worksheet_recording_ids: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.recordingIdentifiersToArray(recording));
    const worksheet_recording_info: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.recordingInformationToArray(recording));
    const worksheet_mainartists: XLSX.WorkSheet = this.toContributorsWorksheet(mainArtists);
    const worksheet_performers: XLSX.WorkSheet = this.toContributorsWorksheet(performers);
    const worksheet_releases: XLSX.WorkSheet = this.toReleasesWorksheet(releases);
    const worksheet_works: XLSX.WorkSheet = this.toWorksOfRecordingWorksheet(works);

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet_recording_ids, "Identifiers");
    XLSX.utils.book_append_sheet(workbook, worksheet_recording_info, "Information");
    XLSX.utils.book_append_sheet(workbook, worksheet_mainartists, "Main Artists");
    XLSX.utils.book_append_sheet(workbook, worksheet_performers, "Performers");
    XLSX.utils.book_append_sheet(workbook, worksheet_releases, "Releases");
    XLSX.utils.book_append_sheet(workbook, worksheet_works, "Works");

    XLSX.writeFile(workbook, excelFileName + EXCEL_EXTENSION);
  }

  exportReleaseAsExcel(release: Release, recordings: Recording[], contributors: Artist[], excelFileName: string) {
    const worksheet_release_ids: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.releaseIdentifiersToArray(release));
    const worksheet_release_info: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.releaseInformationToArray(release));
    const worksheet_contributors: XLSX.WorkSheet = this.toContributorsWorksheet(contributors);
    const worksheet_recordings: XLSX.WorkSheet = this.toRecordingsWorksheet(recordings);

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet_release_ids, "Identifiers");
    XLSX.utils.book_append_sheet(workbook, worksheet_release_info, "Information");
    XLSX.utils.book_append_sheet(workbook, worksheet_contributors, "Contributors");
    XLSX.utils.book_append_sheet(workbook, worksheet_recordings, "Recordings");

    XLSX.writeFile(workbook, excelFileName + EXCEL_EXTENSION);
  }

  exportWorkAsExcel(work: Work, recordings: RecordingOfWork[], contributors: Artist[], excelFileName: string) {
    const worksheet_work_ids: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.workIdentifiersToArray(work));
    const worksheet_work_info: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.workInformationToArray(work));
    const worksheet_contributors: XLSX.WorkSheet = this.toContributorsWorksheet(contributors);
    const worksheet_recordings: XLSX.WorkSheet = this.toRecordingsOfWorkWorksheet(recordings);

    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet_work_ids, "Identifiers");
    XLSX.utils.book_append_sheet(workbook, worksheet_work_info, "Information");
    XLSX.utils.book_append_sheet(workbook, worksheet_contributors, "Contributors");
    XLSX.utils.book_append_sheet(workbook, worksheet_recordings, "Recordings");

    XLSX.writeFile(workbook, excelFileName + EXCEL_EXTENSION);
  }


  formatArtistWorksheet(ws:XLSX.WorkSheet){
    ws['!cols'] = [
      {width: 15},
      {width: 35}
    ]
    return ws;
  }
  formatIdentifiersWorksheet(ws:XLSX.WorkSheet){
    ws['!cols'] = [
      {width: 25},
      {width: 35}
    ]
    return ws;
  }
  formatRelationshipsWorksheet(ws:XLSX.WorkSheet){
    ws['!cols'] = [
      {width: 10},
      {width: 35}
    ]
    return ws;
  }
  formatNameVariantsWorksheet(ws:XLSX.WorkSheet){
    ws['!cols'] = [
      {width: 35},
    ]
    return ws;
  }
}


