import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ArtistService} from "../services/artist.service";

@Component({
  selector: 'identifier-pill',
  templateUrl: './identifier-pill.component.html',
  styleUrls: ['./identifier-pill.component.css']
})
export class IdentifierPillComponent implements OnChanges {
  @Input() name;
  @Input() values: string[];
  @Input() color;
  @Input() linkTemplate:string|null;
  links:string[] = [];

  constructor(private artistService: ArtistService) {
    this.name = "";
    this.color = "red";
    this.values = [];
    this.linkTemplate = null;
  }

  ngOnChanges(): void {
    this.links = [];
    if(this.values != null){
      (this.values as Array<string>).forEach((value) => {
        if(this.linkTemplate != null) {
          const valueRaw = this.artistService.formatIdRaw(this.name, value);
          this.links?.push(this.linkTemplate.replace("__ID__", valueRaw));
        }
      })
    }
  }
}
