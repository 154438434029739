import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isrc'
})
export class IsrcPipe implements PipeTransform {
  isrcRegexExt = /^[A-Z]{2}-\w{3}-\d{2}-\d{5}$/;
  isrcRegex = /^([A-Z]{2})(\w{3})(\d{2})(\d{5})$/;

  transform(value: string|undefined): string {
    if(typeof(value) === "undefined") return "";
    if(this.isrcRegexExt.test(value)) return value;
    const r = this.isrcRegex.exec(value) || [];
    if(r.length == 5){
      return r[1]+"-"+r[2]+"-"+r[3]+"-"+r[4]; // US-CA2-06-01263
    } else {
      return value;
    }
  }
}
