import {AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Artist, Recording, Release } from "../model";
import {ResultService} from "../services/result.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TabsController} from "../_common/tabs.controller";
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { TableRecordingsComponent } from '../table-recordings/table-recordings.component';
import { ExportService } from '../services/export.service';
import { ImageService } from '../services/image.service';
import { DialogCoverComponent } from '../dialog-cover/dialog-cover.component';

@Component({
  selector: 'app-release',
  templateUrl: './app-release.component.html',
  styleUrls: ['./app-release.component.scss']
})
export class AppReleaseComponent extends TabsController implements OnInit, AfterViewInit, OnChanges {
  @Input() release:Release;
  @Input() artists:Array<Artist>;
  @Input() recordings:Array<Recording>;

  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;
  @ViewChild(TableRecordingsComponent) tableRecordingsComponent!: TableRecordingsComponent;

  defaultReleaseCover = 'assets/images/release_default.jpg';

  recordingsLength:number|null = null;

  constructor(private resultService:ResultService,
    private dialog: MatDialog,
    private exportService: ExportService,
    public imageService: ImageService,
    private router:Router,
    private route:ActivatedRoute) {
    super(router, route);
    this.release = this.resultService.emptyRelease();
    this.artists = [];
    this.recordings = [];
  }

  ngOnInit(): void {
    this.setRecordingsRole();
  }

  ngOnChanges() {
    this.readActiveTabFromParam(this.matTabGroup);
  }

  ngAfterViewInit(){
    this.readActiveTabFromParam(this.matTabGroup);
  }

  setRecordingsRole() {
    this.recordings.forEach((rec:Recording) => {
      if(rec.mainArtists === null || typeof rec.mainArtists === "undefined") rec.mainArtists = [];
      rec.contributors?.forEach((contrib:Artist) => {
        if(contrib.contributorType === "MainArtist") {
          rec.mainArtists?.push(contrib);
        }
      })
    })
  }

  onRecordingsComplete(event:any){
    this.recordingsLength = event;
  }

  openCoverDialog() {
    this.dialog.open(DialogCoverComponent, {
      width: '45vw',
      data: {
        title: this.release.title,
        cover: this.imageService.convertImagePath(this.release.cover) || this.defaultReleaseCover,
        originalUrl: this.release.cover
      }
    })
  }

  exportRelease() {
    this.exportService.exportReleaseAsExcel(this.release, this.recordings, this.artists, this.release.title)
  }
}



