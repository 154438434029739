import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Progress} from "../model";
import {Subscription} from "rxjs";
import {ProgressService} from "../services/progress.service";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent  {
  progressSubscription:Subscription;
  isLoading:boolean = false;

  constructor(private progressService:ProgressService) {
    this.progressSubscription = this.progressService.getProgressTopic().subscribe((progress:Progress) => {
      this.isLoading = !!progress.queryInProgress;
    })
  }

}
