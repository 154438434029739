<div class="layout-grid">
  <div class="maipl" >
    <mat-list *ngIf="maiplScore$|async as crtcMaiplScore">
      <mat-list-item>
          <mat-checkbox [checked]="crtcMaiplScore.music" color="accent"
            [disabled]="!(inlineEditService.editMode$|async)"
            (change)="editMaiplScore(crtcMaiplScore, 'music')">Music</mat-checkbox>
      </mat-list-item>
      <mat-list-item >
        <mat-checkbox [checked]="crtcMaiplScore.artist" color="accent"
          [disabled]="!(inlineEditService.editMode$|async)"
          (change)="editMaiplScore(crtcMaiplScore, 'artist')">Artist</mat-checkbox>
      </mat-list-item>
      <mat-list-item>
        <mat-checkbox [checked]="crtcMaiplScore.performance" color="accent"
          [disabled]="!(inlineEditService.editMode$|async)"
          (change)="editMaiplScore(crtcMaiplScore, 'performance')">Performance</mat-checkbox>
      </mat-list-item>
      <mat-list-item>
        <mat-checkbox [checked]="crtcMaiplScore.instrument" color="accent"
          [disabled]="!(inlineEditService.editMode$|async)"
          (change)="editMaiplScore(crtcMaiplScore, 'instrument')">Instrument</mat-checkbox>
      </mat-list-item>
      <mat-list-item>
        <mat-checkbox [checked]="crtcMaiplScore.lyrics" color="accent"
          [disabled]="!(inlineEditService.editMode$|async)"
          (change)="editMaiplScore(crtcMaiplScore, 'lyrics')">Lyrics</mat-checkbox>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item>
        <mat-checkbox [checked]="isCanadianContent$|async" color="accent"
        [disabled]="!(inlineEditService.editMode$|async)"
        (change)="editMaiplIsCancon(recording.crtcMaplStatus)">Is Cancon ?</mat-checkbox>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="recording" [formGroup]="form">
    <h2>Recording: <span style="font-weight: 300; margin-left: 10px">{{recording.id}}</span> <span style="font-weight: 100; margin-left: 10px">({{recording.title}})</span></h2>
    <div class="recording-table-container">
      <mat-table formArrayName="recordingContributors" [dataSource]="recordingContribDS"
        *ngIf="recordingContribDS.data.length > 0; else noContributors" class="crtc-table">
        <ng-container matColumnDef="isni" >
          <mat-header-cell *matHeaderCellDef> ISNI </mat-header-cell>
          <mat-cell *matCellDef="let contributor">{{contributor.get('displayId').value}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="name" >
          <mat-header-cell *matHeaderCellDef> Contributor </mat-header-cell>
          <mat-cell *matCellDef="let contributor">{{contributor.get('name').value}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="role" >
          <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
          <mat-cell *matCellDef="let contributor">
            {{contributor.get('role').value}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nationality" >
          <mat-header-cell *matHeaderCellDef> Nationality (Source) </mat-header-cell>
          <mat-cell *matCellDef="let contributor; let idx = index" [formGroupName]="idx">
            <mat-select-country formControlName="nationalityAlpha2" appearance="legacy"
            (onCountrySelected)="editNationality(contributor.value)"></mat-select-country>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns.contributors"></mat-header-row>
        <mat-row matRipple *matRowDef="let contributor; columns: columns.contributors;" ></mat-row>
      </mat-table>
    </div>
  </div>
  <div class="works" [formGroup]="form">
    <ng-container *ngIf="work !== undefined; else noWork">
      <h2>Work: <span style="font-weight: 300; margin-left: 10px">{{work.bowi||work.iswc}}</span> <span style="font-weight: 100; margin-left: 10px">({{work.title}})</span></h2>
      <div class="work-table-container">
        <mat-table formArrayName="workContributors" [dataSource]="workContribDS">
          <ng-container matColumnDef="isni" >
            <mat-header-cell *matHeaderCellDef> ISNI </mat-header-cell>
            <mat-cell *matCellDef="let contribOfWork">{{contribOfWork.get('displayId').value}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Contributor </mat-header-cell>
            <mat-cell *matCellDef="let contribOfWork">{{contribOfWork.get('name').value}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="role" >
            <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
            <mat-cell *matCellDef="let contribOfWork">{{contribOfWork.get('role').value}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="nationality" >
            <mat-header-cell *matHeaderCellDef> Nationality (Source) </mat-header-cell>
            <mat-cell *matCellDef="let contribOfWork; let idx = index" [formGroupName]="idx">
              <mat-select-country formControlName="nationalityAlpha2" appearance="legacy"
              (onCountrySelected)="editNationality(contribOfWork.value)"></mat-select-country>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columns.works"></mat-header-row>
          <div  style="height: 250px; overflow-y: auto">
            <mat-row matRipple *matRowDef="let contribOfWork; columns: columns.works;"></mat-row>
          </div>
        </mat-table>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noNationality><em>not defined</em></ng-template>

<ng-template #noWork>
  <h2>No Work associated</h2>
</ng-template>

<ng-template #noContributors>
  No Contributors
</ng-template>
