import { formatNumber } from '@angular/common';
import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';

@Component({
  selector: 'counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements  AfterViewInit,OnChanges {
  @Input() duration: number = 400;
  @Input() digit: number|null = 0;
  @Input() steps: number = 12;
  @ViewChild("animatedDigit") animatedDigit: ElementRef = {} as ElementRef;

  animateCount() {
    this.counterFunc(this.digit, this.duration, this.animatedDigit);
  }

  counterFunc(endValue:number|null, durationMs:number, element:ElementRef) {
    if(endValue === null) return element.nativeElement.textContent = "-";

    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = endValue / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;
    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;
      element.nativeElement.textContent = formatNumber(Math.min(Math.abs(Math.ceil(currentValue)), endValue || 0), 'en-US', '1.0-0');

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
    return;
  }

  ngAfterViewInit() {
      this.animateCount();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["digit"] && this.animatedDigit.nativeElement != null) {
      this.animateCount();
    }
  }
}
