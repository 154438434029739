import { InlineEditService } from './../../services/inline-edit.service';
import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'dialog-confirm',
  templateUrl: './dialog-confirm.component.html'
})
export class DialogConfirmComponent {
  inlineEditService: InlineEditService = inject(InlineEditService);
  dialogRef: MatDialogRef<DialogConfirmComponent> = inject(MatDialogRef<DialogConfirmComponent>);

  discardChanges() {
    this.inlineEditService.discardAllChangeCandidates();
    this.inlineEditService.disableEditMode()
    this.dialogRef.close();
  }
}
