<!-- <div class="modal fade show" id="defaultModalPrimary" tabindex="-1" [style.display]="isLoading ? 'block' : 'none'" aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <img src="assets/images/quansic_anim.gif" width="auto" height="auto"/>
  </div>
</div> -->

<!-- <div class="modal" [style.display]="isLoading ? 'block' : 'none'">
  <div class="backdrop"></div>
  <img src="assets/images/quansic_anim.gif" />
</div> -->

<div class="modal" [style.display]="isLoading ? 'block' : 'none'">
  <div class="backdrop"></div>
  <mat-spinner color="accent"></mat-spinner>
</div>

