import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  templateUrl: './dialog-additional-ids.component.html',
  styleUrls: ['./dialog-additional-ids.component.scss']
})
export class DialogAdditionalIdsComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogAdditionalIdsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){  }

}
