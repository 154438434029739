<div class="entity-page">
  <div class="name-block">
    <h1>Release:</h1>
    <div class="name">
      <h1>{{release.title}}</h1>
    </div>
    <button mat-icon-button class="link export" (click)="exportRelease()" title="Download release data">
      <mat-icon [inline]="true">arrow_circle_down</mat-icon>
    </button>
    <!-- <button mat-icon-button class="link export" (click)="export()" title="Download artist data">
      <mat-icon [inline]="true">arrow_circle_down</mat-icon>
    </button> -->
  </div>
  <div class="info">
    <div class="picture">
      <div (click)="openCoverDialog()" class="link">
        <img class="circle-mask circle-mask-large" [src]="imageService.convertImagePath(release.cover) || defaultReleaseCover"/>
      </div>
    </div>
    <div style="flex: 0 1 1em"></div>
    <div class="informations">
      <h2>Informations</h2>
      <div><span class="strong">Type:</span> {{release.type}}</div>
      <div><span class="strong">Year of Recording:</span> {{release.year}}</div>
      <div><span class="strong">Label of Recording:</span> {{release.label}}</div>
    </div>
    <div class="identifiers">
      <h2>Identifiers</h2>
      <div class="identifiers-list">
        <div class="identifier"><span class="strong">UPC:</span> {{release.upc}}</div>
      </div>
    </div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)">
      <mat-tab label="Contributors">
        <ng-container *ngTemplateOutlet="contributors;context:{release: release}" ></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Recordings</div>
          <div class="data-count" *ngIf="tableRecordingsComponent">({{tableRecordingsComponent.dataSource.data.length}})</div>
        </ng-template>
        <table-recordings [recordings]="recordings"></table-recordings>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #contributors let-artist="release">
  <div class="relationships" *ngIf="artists && artists.length > 0">
    <div class="title">Main Artists</div>
    <div class="grid grid-four-col">
      <party-link [party]="artist" *ngFor="let artist of artists | sortBy:'asc':'name'"></party-link>
    </div>
  </div>
</ng-template>


<!-- <div class="row mt-3 ms-3 main-section">
  <div class="col-2">
    <a data-bs-toggle="modal" data-bs-target="#coverModal" style="cursor: pointer">
      <img src="{{release.cover || 'assets/images/nocover.jpg'}}" width="100%" height="auto" class="cover me-2 mb-2"/>
    </a>
  </div>
  <div class="col-8">
    <div class="row">
      <h1 class="d-inline">{{release.title}}</h1>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="neo-section">
          <ul class="list-unstyled">
            <li class="mt-2"><h6>UPC:&nbsp;{{release.upc}}</h6></li>
            <li>Type: {{release.type}}</li>
            <li>Year: {{release.year}}</li>
            <li>Label: {{release.label}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="tab">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" [ngClass]="{'active': tabActive == TABS.Relationships}" [routerLink]="[]" [queryParams]="{tab: 0}">
          Contributors ({{artists.length}})
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'disabled': recordingsLength == null,'active': tabActive == TABS.Recordings}" [routerLink]="[]" [queryParams]="{tab: 2}">
          Recordings (<span *ngIf="recordingsLength == null">_</span><span *ngIf="recordingsLength != null">{{recordingsLength}}</span>)
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane" [ngClass]="{'active': tabActive == TABS.Relationships}" >
        <div class="row">
          <div class="col">
            <ul class="list-unstyled">
              <li *ngFor="let artist of artists"><party-link [party]="artist" style="margin:0 5px"></party-link></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="tab-pane" [ngClass]="{'active': tabActive == TABS.Recordings}">
        <table-recordings [recordings]="recordings" [name]="release.title" (onComplete)="onRecordingsComplete($event)"></table-recordings>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="coverModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{release.title}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body ">
        <img src="{{release.cover || 'assets/images/nocover.jpg'}}" width="100%" height="auto"/>
      </div>
    </div>
  </div>
</div> -->
