import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppHomeComponent } from './app-home/app-home.component';
import {AuthGuard} from "./services/auth-guard";
import {AppRegisterComponent} from "./login/app-register/app-register.component";
import { AppLoginComponent } from './login/app-login/app-login.component';
import { AppRecoverPasswordComponent } from './login/app-recover-password/app-recover-password.component';
import { AppLoginKeyComponent } from './login/app-login-key/app-login-key.component';

const routes: Routes = [
  { path: '', redirectTo: 'app-login', pathMatch: 'full'},
  { path: 'app-register', 	component: AppRegisterComponent },
  { path: 'app-recover', 	component: AppRecoverPasswordComponent },
  { path: 'app-login', 	component: AppLoginComponent },
  { path: 'app-login-key', 	component: AppLoginKeyComponent},
  { path: 'app-home/any', 	component: AppHomeComponent, canActivate: [AuthGuard] },
  { path: 'app-home/:idType/:id', 	component: AppHomeComponent,  canActivate: [AuthGuard]  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
