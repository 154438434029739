<ng-template #artist let-artists="artists">
  <div class="artist link" *ngFor="let artist of artists" matRipple>
    <a [routerLink]="['/app-home', 'quansicId', artist.ids.quansicId]">
      <img class="circle-mask" [src]="imageService.convertImagePath(artist.cover) || '/assets/images/no-artist.jpg'"/>
      <div class="info">
        <h4 style="margin-bottom: 5px;">{{artist.name}}</h4>
        <em style=" color: white">{{artist.comments}}</em>
        <ul>
          <li>Type: {{artist.type}}</li>
          <li *ngIf="artist.type === 'Person'">Gender: {{artist.gender}}</li>
          <li>Nationality: {{artist.nationality}}</li>
        </ul>
      </div>
    </a>
  </div>
</ng-template>


<div class="top-results-container">
  <h2>Top Results</h2>
  <div class="results top-results">
    <div class="grid-view">
      <ng-container *ngTemplateOutlet="artist;context:{artists: topResultsArtists}" ></ng-container>
    </div>
  </div>
</div>
<div class="results-container" [ngClass]="{'hide': (otherResultsArtists$|async)!.length == 0}">
  <h2>Other Results
    <span class="view-select">
      <mat-icon [ngClass]="{'active':  isGridView()}" (click)="setViewToGrid()">grid_view</mat-icon>
      <mat-icon [ngClass]="{'active':  isListView()}" (click)="setViewToList()">list</mat-icon>
    </span>
  </h2>
  <div class="results">
    <div class="grid-view" [ngClass]="{'hide': isListView()}">
      <ng-container *ngTemplateOutlet="artist;context:{artists: otherResultsArtists$|async}" ></ng-container>
    </div>
    <div class="list-view" [ngClass]="{'hide': isGridView()}" style="padding-left: 50px">
      <mat-table [dataSource]="otherResultsDatasource" matSort>
        <ng-container matColumnDef="cover">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let artist">
            <img class="circle-mask circle-mask-mini"  [src]="imageService.convertImagePath(artist.cover) || '/assets/images/no-artist.jpg'"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Name </mat-header-cell>
          <mat-cell *matCellDef="let artist">
            <span class="artist link" (click)="selectArtist(artist)">{{artist.name}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
          <mat-cell *matCellDef="let artist"> {{artist.company}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="gender">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Gender </mat-header-cell>
          <mat-cell *matCellDef="let artist"> {{artist.gender}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nationality" >
          <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Nationality </mat-header-cell>
          <mat-cell *matCellDef="let artist"> {{artist.nationality}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="comments" >
          <mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Comments </mat-header-cell>
          <mat-cell *matCellDef="let artist"> {{artist.comments}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="listViewProps.columns"></mat-header-row>
        <mat-row matRipple *matRowDef="let artist; columns: listViewProps.columns;"></mat-row>
      </mat-table>
      <!-- <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons (page)="listViewProps.handlePaginatoreEvent($event)"></mat-paginator> -->
    </div>
  </div>
</div>

<!-- <div>
  <div class="row">
    <div class="col mx-5">
      <h3>{{artists.length}} result<ng-container *ngIf="artists.length > 1">s</ng-container> found</h3>
      <a (click)="setViewToGrid()" *ngIf="isListView()" href="javascript:;"><i class="ion ion-md-grid me-2"></i>show grid view</a>
      <a (click)="setViewToList()" *ngIf="isGridView()" href="javascript:;"><i class="ion ion-md-list me-2"></i>show list view</a>
    </div>
  </div>
  <div class="row" *ngIf="artists.length > 0">
    <div class="col-12" *ngIf="isGridView()">
      <div class="card-group mx-5" >
        <ng-container *ngFor="let artist of artists" >
          <div class="card neo-section mt-3 px-0 me-2" *ngIf="artistHasIds(artist)">
            <party-link [party]="artist">
                             <img class="card-img-top" src="{{artist.cover " width="100px" height="100px"/>
              <div [style.backgroundImage]="'url(' + (artist.cover || '/assets/images/no-artist.jpg') + ')'" class="artist-thumbnail" ></div>
              <div class="card-header">
                <h2 class="card-title mb-0">{{artist.name}}</h2>
              </div>
              <div class="card-body">
                <ul class="list-unstyled">
                  <li>Type: {{artist.type}}</li>
                  <li>Gender: {{artist.gender}}</li>
                  <li>Nationality:&nbsp;{{artist.nationality.toUpperCase()}}&nbsp;<span class="flag-icon flag-icon-{{artist.nationality.toLowerCase()}}"></span> </li>
                </ul>
                <p class="card-text comments">{{artist.comments || '&nbsp;'}}</p>
              </div>
            </party-link>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-10 mx-5" *ngIf="isListView()">
        <table class="table">
          <thead>
          <tr>
            <th style="width:60px">Cover</th>
            <th style="width:5%">Nationality</th>
            <th style="width:5%">Type</th>
            <th>Name</th>
            <th style="width:35%">Comments</th>
            <th style="width:35%">Popularity</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td style="width:60px"></td>
            <td class="width-1x"><input type="text" placeholder="Search by Nationality" [(ngModel)]="filterBy.nationality" (keyup)="filter()"/> </td>
            <td class="width-1x"><input type="text" placeholder="Search by type" [(ngModel)]="filterBy.type" (keyup)="filter()"/> </td>
            <td><input type="text" placeholder="Search by name" [(ngModel)]="filterBy.name" (keyup)="filter()"/> </td>
            <td><input type="text" placeholder="Search by comments" [(ngModel)]="filterBy.comments" (keyup)="filter()"/> </td>
            <td class="width-1x"></td>
          </tr>
          <tr *ngFor="let artist of rows">
            <td style="width:60px"><img src="{{artist.cover || '/assets/images/no-artist.jpg'}}" width="48" height="48" class="rounded-circle me-2"></td>
            <td>{{artist.nationality.toUpperCase()}}&nbsp;<span class="flag-icon flag-icon-{{artist.nationality.toLowerCase()}}"></span> </td>
            <td>{{artist.type}}</td>
            <td><party-link [party]="artist">{{artist.name}}</party-link></td>
            <td>{{artist.comments}}</td>
            <td>{{artist.popularity}}</td>
          </tr>
          </tbody>
        </table>
      </div>
  </div>
  <div *ngIf="artists.length == 0">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="neo-title mt-5">Oops</h1>
        <p class="text-center h1 mt-7">It seems we can't find any artist that match that name [{{searchTerm}}]</p>
        <p  class="text-center h1">Feel free to <a href="#" data-bs-toggle="modal" data-bs-target="#contactModal">report an incident.</a></p>
      </div>
    </div>
  </div>
</div> -->
