<div class="entity-page">
  <div class="name-block">
    <h1>Work:</h1>
    <div class="name">
      <h1>{{work.title}}</h1>
    </div>
    <button mat-icon-button class="link export" (click)="exportWork()" title="Download work data">
      <mat-icon [inline]="true">arrow_circle_down</mat-icon>
    </button>
    <!-- <button mat-icon-button class="link export" (click)="export()" title="Download artist data">
      <mat-icon [inline]="true">arrow_circle_down</mat-icon>
    </button> -->
  </div>
  <div class="info">
    <div class="picture">
      <img class="circle-mask circle-mask-large" src="{{'assets/images/work_default.jpg'}}"/>
    </div>
    <!-- <div class="informations">
      <h2>Informations</h2>
    </div> -->
    <div style="flex: 0 1 1em"></div>
    <div class="identifiers">
      <h2>Identifiers</h2>
      <div class="identifiers-list">
        <div class="identifier"><span class="strong">BOWI:</span> {{work.bowi}}</div>
        <div class="identifier"><span class="strong">ISWC:</span> {{work.iswc}}</div>
      </div>
    </div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)">
      <mat-tab label="Contributors">
        <ng-container *ngTemplateOutlet="contributors;context:{work: work}" ></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Recordings</div>
          <div class="data-count" *ngIf="tableRecordingsComponent">({{tableRecordingsComponent.dataSource.data.length}})</div>
        </ng-template>
        <table-recordings [recordings]="recordings" [q2]="true"></table-recordings>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #contributors let-work="work">
  <div class="relationships" *ngIf="work && work.contributors.length > 0" >
    <div class="title">Contributors</div>
    <div class="grid grid-two-col">
      <ng-container *ngFor="let artist of work.contributors | sortBy:'asc':'name'">
        <party-link [party]="artist" ></party-link> {{artist.role}}
      </ng-container>
    </div>
  </div>
</ng-template>


<!-- <div class="row mt-3 ms-3 main-section">
  <div class="col-2">
    <img src="assets/images/default_work_icon.jpeg" width="100%" height="auto" class="cover me-2 mb-2"/>
  </div>
  <div class="col-8">
    <div class="row">
      <h1 class="d-inline">{{work.title}}</h1>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="neo-section">
          <ul class="list-unstyled">
            <li class="mt-2"><h6>BOWI: {{work.bowi}}</h6></li>
            <li class="mt-2"><h6>ISWC:&nbsp;{{work.iswc}}</h6></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="tab">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item"><a class="nav-link active" href="#tab-1" data-bs-toggle="tab" role="tab">Contributors ({{work.contributors ? work.contributors.length : 0}})</a></li>
      <li class="nav-item"><a class="nav-link" href="#tab-2" data-bs-toggle="tab" role="tab">Recordings ({{recordings.length}})</a></li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="tab-1" role="tabpanel">
        <div class="row">
          <div class="col">
            <ul class="list-unstyled">
              <li *ngFor="let artist of work.contributors">
                <party-link [party]="artist" style="margin:0 5px"></party-link> <i class="ion ion-md-arrow-forward mx-1"></i> {{artist.role}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="tab-2" role="tabpanel">
        <table-recordings [recordings]="recordings" [name]="work.title" (onComplete)="onRecordingsComplete($event)"></table-recordings>
      </div>
    </div>
  </div>
</div> -->
