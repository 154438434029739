<div style="font-size: 2rem;line-height: 2rem;">
  <div>
    <h1>Oops</h1>
    <p *ngIf="notFound()" >
      It seems the
      <ng-container *ngIf="isArtist()">artist</ng-container>
      <ng-container *ngIf="isRelease()">release</ng-container>
      <ng-container *ngIf="isRecording()">recording</ng-container>
      <ng-container *ngIf="isWork()">work</ng-container>
      you are looking for doesn’t have any data <span style="font-size: 0.75em">({{identifiers.idType}} {{identifiers.id}})</span>
    </p>
    <p *ngIf="invalidId()" >It seems the identifier you have typed is malformed. Please check</p>
    <p *ngIf="unauthorized()">The registration key that you have provided is not valid. Please <a (click)="logout()">logout</a> and enter a valid registration key.</p>
    <p *ngIf="serverError()">It seems there is a problem connecting to our servers <span style="font-family: 'courier', monospace; font-size: 0.8em">[{{errorMsg}}]</span>. It should be corrected soon. </p>
    <p>Feel free to <a class="link" (click)="openFeedbackDialog()">report an incident.</a></p>
  </div>
</div>
