import { HttpHelperService } from './http.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface LicenseInfo {
  link: string|null
  label: string|null
  attribution: string|null
}

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  http = inject(HttpClient);
  httpHelperService = inject(HttpHelperService);

  private bffApiUrl = this.httpHelperService.getBffDomain();  // URL to web api

  getImageLicence$(url: string): Observable<LicenseInfo | null> {
    if(url.includes('wikimedia.org')){
      return this.http.get(`${this.bffApiUrl}/api/license?url=${url}`, {responseType: 'json'}).pipe(
        map(response => (response as LicenseInfo))
      )
    }
    return of(null);
  }
}
