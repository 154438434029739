import packageJson from '../../package.json';
export const environment = {
  version: packageJson.version,
  demo: false,
  dataTest: false,
  production: true,
  apiBaseUrl: "origin",
  xPath: 'api/x',
  yPath: 'api/y',
  clientPath: 'api/client',
  emailPath: 'api/email',
  logPath: 'api/log',
  environmentName: 'dev',
  legacyDomain: 'https://explorer-v1.quansic.com',
  q2Score: true
};
