import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(timeInMs: number): string {
    if(timeInMs == null) timeInMs = 0;
    if(timeInMs == 0) return "";

    const duration = moment.duration(timeInMs);
    const hours = duration.hours().toString().padStart(2, '0')
    const minutes = duration.minutes().toString().padStart(2, '0')
    const seconds = duration.seconds().toString().padStart(2, '0')
    return (duration.hours()>0 ? hours + ":":"") + minutes + ":" + seconds;
  }

}
