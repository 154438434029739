<div class="mat-typography">
  <h1 mat-dialog-title>Need some help ?</h1>
  <div mat-dialog-content>
    <h2>Search page :</h2>
    <p>
      How do I find what I’m looking for on Data Explorer ?
      You have several search requests possible :
    </p>
    <ul class="list-unstyled">
      <li>By artist name or its ISNI</li>
      <li>By ISRC</li>
      <li>By UPC</li>
    </ul>

    <p>If you have several artists with the same name you can choose the  one you are looking for according to its ISNI or description. (ex: UK singer or Swedish band)</p>
    <h2>Artist page</h2>
    <p>Here you will find all the recordings, the releases and the relationships of the artist but also personal informations like the real name, date of birth, and different ID’s (MusicBrainz, Discogs, Apple etc)</p>
    <p>You will be able to navigate through all the recordings and releases with all its details by clicking on the one you want to look up close</p>
    <p>The releases are by default sorted by release date</p>
    <p>You can search for a specific release or recording by doing a research on each column</p>

    <h2>Recording page</h2>
    <p>On the recording page you will find all informations about the recording like it’s title, subtitle, Label, ISRC but also an extract of it.</p>
    <p>A search bar is here to help you narrow down your search. On the contributors tab you will also find featured and non featured artist</p>
    <p>You will be able to navigate through all the recordings and releases with all its details by clicking on the one you want to look up close</p>

    <h2>Release page</h2>
    <p>On the release page you will find the title of the release, all it’s recordings, the year and UPC</p>
    <p>A search bar is here to help you narrow down your search. On the contributors tab you will also find featured and non featured artist</p>

    <h2>Export</h2>
    <p>You can export the data from the request you did on any page by clicking on the buttons on the right of the table (csv, pdf or excel)</p>

    <h2>What if there are no data on the artist I’m looking for ?</h2>
    <p>If you don’t find an artist  please contact us the artist you are looking for may not be in our database yet, we will do our best to implement it.</p>
    <p>Did you verify the spelling is correct? Sometimes searching by IDs (ISNI, IPN, IPI) is more accurate</p>
    <p>In some rare cases it is possible that an artist has several ISNIs, try with the artist’s name or one of its ISRC or UPC.</p>

    <p style="padding-left: 0px">You didn’t find the answer you were looking for ? Please contact us we will do our best to reply as fast as possible and if needed we can schedule a call to look in details your request</p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </div>
</div>
