import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PerfService {
  marks:any[]|null = null;

  constructor() { }

  startMeasure() {
    this.marks = [{name: "start", ts: Date.now()}]
  }

  mark(name:string) {
    if(this.marks == null) return;
    this.marks.push({name: name, ts: Date.now()})
    const i = this.marks.length - 1;
    const duration = (i > 0) ? this.marks[i].ts - this.marks[i-1].ts : 0;
  }

  end(name:string|null){
    if(this.marks == null) return;
    this.marks.push({name: name || "end", ts: Date.now()})
  }

  printResults() {
    if(this.marks === null) return;
    for (let i = 0; i < this.marks.length; i++) {
      const duration = (i > 0) ? this.marks[i].ts - this.marks[i-1].ts : 0;
    }
  }
}
