import { Router } from '@angular/router';
import { Component, inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoginService } from '@githubquansic/web-auth/ng';

@Component({
  selector: 'app-recover-password',
  templateUrl: './app-recover-password.component.html',
  styleUrls: ['./app-recover-password.component.scss']
})
export class AppRecoverPasswordComponent {
  loginService = inject(LoginService);

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })
  queryInProgress = false;
  emailSent = false;
  errorMessage: string|null = null;

  constructor(private router: Router){}

  sendRecoverPasswordEmail() {
    this.queryInProgress = true;
    const email = this.form.get('email')?.value;
    if(email && email !== null) {
      this.loginService.sendRecoverPasswordEmail(email).subscribe({
        next: () => {
          this.queryInProgress = false
          this.emailSent = true;
        },
        error: (e) => {
          this.queryInProgress = false
          this.errorMessage = e.error?.error.message ? e.error?.error.message : e;
        }
      })
    }
  }


  navigateToLoginPage(){
    this.router.navigateByUrl('/app-login');
  }
}
