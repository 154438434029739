<div class="stats" [ngClass]="{'hidden': stats == null}">
  <div class="stats-title"><counter [digit]="stats?.totalAssets || null"></counter> Music Identifiers</div>
  <div>
      <div class="stats-element"><counter [digit]="stats?.parties || null" class="bold"></counter>Artists</div>
      <div class="stats-element"><counter [digit]="stats?.partyIds || null" class="bold"></counter>Artist Identifiers</div>
      <div class="stats-element"><counter [digit]="stats?.recordings || null" class="bold"></counter>Recordings</div>
      <div class="stats-element"><counter [digit]="stats?.releases || null" class="bold"></counter>Releases</div>
      <div class="stats-element"><counter [digit]="stats?.works || null" class="bold"></counter>Works</div>
  </div>
</div>
