import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iswc'
})
export class IswcPipe implements PipeTransform {
  iswcRegexExt = /^T-[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]$/;
  iswcRegex = /^(T)([0-9]{3})([0-9]{3})([0-9]{3})([0-9])$/

  transform(value: string|undefined): string {
    if(typeof(value) === "undefined") return "";
    if(this.iswcRegexExt.test(value)) return value;
    const r = this.iswcRegex.exec(value) || [];
    if(r.length == 6){
      return r[1]+"-"+r[2]+"."+r[3]+"."+r[4]+"-"+r[5]; // /T7001673583
    } else {
      return value;
    }
  }

}
