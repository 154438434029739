import { DialogTermsComponent } from './../dialog-terms/dialog-terms.component';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dialog-policy',
  templateUrl: './dialog-policy.component.html',
  styleUrls: ['./dialog-policy.component.css']
})
export class DialogPolicyComponent{

  constructor(private dialog: MatDialog) {}

  showTermsAndConditionsDialog() {
    this.dialog.closeAll();
    this.dialog.open(DialogTermsComponent,  {
      panelClass: "dialog",
      autoFocus: false
    });
  }

}
