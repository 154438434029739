<div class="entity-page">
  <div class="name-block">
    <h1>Recording:</h1>
    <div class="name">
      <h1>{{recording.title}}</h1>
      <em class="comments">{{recording.subtitle}}</em>
    </div>
    <button mat-icon-button class="link export" (click)="exportRecording()" title="Download recording data">
      <mat-icon [inline]="true">arrow_circle_down</mat-icon>
    </button>
    <!-- <button mat-icon-button class="link export" (click)="export()" title="Download artist data">
      <mat-icon [inline]="true">arrow_circle_down</mat-icon>
    </button> -->
  </div>
  <div class="info">
    <div class="picture">
      <audio-player *ngIf="recording.audioUrl != '' && recording.audioUrl != null else cover" format="full" [audioUrl]="recording.audioUrl" [trackIsrc]="recording.isrc" [trackName]="recording.title"></audio-player>
      <ng-template #cover>
        <img class="circle-mask circle-mask-large" src="{{'assets/images/recording_default.jpg'}}"/>
      </ng-template>
    </div>
    <div style="flex: 0 1 1em"></div>
    <div class="informations">
      <h2>Informations</h2>
      <div><span class="strong">Duration:</span> {{recording.duration | duration}}</div>
      <div><span class="strong">Year of Recording:</span> {{recording.year}}</div>
      <div><span class="strong">Country of Recording:</span> {{recording.country}}</div>
      <div><span class="strong">Main artists:</span>
        <span *ngFor="let artist of mainArtists | sortBy:'asc':'name'">
          <party-link [party]="artist" style="margin:0 5px"></party-link>
        </span>
      </div>
      <div>&#9413; {{recording.pLine}}</div>
    </div>
    <div class="identifiers">
      <h2>Identifiers</h2>
      <div class="identifiers-list">
        <div class="identifier"><span class="strong">ISRC:</span> {{recording.isrc}}</div>
        <div class="identifier"><span class="strong">Spotify Track Id:</span>  <a href="https://open.spotify.com/track/{{recording.spotifyId}}" target="_blank">{{recording.spotifyId}}</a></div>
        <div class="identifier"><span class="strong">Apple Track Id:</span> {{recording.appleId}}</div>
      </div>
    </div>
    <div class="crtc" *ngIf="crtcService.isCrtcUser()">
      <h2>CRTC <cancon-flag [isCancon]="recording.crtcMaplStatus?.isCanadianContent"></cancon-flag></h2>
      <div>MAIPL score: <maipl-score [score]="crtcMaiplScore$()|async"></maipl-score>
      </div>
      <div>Is Cancon: {{recording.crtcMaplStatus?.isCanadianContent ? 'Yes' : 'No'}}</div>
      <div><button class="link" mat-button class="link" (click)="openCrtcDetailsDialog()">Edit</button></div>
      <div><changelog [entityId]="recording.isrc"></changelog></div>
    </div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)">
      <mat-tab label="Contributors">
        <ng-container *ngTemplateOutlet="contributors;context:{recording: recording}" ></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Releases</div>
          <div class="data-count" *ngIf="tableReleasesComponent">({{tableReleasesComponent.dataSource.data.length}})</div>
        </ng-template>
        <table-releases [releases]="releases" [name]="recording.title" ></table-releases>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Works</div>
          <div class="data-count" *ngIf="tableWorksComponent">({{tableWorksComponent.dataSource.data.length}})</div>
        </ng-template>
        <table-works [works]="works" [recording]="recording" [q2]="true"></table-works>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #contributors let-artist="recording">
  <div class="relationships" *ngIf="mainArtists && mainArtists.length > 0">
    <div class="title">Main Artists</div>
    <div class="grid grid-four-col">
      <party-link [party]="mainArtist" *ngFor="let mainArtist of mainArtists | sortBy:'asc':'name'"></party-link>
    </div>
    <hr class="grid-col-span-2"/>
  </div>
  <div class="relationships" *ngIf="performers && performers.length > 0">
    <div class="title">Non Featured</div>
    <div class="grid grid-two-col">
      <ng-container *ngFor="let performer of performers | sortBy:'asc':'name'">
        <party-link [party]="performer"></party-link> ({{performer.role}})
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- <div class="row mt-3 ms-3 main-section">
  <div class="col-2">
    <img src="assets/images/recording.jpg" width="100%" height="auto" class="cover me-2 mb-2"/>
  </div>
  <div class="col-8">
    <div class="row">
      <h1 class="d-inline">{{recording.title}}</h1>
      <p style="font-size: 1.3em"><em>{{recording.subtitle}}</em></p>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <div class="neo-section">
          <ul class="list-unstyled">
            <li><strong>Duration</strong>: {{recording.duration | duration}}</li>
            <li><strong>Year of Recording</strong>: {{recording.year}}</li>
            <li><strong>Country of Recording</strong>: {{recording.country}}</li>
            <li><strong>Main artists:</strong>
              <span *ngFor="let artist of mainArtists">
                <party-link [party]="artist" style="margin:0 5px"></party-link>
              </span>
            </li>
            <li><strong>&#9413;</strong> {{recording.pLine}}</li>
          </ul>
        </div>
      </div>
      <div class="col-6">
        <div class="neo-section">
          <ul class="list-unstyled">
            <li class="mt-2"><h6>ISRC: &nbsp;{{recording.isrc}}</h6></li>
            <li class="mt-2"><strong>Spotify Track Id</strong>: <a href="https://open.spotify.com/track/{{recording.spotifyId}}" target="_blank">{{recording.spotifyId}}</a></li>
            <li class="mt-2"><strong>Apple Track Id</strong>: {{recording.appleId}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <audio-player *ngIf="recording.audioUrl != '' && recording.audioUrl != null" [radius]="35" [audioUrl]="recording.audioUrl" [trackIsrc]="recording.isrc" [trackName]="recording.title"></audio-player>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5">
  <div class="tab">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': tabActive == TABS.Relationships}" [routerLink]="[]" [queryParams]="{tab: 0}">Contributors</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'disabled': releasesLength == null, 'active': tabActive == TABS.Releases}" [routerLink]="[]" [queryParams]="{tab: 1}">
          Releases (<span *ngIf="releasesLength == null">_</span><span *ngIf="releasesLength != null">{{releasesLength}}</span>)
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'disabled': worksLength == null, 'active': tabActive == TABS.Works}" [routerLink]="[]" [queryParams]="{tab: 3}">
          Works (<span *ngIf="worksLength == null">_</span><span *ngIf="worksLength != null">{{worksLength}}</span>)
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane" [ngClass]="{'active': tabActive == TABS.Relationships}">
        <div class="row">
          <div class="col">
            <div class="ms-1 mt-3">
              <div><strong>Main Artists</strong></div>
              <ul class="list-unstyled">
                <li *ngFor="let artist of mainArtists"><party-link [party]="artist" style="margin:0 5px"></party-link></li>
              </ul>
            </div>
            <div class="ms-1 mt-3">
              <div><strong>Non Featured</strong></div>
              <span *ngIf="performers == null || performers.length == 0" class="ms-1">None</span>
              <ul class="list-unstyled">
                <li *ngFor="let performer of performers" style="margin:0 5px"><party-link [party]="performer"></party-link> ({{performer.role}})</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" [ngClass]="{'active': tabActive == TABS.Releases}">
        <table-releases [releases]="releases" [name]="recording.title" (onComplete)="onReleasesComplete($event)"></table-releases>
      </div>
      <div class="tab-pane" [ngClass]="{'active': tabActive == TABS.Works}">
        <table-works [works]="works" [name]="recording.title" (onComplete)="onWorksComplete($event)"></table-works>
      </div>
    </div>
  </div>
</div> -->
