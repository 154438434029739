import { TableControlsComponent } from './../table-controls/table-controls.component';
import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import { Release } from "../model";
import { ExportService } from "../services/export.service";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'table-releases',
  templateUrl: './table-releases.component.html',
  styleUrls: ['./table-releases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableReleasesComponent implements OnChanges, AfterViewInit {
  @Input() releases: Array<Release> = [];
  @Input() name = "";

  @ViewChild(TableControlsComponent) tableControls!: TableControlsComponent;
  @ViewChild(MatSort) sort!: MatSort;

  public dataSource = new MatTableDataSource<Release>([]);
  columns = ['visual', 'type', 'upc', 'title', 'year'];

  constructor(private exportService: ExportService) { }

  ngOnChanges(): void {
    setTimeout(() => {
      this.tableControls.resetFilter();
      this.loadReleases();
    }, 1);
  }

  ngAfterViewInit() {
    this.tableControls.paginator$.subscribe((paginator) => {
      this.dataSource.paginator = paginator
    });

    this.tableControls.filter$.subscribe((filter) => {
      this.dataSource.filter = filter;
      this.tableControls.filteredDataCount = this.dataSource.filteredData.length;
    });
  }

  sortData(sort: Sort){
    if(!this.dataSource.sort){
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sort({id: sort.active, start: sort.direction, disableClear: false});
    }
  }

  intialSort() {
    let data = [...this.dataSource.data];
    this.dataSource.data = data.sort((a: Release, b: Release) => {
      const aYear = a.year ? parseInt(a.year): 0;
      const bYear = b.year ? parseInt(b.year) : 0;
      const aType = a.type && a.type === 'Album' ? 1: 0;
      const bType = b.type && b.type === 'Album' ? 1: 0;

      const aCover = a.cover ? 1: 0;
      const bCover = b.cover ? 1 : 0;
      return  bType - aType || bCover - aCover || bYear - aYear || a.title.localeCompare(b.title)
    });
  }

  loadReleases(): void {
    this.dataSource.data = this.releases
    this.tableControls.filteredDataCount = this.dataSource.data.length;
    this.tableControls.totalDataCount = this.dataSource.data.length;
    if(this.dataSource.paginator) this.dataSource.paginator.firstPage();

    this.intialSort();
  }

  exportExcel(): any{
    const lines = this.dataSource.filteredData.map((row)=> {
      return {
        upc: row.upc,
        title: row.title,
        year: row.year,
        label: row.label
      }
    })
    this.exportService.exportReleasesAsExcel(this.name, this.dataSource.filteredData);
  }

  exportCSV(): void{
    this.exportService.exportReleasesAsCSV(this.name, this.dataSource.filteredData);
  }

}
