import { ExportService } from '../services/export.service';
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Artist, Recording, Release, Work } from '../model';

export type ExportArtistFunction = ((party: Artist, releases:any[], recordings:any[], works: any[], excelFileName: string) => void)

export type DialogArtistExportData = {
  artist: Artist
  releases: Release[]
  works: Work[]
  recordings: Recording[]
  exportFn: {
    excel?: ExportArtistFunction,
    csv?: ExportArtistFunction,
    json?: ExportArtistFunction,
  }
}

@Component({
  selector: 'dialog-artist-export',
  templateUrl: './dialog-artist-export.component.html'
})
export class DialogArtistExportComponent {
  constructor(
    private exportService: ExportService,
    public dialogRef: MatDialogRef<DialogArtistExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogArtistExportData) {}

  exportAsExcel() {
    if(this.data.exportFn?.excel){
      this.data.exportFn.excel.call(this.exportService, this.data.artist, this.data.releases, this.data.recordings, this.data.works, this.data.artist.name);
      this.dialogRef.close();
    }
  }

  exportAsCSV() {
    if(this.data.exportFn?.csv){
      this.data.exportFn.csv.call(this.exportService, this.data.artist, this.data.releases, this.data.recordings, this.data.works, this.data.artist.name);
      this.dialogRef.close();
    }
  }

  exportAsJson() {
    if(this.data.exportFn.json){
      this.data.exportFn.json.call(this.exportService, this.data.artist, this.data.releases, this.data.recordings, this.data.works, this.data.artist.name);
      this.dialogRef.close();
    }
  }

  canExportExcel() {
    return this.data.exportFn.excel !== undefined;
  }

  canExportCSV() {
    return this.data.exportFn.csv !== undefined;
  }

  canExportJson() {
    return this.data.exportFn.json !== undefined;
  }
}
