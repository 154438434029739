import {Injectable} from '@angular/core';

import {
  Artist,
  ArtistResult, Entity, Identifiers,
  Recording,
  RecordingOfWork,
  Release,
  Result, Work,
  WorkOfRecording,
  XAPIParty,
  XAPIRecording,
  XAPIRelease, XAPIWork, YSources
} from "../model";

import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  result: Result;
  resultTopic = new BehaviorSubject<Result>(this.defaultResult());
  artistResultTopic = new Subject<ArtistResult>();

  entityLoad$: BehaviorSubject<Entity|null> = new BehaviorSubject<Entity|null>(null)

  constructor() {
    this.result = this.defaultResult();
  }

  defaultResult() {
    return {
      error: null,
      errorMsg: null,
      isShowDisambig: false,
      nameDisambigQuery: "",
      artistsForDisambiguation: [] as Artist[],
      artists: [] as Artist[],
      recordings: [] as Recording[],
      releases: [] as Release[],
      selectedArtist: null,
      selectedRecording: null,
      selectedRelease: null,
      selectedWork: null
    } as Result
  }

  cleanPLineString(pLine: string): string{
    return pLine.replace("(P)", "").replace('\u24C5', "").replace('℗', "");
  }

  getFirstIsni(xApiParty: XAPIParty): string {
   return  xApiParty.ids.isnis ? xApiParty.ids.isnis[0] : "";
  }

  toRelationships(apiArtist: XAPIParty): any {
    return {
      hasMember: apiArtist.relationships ? apiArtist.relationships?.filter((rel: XAPIParty) => rel.relationshipType === "HasMember") : [],
      isMemberOf: apiArtist.relationships ? apiArtist.relationships?.filter((rel: XAPIParty) => rel.relationshipType === "IsMemberOf") : [],
      aka: apiArtist.relationships ? apiArtist.relationships?.filter((rel: XAPIParty) => rel.relationshipType === "Aka") : []
    };
  }

  toArtistsFromXApiParties(xApiParties: XAPIParty[]): Artist[] {
    return xApiParties.map(xApiParty => {
      return {
        id: xApiParty.ids.quansicId,
        entityType: 'party',
        name: xApiParty.name,
        firstname: xApiParty.firstname,
        lastname: xApiParty.lastname,
        type: xApiParty.type,
        gender: xApiParty.gender,
        comments: xApiParty.comment,
        role: xApiParty.role,
        cover: xApiParty.visual,
        birthdate: xApiParty.birthdate,
        deathdate: xApiParty.deathdate,
        nationality: xApiParty.nationality,
        ids: this.formatIdentifiers(xApiParty.ids),
        contributorType: xApiParty.contributorType
      };
    }) as Artist[];
  }

  toRecordingsFromXAPIRecordings(xApiRecordings: XAPIRecording[], sources?: YSources): Recording[] {
    return xApiRecordings.map((recording: XAPIRecording) => {
      //recording.crtcMaplStatus = {isCanadianContent: true, classifications: {"Quansic": "MAP"}, source: "test"}
      return {
        id: recording.isrc,
        entityType: "recording",
        isrc: recording.isrc,
        appleId: recording.appleId,
        spotifyId: recording.spotifyId,
        title: recording.title,
        subtitle: recording.subtitle,
        year: recording.year,
        country: recording.country,
        contributors: this.toArtistsFromXApiParties(recording.contributors || []),
        contributorType: recording.contributorType,
        role: recording.role,
        duration: recording.durationMs,
        audioUrl: recording.audioUrl,
        pLine: this.cleanPLineString(recording.pLine || ""),
        active: recording.active,
        crtcMaplStatus: recording.crtcMaplStatus,
        ySources: sources
      } as Recording;
    })
  }

  // toRecordingMaplScore(recording: XAPIRecording) {
  //   console.log("toRecordingMaplScore", recording.crtcMaplStatus)
  //   return recording.crtcMaplStatus?.classifications['Quansic']
  // }

  toRecordingsOfWorkFromXAPIRecording(xApiRecordings: XAPIRecording[]): RecordingOfWork[] {
    return this.toRecordingsFromXAPIRecordings(xApiRecordings).map((recording: Recording, idx: number) => {
      return {q2Score: xApiRecordings[idx].q2Score, ...recording} as RecordingOfWork
    })
  }

  toRecordingsFromAPIRelease(apiRelease: XAPIRelease) {
    if (apiRelease.recordings !== undefined)
      return apiRelease.recordings.map((apiRecording:XAPIRecording) => {
        return {
          isrc: apiRecording.isrc,
          entityType: 'recording',
          appleId: apiRecording.appleId,
          spotifyId: apiRecording.spotifyId,
          title: apiRecording.title,
          subtitle: apiRecording.subtitle,
          duration: apiRecording.durationMs,
          audioUrl: apiRecording.audioUrl,
          contributors: apiRecording.contributors
        } as Recording;
      })
    else return []
  }

  toReleases(apiReleases: XAPIRelease[]):Release[] {
    return apiReleases.map((release:XAPIRelease) => {
      return {
        upc: release.upc,
        entityType: 'release',
        type: release.type,
        title: release.title,
        country: "",
        year: release.year,
        cover: release.cover,
        label: release.label || "",
        active: release.active
      } as Release;
    })
  }
  toReleasesFromXApiReleases(xApiReleases: XAPIRelease[]):Release[] {
    return xApiReleases.map((release:XAPIRelease) => {
      return {
        upc: release.upc,
        entityType: 'release',
        type: release.type,
        title: release.title,
        year: release.year,
        cover: release.cover,
        label: release.label,
        active: release.active
      } as Release;
    })
  }
  toWorksFromXApiWorks(xApiworks: XAPIWork[]):Work[] {
    return xApiworks.map((work:XAPIWork) => {
      const contributors = (work.contributors || []);
      return {
        iswc: work.iswc,
        bowi: work.bowi,
        entityType: 'work',
        title: work.title,
        role: work.role,
        contributors: contributors
      } as Work;
    })
  }

  toWorksOfRecordingFromXAPIWorks(xApiWorks: XAPIWork[]): WorkOfRecording[] {
    return this.toWorksFromXApiWorks(xApiWorks).map((work: Work, idx: number) => {
      return {q2Score: xApiWorks[idx].q2Score, ...work} as WorkOfRecording
    })
  }

  toReleasesFromXApiRecording(xApiRecording: XAPIRecording):Release[] {
    if (xApiRecording.releases !== undefined)
      return xApiRecording.releases.map((release:XAPIRelease) => {
        return {
          upc: release.upc,
          entityType: 'release',
          type: release.type,
          title: release.title,
          country: "",
          year: release.year,
          cover: release.cover
        } as Release;
      })
    else return []
  }

  toArtists(apiParties: XAPIParty[]|undefined) {
    if(!apiParties) return [];
    return apiParties.map((party) => {
      return {
        id: party.ids.quansicId,
        entityType: 'party',
        name: party.name,
        firstname: party.firstname,
        lastname: party.lastname,
        role: party.role,
        type: party.type,
        ids: {
          quansicId: party.ids.quansicId,
          isnis: party.ids.isnis || [],
          mergedIsnis: party.ids.mergedIsnis || [],
          ipis: party.ids.ipis?.map(this.formatIsni) || [],
          ipns: party.ids.ipns || [],
          musicBrainzIds: party.ids.musicBrainzIds || [],
          discogsIds: party.ids.discogsIds || [],
          appleIds: party.ids.appleIds || [],
          spotifyIds: party.ids.spotifyIds || [],
          wikidataIds: party.ids.wikidataIds || [],
          amazonIds: party.ids.amazonIds || [],
          deezerIds: party.ids.deezerIds || [],
        },
        contributorType: party.contributorType,
        namevariants: party.nameVariants,
        nationality: party.nationality,
        relationships: this.toRelationships(party)
      } as Artist
    });
  }

  publishArtistsForDisambiguation(artists: Array<XAPIParty>, query: string): void{
    this.resetResult();
    this.result.isShowDisambig = true;
    this.result.nameDisambigQuery = query;
    this.result.artistsForDisambiguation = artists.map((party: XAPIParty): Artist => {
      return {
        id: party.ids.quansicId,
        entityType: 'party',
        name: party.name,
        type: party.type,
        gender: party.gender,
        comments: party.comment,
        birthdate: party.birthdate,
        nationality: party.nationality || "",
        cover: party.visual,
        popularity: party.popularity,
        ids: party.ids as Identifiers,
        ySources: {}
      };
    });
    this.result.artistsForDisambiguation.sort((a1: Artist, a2: Artist): number => {
      if (a1.cover == null && a2.cover != null) return 1;
      if (a1.cover != null && a2.cover == null) return -1;
      return 0;
    })
    this.pushResult(this.result);
  }


  pushAPIArtist(xApiParty: XAPIParty, ySources: YSources): void{
    this.resetResult();
    this.result.isShowDisambig = false;
    this.result.selectedArtist = {
      id: xApiParty.ids.quansicId,
      entityType: 'party',
      name: xApiParty.name,
      firstname: xApiParty.firstname,
      lastname: xApiParty.lastname,
      type: xApiParty.type,
      gender: xApiParty.gender || "",
      nationality: xApiParty.nationality || "",
      comments: xApiParty.comment,
      cover: xApiParty.visual || "",
      ids: this.formatIdentifiers(xApiParty.ids),
      birthdate: xApiParty.birthdate,
      deathdate: xApiParty.deathdate,
      relationships: this.toRelationships(xApiParty),
      namevariants: xApiParty.nameVariants,
      ySources: ySources
    } as Artist;
    this.result.recordings = this.toRecordingsFromXAPIRecordings(xApiParty.recordings || []);
    this.result.releases = this.toReleasesFromXApiReleases(xApiParty.releases || []);
    this.result.works = this.toWorksFromXApiWorks(xApiParty.works || []);
    this.entityLoad$.next(this.result.selectedArtist)
    this.pushResult(this.result);
  }

  publishAPIRecordings(xApiRecordings: XAPIRecording[]): void {
    this.result.recordings = this.toRecordingsFromXAPIRecordings(xApiRecordings || []);
    this.pushResult(this.result);
  }


  publishISRC(isrc: string, apiRecording: XAPIRecording|null, ysources: YSources): void {
    this.resetResult();
    if(apiRecording != null) {
      this.result.selectedRecording = this.toRecordingsFromXAPIRecordings([apiRecording], ysources)[0] ;
      this.result.artists = this.toArtists(apiRecording.contributors);
      this.result.releases = this.toReleasesFromXApiRecording(apiRecording)
      this.result.works = this.toWorksOfRecordingFromXAPIWorks(apiRecording.works || [])
    } else {
      this.result.selectedRecording = this.emptyRecording();
    }
    this.entityLoad$.next(this.result.selectedRecording)
    this.pushResult(this.result);
  }


  publishUPC(upc:string, apiRelease:XAPIRelease|null) {
    this.resetResult();
    if(apiRelease != null) {
      this.result.selectedRelease =this.toReleases([apiRelease])[0] ;
      this.result.artists = this.toArtists(apiRelease.parties);
      this.result.recordings = this.toRecordingsFromAPIRelease(apiRelease);
    } else {
      this.result.selectedRelease = this.emptyRelease();
    }
    this.pushResult(this.result);
  }

  publishWork(apiWork: XAPIWork|null): void {
    this.resetResult();
    if(apiWork != null) {
      this.result.selectedWork = this.toWorksFromXApiWorks([apiWork])[0] ;
      this.result.recordings = this.toRecordingsOfWorkFromXAPIRecording(apiWork.recordings || []);
    } else {
      this.result.selectedWork = this.emptyWork();
    }
    this.pushResult(this.result);
  }

  formatIdentifiers(ids:Identifiers): Identifiers {
    return {
      ...ids,
      isnis: ids.isnis?.map(this.formatIsni),
    } as Identifiers
  }

  formatIsni(isni:string): string {
    return isni.replace(/^([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})$/g, '$1 $2 $3 $4');
  }


  pushResult(result: Result){
    this.resultTopic.next(result);
  }
  getResult(): Observable<Result> {
    return this.resultTopic.asObservable();
  }
  getArtistResult(): Observable<ArtistResult> {
    return this.artistResultTopic.asObservable();
  }

  resetResult(): void {
    this.result = this.defaultResult();
  }

  error(errorCode: any, message?: string): void{
    this.resetResult();
    this.result.error = errorCode;
    if (message) { this.result.errorMsg = message; }
    this.pushResult(this.result);
  }

  errorId(errorCode: any, idType?: string, id?: string): void{
    this.resetResult();
    this.result.error = errorCode;
    if (idType && id) { this.result.errorId = { idType, id}; }
    this.pushResult(this.result);
  }


  emptyXApiParty(): XAPIParty {
    return {
      name: "",
      firstname: "",
      lastname: "",
      type: "",
      gender: "",
      ids: this.emptyIdentifiers(),
      nationality: "",
      relationships: [],
      birthdate: "",
      deathdate: "",
      role: "",
      contributorType: "",
      comment: "",
      nameVariants: [],
      recordings: [],
      releases: [],
      works: [],
      popularity: 0,
      relationshipType: "",
      visual: ""
    }
  }

  emptyArtist(): Artist {
    return {
      id: "",
      name: "",
      entityType: 'party',
      type: "",
      gender: "",
      comments: "",
      cover: "",
      ids: this.emptyIdentifiers(),
      nationality: "",
      relationships: {
        isMemberOf: [],
        hasMember: [],
        aka: []
      },
      namevariants: [],
      ySources: {}
    }
  }

  emptyIdentifiers(): Identifiers {
    return {
      quansicId: "",
      isnis: [],
      mergedIsnis: [],
      ipis: [],
      ipns: [],
      musicBrainzIds: [],
      discogsIds: [],
      appleIds: [],
      spotifyIds: [],
      wikidataIds: [],
      amazonIds: [],
      deezerIds: []
    }
  }

  emptyRelease(): Release {
    return {
      id: '',
      upc: "",
      entityType: 'release',
      type: "",
      title: "",
      year: "",
      country: "",
      cover: "",
      label: "",
      active: true
    }
  }

  emptyRecording(): Recording {
    return {
      id: '',
      isrc: "",
      entityType: 'recording',
      appleId: "",
      spotifyId: "",
      title: "",
      year: 0,
      country: "",
      subtitle: "",
      duration: 0,
      contributors: [],
      contributorType:"",
      audioUrl: "",
      pLine: "",
      active: true,
      ySources: {}
    }
  }

  emptyXAPIRecording(): XAPIRecording {
    return {
      isrc: "",
      title: "",
      subtitle: "",
      year: 0,
      country: "",
      durationMs: 0,
      contributors: [],
      contributorType:"",
      audioUrl: "",
      pLine: "",
      active: true
    }
  }

  emptyWork(): Work {
    return {
      id: '',
      entityType: 'work',
      iswc: "",
      bowi: "",
      title: "",
      role: ""
    }
  }
}
