import { LoginService } from "@githubquansic/web-auth/ng";
import { EmailService } from "../../services/email.service";
import { MatDialog } from '@angular/material/dialog';
import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from "@angular/forms";
import { Router } from "@angular/router";
import { DialogPolicyComponent } from '../../dialog-policy/dialog-policy.component';
import { DialogTermsComponent } from '../../dialog-terms/dialog-terms.component';
import { hasOneDigit, hasOneUpperCase, isMinLength, PasswordMatchValidator, PasswordValidator } from './password.validator';

@Component({
  selector: 'app-app-register',
  templateUrl: './app-register.component.html',
  styleUrls: ['./app-register.component.scss']
})
export class AppRegisterComponent{
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, PasswordValidator]),
    confirmPassword: new FormControl('', [Validators.required]),
    name: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    agree: new FormControl(false, Validators.requiredTrue),
    // captcha: new FormControl('', Validators.required)
  }, [PasswordMatchValidator('password', 'confirmPassword')]);
  captcha: string|null = null;
  submitted = false;
  queryInProgress = false;
  errorMessage: string|null = null;
  accountCreated = false;
  hide = true;

  loginService =  inject(LoginService)

  constructor(private emailService: EmailService, private router: Router, private dialog: MatDialog) { }

  register(): void {
    this.form.markAllAsTouched();
    this.submitted = true;
    if (this.form.valid) {
      this.queryInProgress = true;
      const email = this.form.get('email')!.value;
      const name = this.form.get('name')!.value;
      const password = this.form.get('password')!.value;
      const company = this.form.get('company')!.value;
      if(email != null && password != null && company != null && name != null) {
        this.loginService.registerWebUser(email.toLowerCase(), password, name, company, []).subscribe({
          next: (res: any) => { // success
            this.queryInProgress = false;
            this.accountCreated = true;
          },
          error: (errorResponse: any) => { // error
            this.queryInProgress = false;
            this.errorMessage = errorResponse.error
          }
        })
      }
    }
  }

  isEmailEmpty(): boolean {
    return this.form.controls['email'].errors?.['required'];
  }
  isEmailInvalid(): boolean{
    return this.form.controls['email'].errors?.['email'];
  }

  isPasswordEmpty(): boolean {
    return this.form.controls['password'].errors?.['required'];
  }
  isPasswordInvalid(): boolean{
    return this.form.controls['password'].errors?.['password'];
  }
  isPasswordMinLength() {
    return isMinLength(this.form.controls['password']?.value);
  }
  isPasswordOneDigit() {
    return hasOneDigit(this.form.controls['password']?.value);
  }
  isPasswordOneUpperCase() {
    return hasOneUpperCase(this.form.controls['password']?.value);
  }

  isPasswordConfirmNoMatch() {
    return (this.form.errors)?.['noMatch'] as boolean;
  }

  isNameEmpty(): boolean {
    return this.form.controls['name'].errors?.['required'];
  }

  // isCaptchaUnchecked(): boolean {
  //   return this.form.controls['captcha'].errors?.['required'] && this.submitted;
  // }
  isAgreeUnchecked(): boolean {
    return this.form.controls['agree'].errors?.['required'];
  }

  openPrivacyPolicyDialog(): void {
    this.dialog.open(DialogPolicyComponent, {
      panelClass: "dialog",
      autoFocus: false
    });
  }

  openTermsConditionsDialog(): void {
    this.dialog.open(DialogTermsComponent, {
      panelClass: "dialog",
      autoFocus: false
    });
  }

  navigateToLoginPage(){
    this.router.navigateByUrl('/app-login');
  }
}


