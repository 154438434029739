  <div>
    <p *ngIf="changeRequests.length === 0">No changes yet.</p>

      <mat-table [dataSource]="changeRequests" *ngIf="changeRequests.length > 0">
        <ng-container matColumnDef="entityType" >
          <mat-header-cell *matHeaderCellDef>Entity</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">{{changeReq.entityType}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="entityId" >
          <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            {{changeReq.entityName}}
            <br/>{{changeReq.displayId}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="property" >
          <mat-header-cell *matHeaderCellDef>Property</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">{{changeReq.property}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="originalValue" >
          <mat-header-cell *matHeaderCellDef>Orig. Value</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            <span *ngIf="changeReq.originalValue !== null else none">{{changeReq.originalValue}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="newValue" >
          <mat-header-cell *matHeaderCellDef>New Value</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            <span *ngIf="changeReq.newValue !== null else none">{{changeReq.newValue}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="changedBy" >
          <mat-header-cell *matHeaderCellDef>Changed By</mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            <span>{{changeReq.user}} ({{changeReq.source}})</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="comment" >
          <mat-header-cell *matHeaderCellDef>Comment/Source</mat-header-cell>
          <mat-cell *matCellDef="let changeReq; let i = index">
            <mat-form-field apparence="legacy">
              <textarea matInput [(ngModel)]="comments[i]"></textarea>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delete">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let changeReq">
            <button mat-icon-button (click)="discardChangeEvent(changeReq.uuid)" color="warn"><mat-icon>delete</mat-icon></button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row matRipple *matRowDef="let changeReq; columns: columns;" ></mat-row>
      </mat-table >
  </div>
<ng-template #none><em>none</em></ng-template>

