<div class="identifier thin">
  <ng-container *ngIf="values != null">
    <span *ngIf="links.length > 0; then link else noLink"></span>
    <ng-template #link>
      <a href="{{links[0]}}" target="_blank">{{values[0]}}</a>
    </ng-template>
    <ng-template #noLink>{{values[0]}}</ng-template>

    <copy-to-clipboard [value]="values[0]"></copy-to-clipboard>

    <a class="additional-ids-link link" (click)="openAdditionalIdsDialog()" *ngIf="values.length > 1">+{{values.length -1 }}</a>

    <!-- <div *ngIf="values.length>1" class="badge rounded-pill search-pill bg-danger multi-ids-notif" [ngbPopover]="popContent" [popoverTitle]="popTitle" [autoClose]="'outside'" placement="bottom">
      +{{values.length-1}}
    </div> -->
    <!-- <ng-template #popContent>
      <ul class="list-unstyled" style="width:200px">
        <li *ngFor="let val of values;let idx = index;">
            <span *ngIf="links.length > 0; then link else noLink"></span>
            <ng-template #link>
              <a href="{{links[idx]}}" target="_blank">{{val}}</a>
            </ng-template>
            <ng-template #noLink>{{val}}</ng-template>
            <copy-to-clipboard [value]="val"></copy-to-clipboard>
        </li>
      </ul>
    </ng-template>
    <ng-template #popTitle>Identifiers</ng-template> -->


    <!--    <ng-container  *ngFor="let val of values;let idx = index; let isLast = last">-->
<!--      <span *ngIf="links.length > 0; then link else noLink"></span>-->
<!--      <ng-template #link>-->
<!--        <a href="{{links[idx]}}" target="_blank">{{val}}</a>-->
<!--      </ng-template>-->
<!--      <ng-template #noLink>{{val}}</ng-template>-->
<!--      <copy-to-clipboard [value]="val"></copy-to-clipboard> <ng-container *ngIf="!isLast">, </ng-container>-->
<!--    </ng-container>-->
  </ng-container>
</div>

