import {Injectable} from "@angular/core";
import {Artist} from "../model";

@Injectable({
  providedIn: 'root'
})
export class ArtistService {
  getIdentifier(artist: Artist): any {
    if (artist == null) return {};

    if (artist.ids.isnis && artist.ids.isnis.length > 0) return {idType: "isni", id: artist.ids.isnis[0]}
    if (artist.ids.musicBrainzIds && artist.ids.musicBrainzIds.length>0) return {idType: "musicBrainz-id", id: artist.ids.musicBrainzIds[0]}
    if (artist.ids.discogsIds && artist.ids.discogsIds.length>0) return {idType: "discogs-id", id: artist.ids.discogsIds[0]}
    if (artist.ids.spotifyIds && artist.ids.spotifyIds.length>0) return  {idType: "spotify-id", id: artist.ids.spotifyIds[0]}
    if (artist.ids.appleIds && artist.ids.appleIds.length>0) return  {idType: "apple-id", id: artist.ids.appleIds[0]}
    if (artist.ids.amazonIds && artist.ids.amazonIds.length>0) return  {idType: "amazon-id", id: artist.ids.amazonIds[0]}
    if (artist.ids.ipis && artist.ids.ipis.length>0) return  {idType: "ipi", id: artist.ids.ipis[0]}
    return {};
  }

  formatIdRaw(idType: string, idValue: string): string {
      if(idType.toLowerCase() === "isni") {
        return idValue.replace(/[\ ]/g, "");
      }
      if(idType.toLowerCase() === "iswc") {
        return idValue.replace(/[\.\-\ ]/g, "");
      }
      if(idType.toLowerCase() === "bowi") {
        return idValue.replace(/[\.\-\ ]/g, "");
      }
      return idValue.replace(/[\.\-\ ]/g, "");
  }
}

