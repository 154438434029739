import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Directive} from "@angular/core";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";

@Directive()
export class TabsController {
  _router:Router;
  _route:ActivatedRoute;

  constructor(router:Router, route:ActivatedRoute) {
    this._router = router;
    this._route = route;
  }

  readActiveTabFromParam(matTabGroup: MatTabGroup){
    if(!matTabGroup) return;
    return this._route.queryParamMap.subscribe((paramsMap:ParamMap) => {
      matTabGroup.selectedIndex = (paramsMap.get('tab') || "0");
    })
  }

  selectedTabChange(event: MatTabChangeEvent){
    const tabIndex = event.index || 0;
    this._router.navigate([], { queryParams: { tab: tabIndex } });
  }
}
