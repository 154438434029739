import { ChangeRequestComponent } from './edit/change-request-component/change-request.component';
import { MaiplScoreComponent } from './crtc/maipl-score/maipl-score.component';
import { DialogChangelogComponent } from './edit/changelog/dialog-changelog/dialog-changelog.component';
import { DialogCrtcComponent } from './crtc/dialog-crtc/dialog-crtc.component';
import { AppLoginKeyComponent } from './login/app-login-key/app-login-key.component';
import { AppRecoverPasswordComponent } from './login/app-recover-password/app-recover-password.component';
import { TableControlsComponent } from './table-controls/table-controls.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";

import { AuthGuard } from "./services/auth-guard";

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { IswcPipe } from './pipes/iswc.pipe';
import { IsrcPipe } from './pipes/isrc.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { TypeofPipe } from './pipes/typeof.pipe';
import { SortByPipe } from './pipes/sortBy.pipe';
import { SearchComponent } from './search/search.component';
import { AppLoginComponent } from './login/app-login/app-login.component';
import { AppHomeComponent } from './app-home/app-home.component';
import { AppArtistComponent } from './app-artist/app-artist.component';
import { AppReleaseComponent } from './app-release/app-release.component';
import { AppRecordingComponent } from './app-recording/app-recording.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { PartyLinkComponent } from './party-link/party-link.component';
import { TableReleasesComponent } from './table-releases/table-releases.component';
import { TableRecordingsComponent } from './table-recordings/table-recordings.component';
import { IdentifierPillComponent } from './identifier-pill/identifier-pill.component';
import { DialogCoverComponent } from './dialog-cover/dialog-cover.component';
import { DialogRowsExportComponent } from './dialog-rows-export/dialog-rows-export.component';
import { DialogArtistExportComponent } from './dialog-artist-export/dialog-artist-export.component';
import { DialogContactComponent } from './dialog-contact/dialog-contact.component';
import { DialogHelpComponent } from './dialog-help/dialog-help.component';
import { DialogFeedbackComponent } from './dialog-feedback/dialog-feedback.component';
import { DialogLockComponent } from './dialog-lock/dialog-lock.component';
import { DialogAdditionalIdsComponent } from './dialog-additional-ids/dialog-additional-ids.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoadingComponent } from './loading/loading.component';
import { DisambigComponent } from './disambig/disambig.component';
import { GraphComponent } from "./graph/graph.component";
import { TableWorksComponent } from './table-works/table-works.component';
import { AppWorkComponent } from './app-work/app-work.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { AppRegisterComponent } from './login/app-register/app-register.component';
import { CounterComponent } from './counter/counter.component';
import { StatsComponent } from './stats/stats.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { DialogTermsComponent } from './dialog-terms/dialog-terms.component';
import { DialogPolicyComponent } from './dialog-policy/dialog-policy.component';
import { ArtistIdentifierComponent } from './artist-identifier/artist-identifier.component';
import { TableEmptyComponent } from './table-empty/table-empty.component';
import { ChangelogComponent } from './edit/changelog/changelog.component';
import { DialogConfirmComponent } from './edit/dialog-confirm/dialog-confirm.component';
// import { InlineEditComponent } from './edit/inline-edit-deprecated/inline-edit.component';

import { MaterialModule } from './material.module';
import { MaiplEditPanelComponent } from './crtc/maipl-edit-panel/maipl-edit-panel.component';
import { GraphInstanceInterceptor } from './graph-instance.interceptor';
import { CanconFlagComponent } from './crtc/cancon-flag/cancon-flag.component';

@NgModule({
  declarations: [
    AppComponent,
    AppHomeComponent,
    AppLoginComponent,
    AppLoginKeyComponent,
    AppRecoverPasswordComponent,
    AppArtistComponent,
    AppReleaseComponent,
    AppRecordingComponent,
    AppRegisterComponent,
    IswcPipe,
    IsrcPipe,
    DurationPipe,
    SortByPipe,
    SearchComponent,
    AudioPlayerComponent,
    PartyLinkComponent,
    TableControlsComponent,
    TableReleasesComponent,
    TableRecordingsComponent,
    TableWorksComponent,
    TableEmptyComponent,
    IdentifierPillComponent,
    DialogCoverComponent,
    DialogContactComponent,
    DialogTermsComponent,
    DialogPolicyComponent,
    DialogHelpComponent,
    DialogRowsExportComponent,
    DialogArtistExportComponent,
    DialogFeedbackComponent,
    DialogLockComponent,
    DialogAdditionalIdsComponent,
    DialogCrtcComponent,
    CanconFlagComponent,
    DialogChangelogComponent,
    PageErrorComponent,
    TypeofPipe,
    NavbarComponent,
    LoadingComponent,
    DisambigComponent,
    GraphComponent,
    AppWorkComponent,
    CopyToClipboardComponent,
    CounterComponent,
    StatsComponent,
    ArtistIdentifierComponent,
    ChangeRequestComponent,
    ChangelogComponent,
    DialogConfirmComponent,
    // InlineEditComponent,
    MaiplScoreComponent,
    MaiplEditPanelComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    // RecaptchaModule,
    // RecaptchaFormsModule,
    MaterialModule,
    MatSelectCountryModule.forRoot('en')
  ],
  providers: [
    AuthGuard,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: "6Lf4sgceAAAAAAVjz-XZVLch6Q-Bka8kQlqMXL9S" } as RecaptchaSettings,
    },
    DurationPipe,
    { provide: HTTP_INTERCEPTORS, useClass: GraphInstanceInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

