import { XAPIWork, YSources } from './../model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Observable, Subscription, combineLatest, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {XApiResponse, XAPIParty, ApiError, XAPIRecording, Recording, Identifiers, NameVariant, Result} from "../model";
import { ResultService} from "./result.service";

import { environment } from '../../environments/environment';
import {ProgressService} from "./progress.service";
import {LogService} from "./log.service";
import {Router} from "@angular/router";
import {HttpHelperService} from "./http.service";


@Injectable({
  providedIn: 'root'
})
export class ApiSearchService {


  private bffApiUrl = this.httpHelperService.getBffDomain();  // URL to web api

  constructor(
    private http: HttpClient,
    private httpHelperService: HttpHelperService,
    private resultService: ResultService,
    private progressService: ProgressService,
    private logService: LogService,
    private router: Router) { }

  errorHandler(err: HttpErrorResponse, idName: string|null = null, id: string|null = null): void {
    if (err.status === 400){
      this.resultService.error(ApiError.INVALID_ID, err.error.message);
    }
    if (err.status === 401 || err.status === 403){
      this.resultService.error(ApiError.UNAUTHORIZED, err.error?.message);
      this.router.navigate(['/app-login']).catch((e) => {});
    }
    if (err.status === 404){
      if (idName !== null && id !== null) { this.resultService.errorId(ApiError.NOT_FOUND, idName, id); }
      else {this.resultService.error(ApiError.NOT_FOUND)}
    }
    if (err.status >= 500){
      this.resultService.error(ApiError.SERVER_ERROR, err.error.message);
    }
    this.progressService.stopQueryInProgress();
  }

  quietErrorHandler(err: HttpErrorResponse): Observable<never> {
    if (err.error instanceof Error) {
     console.error("An error occurred [" + err.message + "]");
    } else {
      console.error("Cannot connect to local server [" + err.name + "]");
    }
    return throwError('An error happened. Only logging the message.');
  }

  getDemoDisamgib(): Subscription {
    this.resultService.resetResult();
    this.progressService.startQueryInProgress();

    return this.http.get<XApiResponse>(`${this.bffApiUrl}/api/x/demo`)
      .subscribe({
        next: (response: XApiResponse) => {
          this.progressService.stopQueryInProgress();
          const xParties: XAPIParty[] | undefined = response.results.parties;
          if (xParties != null) {
            this.resultService.publishArtistsForDisambiguation(xParties, "");
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler(err);
        }
      });
  }

  searchByName(name: string): void {
    this.resultService.resetResult();
    this.progressService.startQueryInProgress();
    this.http.get<XApiResponse>(`${this.bffApiUrl}/api/x/party?name=${encodeURIComponent(name)}`, {withCredentials: true})
      .subscribe({
        next: (response: XApiResponse) => {
          this.progressService.stopQueryInProgress();
          const xParties: XAPIParty[] | undefined = response.results.parties;
          if (xParties != null) {
            if (xParties.length >= 1) {
              this.resultService.publishArtistsForDisambiguation(xParties, `name=${name}`);
            }
            if (xParties.length === 0) {
              this.errorHandler(new HttpErrorResponse({error: null, status: 404}), "name", name);
            }
          } else {
            this.errorHandler(new HttpErrorResponse({error: null, status: 500}), "name", name);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler(err);
        }
      });
  }

  getArtistYSources(quansicId: string): Observable<YSources> {
    return this.http.get<any>(`${this.bffApiUrl}/api/x/lookup/party/${quansicId}/sources`, {withCredentials: true})
    .pipe(
      map(response => response.results.sources)
    )
  }

  getRecordingYSources(isrc: string): Observable<YSources> {
    return this.http.get<any>(`${this.bffApiUrl}/api/x/lookup/recording/isrc/${isrc}/sources`, {withCredentials: true})
    .pipe(
      map(response => response.results.sources)
    )
  }

  lookupArtistByQuansicId(quansicId: string): void {
    this.resultService.resetResult();
    this.progressService.startQueryInProgress();
    combineLatest([
      this.http.get<XApiResponse>(`${this.bffApiUrl}/api/x/lookup/party/${quansicId}`, {withCredentials: true}),
      this.getArtistYSources(quansicId)
    ])
    .subscribe({
      next: ([partyResponse, ySources]) => {
        const xParty: XAPIParty | undefined = partyResponse.results.party;
        if (xParty != null) {
          this.logService.recordArtistDisplay("quansicId", quansicId);
          this.resultService.pushAPIArtist(xParty, ySources);
        }
        this.progressService.stopQueryInProgress();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler(err);
      }
    });
  }

  searchArtistsById(idName: string, id: string): void {
    this.resultService.resetResult();
    this.progressService.startQueryInProgress();
    this.http.get<XApiResponse>(`${this.bffApiUrl}/api/x/search/party/${idName}/${id}`, {withCredentials: true})
      .subscribe({
        next: (httpResponse: XApiResponse) => {
          const xParties: XAPIParty[] | undefined = httpResponse.results.parties;
          if (xParties != null) {
            this.logService.recordArtistDisplay(idName, id);
            if (xParties.length > 1) {
              this.resultService.publishArtistsForDisambiguation(xParties, `${idName}=${id}`);
            }
            if (xParties.length === 1){
              const party = xParties[0];
              if (party) { this.lookupArtistByQuansicId(party.ids.quansicId); }
            }
            if (xParties.length === 0){
              this.errorHandler(new HttpErrorResponse({error: null, status: 404}), idName, id);
            }
          } else {
            this.errorHandler(new HttpErrorResponse({error: null, status: 500}), idName, id);
          }
          this.progressService.stopQueryInProgress();
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler(err, idName, id);
        }
      });
  }

  /**
   * Get paginated recordings for a given artist (idType, id) starting at the given offset.
   * @return Recording[]
   */
  lookupRecordingsByQuansicId(quansicId: string, offset: number): Observable<any>{
    const url = `${this.bffApiUrl}/api/x/lookup/party/${quansicId}/recordings/${offset}`;
    return this.http.get<XApiResponse>(url, {withCredentials: true})
      .pipe(
        map((response: any) => {
          const xRecordings: XAPIRecording[] = response.results.data;
          return this.resultService.toRecordingsFromXAPIRecordings(xRecordings);
        })
      );
  }

  /**
   * Get paginated recordings for a given artist (idType, id) starting at the given offset.
   * @return Recording[]
   */
  lookupRecordingsByPartyId(idName: string, id: string, offset: number): Observable<any>{
    const url = `${this.bffApiUrl}/api/x/lookup/party/recordings/${idName}/${id}/${offset}`;
    return this.http.get<XApiResponse>(url, {withCredentials: true})
      .pipe(
        map((response: any) => {
          const xRecordings: XAPIRecording[] = response.results.data;
          return this.resultService.toRecordingsFromXAPIRecordings(xRecordings);
        })
      );
  }

  lookupWorksByRecordingId(isrc: string, offset: number): Observable<any>{
    const url = `${this.bffApiUrl}/api/x/lookup/recording/${isrc}/works/${offset}`;
    return this.http.get<XApiResponse>(url, {withCredentials: true})
      .pipe(
        map((response: any) => {
          const xWorks: XAPIWork[] = response.results.data;
          return this.resultService.toWorksFromXApiWorks(xWorks);
        })
      );
  }

  lookupIsrc(isrc: string): void{
    this.resultService.resetResult();
    this.progressService.startQueryInProgress();

    const isrcRaw = isrc.replace(/[-]/gi, "");
    combineLatest([
      this.http.get<XApiResponse>(`${this.bffApiUrl}/api/x/lookup/recording/isrc/${isrcRaw}`, {withCredentials: true}),
      this.getRecordingYSources(isrcRaw)
    ])
      .subscribe({
        next: ([isrcResponse, ySources]) => {
          this.logService.recordRecordingDisplay("ISRC", isrc);
          const recording = isrcResponse.results.recording || null;
          this.resultService.publishISRC(isrc, recording, ySources);
          this.progressService.stopQueryInProgress();
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler(err, 'isrc', isrc);
        }
      });
  }

  lookupUpc(upc: string): void{
    this.resultService.resetResult();
    this.progressService.startQueryInProgress();

    const upcRaw = upc.replace(/[-]/gi, "");
    const url = `${this.bffApiUrl}/api/x/lookup/release/upc/${upcRaw}`;
    this.http.get<XApiResponse>(url, {withCredentials: true})
      .subscribe({
        next: (response: XApiResponse) => {
          this.logService.recordReleaseDisplay("UPC", upc);
          this.resultService.publishUPC(upc, response.results.release || null);
          this.progressService.stopQueryInProgress();
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler(err, 'upc', upc);
        }
      });
  }

  lookupIswc(iswc: string): void{
    this.resultService.resetResult();
    this.progressService.startQueryInProgress();

    const iswcRaw = iswc.replace(/[-]/gi, "");
    const url = `${this.bffApiUrl}/api/x/lookup/work/iswc/${iswcRaw}`;
    this.http.get<XApiResponse>(url, {withCredentials: true})
      .subscribe({
        next: (response: XApiResponse) => {
          this.logService.recordWorkDisplay("ISWC", iswc);
          this.resultService.publishWork(response.results.work || null);
          this.progressService.stopQueryInProgress();
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler(err, 'iswc', iswc);
        }
      });
  }
  lookupBowi(bowi: string): void{
    this.resultService.resetResult();
    this.progressService.startQueryInProgress();

    const bowiRaw = bowi.replace(/[-]/gi, "");
    const url = `${this.bffApiUrl}/api/x/lookup/work/bowi/${bowiRaw}`;
    this.http.get<XApiResponse>(url, {withCredentials: true})
      .subscribe({
        next: (response: XApiResponse) => {
          this.logService.recordWorkDisplay("BOWI", bowi);
          this.resultService.publishWork(response.results.work || null);
          this.progressService.stopQueryInProgress();
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler(err, 'bowi', bowi);
        }
      });
  }

  getStats(): Observable<any> {
    const url = `${this.bffApiUrl}/api/x/stats`;
    return this.http.get<XApiResponse>(url).pipe(
      catchError((err: HttpErrorResponse) => {
        this.errorHandler(err);
        return throwError('Something bad happened; please try again later.');
      }));
  }

  emptyXAPIParty(): XAPIParty {
    return {
      name: "",
      ids: {
        quansicId: "",
        isnis: [],
        mergedIsnis: [],
        ipis: [],
        ipns: [],
        musicBrainzIds: [],
        appleIds: [],
        spotifyIds: [],
        wikidataIds: [],
        discogsIds: [],
        amazonIds: [],
        deezerIds: []
      },
    } as XAPIParty;
  }
}
