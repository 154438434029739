import { AbstractControl, Form, ValidationErrors, ValidatorFn } from "@angular/forms";

const MIN_LENGTH = 8;

export function hasOneUpperCase(password: string|null): boolean {
  if(password === null) return false;
  return /[A-Z]+/.test(password);
}

export function hasOneDigit(password: string|null): boolean {
  if(password === null) return false;
  return /[0-9]+/.test(password);
}

export function isMinLength(password: string|null): boolean {
  if(password === null) return false;
  return password.length >= MIN_LENGTH;
}



export function PasswordValidator(control:AbstractControl): ValidationErrors | null {
  const password = control.value;
  const oneDigit = hasOneDigit(password);
  const oneUpperCase = hasOneUpperCase(password);
  const minLength = isMinLength(password);

  if(oneDigit && oneUpperCase && minLength){
    return null;
  } else {
    return {
      password: {oneDigit, oneUpperCase, minLength}
    }
  }
}

export function PasswordMatchValidator(passwordField: string, confirmField: string): (control: AbstractControl) => ValidationErrors | null  {
  return (control: AbstractControl): ValidationErrors | null => {
    const passwordCtl = control.get(passwordField)
    const passwordConfirmCtl = control.get(confirmField)
    if(passwordCtl === null) console.error(`No field named ${passwordField} in the form`)
    if(passwordConfirmCtl === null) console.error(`No field named ${confirmField} in the form`)

    if(passwordCtl && passwordConfirmCtl){
      const password = passwordCtl.value;
      const confirm = passwordConfirmCtl.value;

      if (password !== '' && password !== confirm) { return { 'noMatch': true } }
    }

    return null
  }
}


