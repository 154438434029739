import { Injectable } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
@Injectable({
  providedIn: 'root'
})

export class ImageService {

  wikimediaFileRegex = /http[s]?\:\/\/commons\.wikimedia\.org\/wiki\/File\:/;
  wikimediaFileRegexGroup = /http[s]?\:\/\/commons\.wikimedia\.org\/wiki\/File\:(.*)/;
  wikimediaImageResolver = 'https://commons.wikimedia.org/w/index.php?title=Special:Redirect/file'
  wikimediaImageWidth = 300;

  isWikimediaImage(imagePath: string): boolean {
    return this.wikimediaFileRegex.test(imagePath);
  }

  convertImagePath(imagePath: string|undefined): string {
    if(imagePath === undefined) return ''
    if(this.isWikimediaImage(imagePath))return this.convertWikimediaLink(imagePath);
    return imagePath;
  }

  private convertWikimediaLink(wikimediaPageLink: string) {
    let result = wikimediaPageLink;
    const matches = wikimediaPageLink.match(this.wikimediaFileRegexGroup);
    if(matches && matches.length > 1){
      result = `${this.wikimediaImageResolver}/${matches[1]}&width=${this.wikimediaImageWidth}`
    }
    return result
  }
}
