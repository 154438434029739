import { YDBService } from '../../services/y-db.service';
import { ChangeRequest, InlineEditService } from '../../services/inline-edit.service';
import { Component, inject, Input, OnInit } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { LoginService } from '@githubquansic/web-auth/ng';
import { tap } from 'rxjs';
import { FormArray } from '@angular/forms';


@Component({
  selector: 'change-requests',
  templateUrl: './change-request.component.html',
  styleUrls: ['./change-request.component.scss'],
})
export class ChangeRequestComponent implements OnInit {
  @Input() comments: any = {}; //variable in which put the comments of the change requests

  public loginService: LoginService = inject(LoginService);
  public inlineEditService: InlineEditService = inject(InlineEditService);

  collapsed = false;
  changeRequests: ChangeRequest[] = [];

  columns = ['entityType', 'entityId', 'property', 'originalValue', 'newValue', 'changedBy', 'comment', 'delete'];

  ngOnInit() {
    this.inlineEditService.changeRequests$.subscribe({
      next: (changeRequests: ChangeRequest[]) => {
        // const existingEvent = this.findChangeEvent(changeEvent.id, changeEvent.property);
        // if(existingEvent) this.changeEvents[this.changeEvents.indexOf(existingEvent)] =
        this.changeRequests = [...changeRequests].reverse();
      }
    })
  }

  findChangeEvent(id: string, property: string): ChangeRequest|undefined {
    return this.changeRequests.find((ce: ChangeRequest) => ce.entityId === id && ce.property === property)
  }

  discardChangeEvent(uuid: string){
    this.inlineEditService.discardChangeCandidate(uuid);
  }
}
