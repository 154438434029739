<div class="table-controls">
  <mat-form-field class="filter">
    <mat-label>Filter</mat-label>
    <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>
  <span class="filtered-data">
    {{filteredDataCount}} / {{totalDataCount}}
  </span>
  <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
