import { Component, OnInit } from '@angular/core';
import {ApiSearchService} from "../services/api-search.service";
import {XApiStats} from "../model";

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  stats: XApiStats|null = null; //{ totalAssets: null, parties: null, partyIds: null, recordings: null, releases: null, works: null};

  constructor(private apiSearchService: ApiSearchService) { }

  ngOnInit(): void {
   return this.getStats();
  }

  getStats(): void {
    this.apiSearchService.getStats().subscribe(
      (response: any) => {
        this.stats = response.results.stats;
      }
    );
  }

}
