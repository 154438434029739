import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from "@angular/common/http";
import { UserData} from "../model";
import { tap} from "rxjs/operators";
import {Observable, of, Subject, throwError} from "rxjs";
import { catchError } from "rxjs/operators";
import {HttpHelperService} from "./http.service";


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  userData: UserData;
  login$ = new Subject<UserData>();
  logout$ = new Subject<UserData>();

  private USER_DATA = "qud";
  private bffApiUrl = this.httpHelperService.getBffDomain();  // URL to web api

  constructor(private http: HttpClient, private httpHelperService: HttpHelperService) {
    this.userData = {
      id: "",
      name: "",
      company: "",
      email: "",
      role: "",
      apiKey: ""
    };
  }

  errorHandler(err: HttpErrorResponse): Observable<never> {
    let errorMessage;
    if (err.error instanceof Error) {
      errorMessage = 'An error occurred [' + err.message + ']';
    } else {
      errorMessage = "Cannot connect to local server [" + err.name + "]";
    }
    return throwError('Something bad happened; please try again later.');
  }

  getUserData(): UserData|null {
   const userDataStr = localStorage.getItem(this.USER_DATA);
   if (userDataStr){
     return JSON.parse(userDataStr) as UserData;
   }
   return null;
  }
  setUserData(userData: any|null): void {
    localStorage.removeItem(this.USER_DATA);
    if (userData != null) {
      localStorage.setItem(this.USER_DATA, JSON.stringify(userData));
    }
  }

  /**
   * login(key) --->  /client/lookup/key/$key     ---> 401
   *                                              ---> JWT token -> save token
   * isAuthenticated ---> /client/auth/token      ---> 401  ->  delete token
   *                                              ---> 200  ->  do nothing
   *
   */
  login(apiKey: string): any{
    if (apiKey == null || apiKey === '') { return of(null); }
    const url = `${this.bffApiUrl}/api/auth/authenticate/${apiKey}`;
    return this.http.get<any>(url, {withCredentials: true})
      .pipe(
          tap((userData: any) => {
            this.setUserData(userData);
            this.login$.next(userData);
        }),
        catchError(err => {
          console.log(err);
          // Unauthorizaed. Returned 401 or 403
          return throwError(err);
        })
      );
  }

  register(email: string, name: string, company: string, tags: string[] = []): Observable<HttpResponse<any>> {
    const url = this.bffApiUrl + '/app-home/any?key=__KEY__';
    return this.http.post<any>(`${this.bffApiUrl}/api/client/register`, {email, name, company, tags, url}, {observe: 'response'});
  }

  isAuthenticated(): boolean {
    return this.getUserData() !== null;
  }

  // setToken(token:string|null) {
  //   localStorage.removeItem(this.JWT_TOKEN);
  //   if(token != null) {
  //     localStorage.setItem(this.JWT_TOKEN, token);
  //   }
  // }
  // getToken() {
  //   return localStorage.getItem(this.JWT_TOKEN);
  // }

  logout(): Observable<HttpResponse<any>> {
    return this.http.post(`${this.bffApiUrl}/api/auth/invalidate/session`, {withCredentials: true}).pipe(
      tap((httpResponse: any) => {
        const userData = this.getUserData();
        if (userData != null) {
          this.logout$.next(userData);
        }
        this.setUserData(null);
      })
    );
  }

}
