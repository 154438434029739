<table-empty *ngIf="dataSource.data.length == 0" entity="Releases"></table-empty>

<div [ngClass]="{'hidden': dataSource.data.length == 0}">
  <table-controls></table-controls>
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
    <ng-container matColumnDef="visual">
      <mat-header-cell *matHeaderCellDef> Visual </mat-header-cell>
      <mat-cell *matCellDef="let release">
        <img src="{{release.cover}}" width="50" height="auto" loading="lazy"/>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type"> Type </mat-header-cell>
      <mat-cell *matCellDef="let release"> {{release.type}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="upc">
      <mat-header-cell *matHeaderCellDef mat-sort-header> UPC </mat-header-cell>
      <mat-cell *matCellDef="let release"> {{release.upc}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="title" >
      <mat-header-cell *matHeaderCellDef mat-sort-header> Release Title </mat-header-cell>
      <mat-cell *matCellDef="let release"> <a [routerLink]="['/app-home', 'upc', release.upc]">{{release.title}}</a> </mat-cell>
    </ng-container>
    <ng-container matColumnDef="year" >
      <mat-header-cell *matHeaderCellDef mat-sort-header> Release Year </mat-header-cell>
      <mat-cell *matCellDef="let release"> {{release.year}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row matRipple *matRowDef="let release; columns: columns;" ></mat-row>
  </mat-table>
</div>
<!-- <div>
  <div class="ms-2 d-inline float-end" >
    <img src="assets/images/icons/excel.png" class="ms-2" width="30" (click)="exportExcel()"/>
    <img src="assets/images/icons/csv.png" class="ms-2" width="30" (click)="exportCSV()"/>
  </div>
  <table class="table table-bordered">
    <thead>
    <tr>
      <th>Visual</th>
      <th class="width-1x">UPC</th>
      <th class="width-1x">Type</th>
      <th>Release Title</th>
      <th class="width-1x">Release Date</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td style="width:65px"></td>
      <td class="width-1x"><input type="text" placeholder="Search by UPC" [(ngModel)]="filterBy.upc" (keyup)="filter()"/> </td>
      <td class="width-1x"><input type="text" placeholder="Search by type" [(ngModel)]="filterBy.type" (keyup)="filter()"/> </td>
      <td><input type="text" placeholder="Search by title" [(ngModel)]="filterBy.title" (keyup)="filter()"/> </td>
      <td class="width-1x"><input type="text" placeholder="Search by date" [(ngModel)]="filterBy.year" (keyup)="filter()"/> </td>
    </tr>
    </tbody>
  </table>
  <table class="table table-bordered scroll">
    <tbody>
        <tr *ngFor="let rel of rows">
          <td style="width: 65px; padding: 5px;">
            <a [routerLink]="['/app-home','upc', rel.upc]" *ngIf="isLinkActive(rel)">
              <img src="{{rel.cover}}" width="50" height="auto" loading="lazy"/>
            </a>
            <img *ngIf="!isLinkActive(rel)" src="{{rel.cover}}" width="50" height="auto" loading="lazy"/>
          </td>
          <td class="width-1x">
            <a [routerLink]="['/app-home', 'upc', rel.upc]" *ngIf="isLinkActive(rel)">{{rel.upc}}</a>
            <span *ngIf="!isLinkActive(rel)">{{rel.upc}}</span>
          </td>
          <td class="width-1x">{{rel.type}}</td>
          <td>
            <a [routerLink]="['/app-home', 'upc', rel.upc]" *ngIf="isLinkActive(rel)">{{rel.title}}</a>
            <span *ngIf="!isLinkActive(rel)">{{rel.title}}</span>
          </td>
          <td class="width-1x">{{rel.year}}</td>
        </tr>
    </tbody>
  </table>
</div> -->
