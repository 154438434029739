<div class="mat-typography">
  <h1 mat-dialog-title>All {{data.idName}} ({{data.identifiers.length}})</h1>
  <div mat-dialog-content>
    <mat-list *ngIf="data.identifiers.length > 0">
      <mat-list-item *ngFor="let id of data.identifiers; let idx = index">
        <a class="link" *ngIf="data.links.length > 0" [href]="data.links[idx]" target="_blank">{{id}}</a>
        <div *ngIf="data.links.length == 0">{{id}}</div>
        <copy-to-clipboard [value]="id"></copy-to-clipboard>
      </mat-list-item>
    </mat-list>
    <h3 *ngIf="data.identifiers.length === 0">No additional identifiers</h3>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </div>
</div>
