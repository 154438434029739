import { Component, Input } from "@angular/core";

@Component({
  selector: 'cancon-flag',
  template: `
    <img class="canada-flag"
      *ngIf="isCancon"
      src="assets/svg-country-flags/ca.png"/>
  `,
  styles: [`
    .canada-flag {

    }
  `],
})
export class CanconFlagComponent {
  @Input() isCancon?: boolean = false
}
