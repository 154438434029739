import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
// import { DataSet } from 'vis-data/peer';
// import { Network } from 'vis-network/peer';
// import {Artist} from "../model";
// import {ResultService} from "../services/result.service";

@Component({
  selector: 'graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
})

export class GraphComponent implements OnInit, AfterViewInit {
//   @Input() artist:Artist;
//   @ViewChild('visNetwork', { static: false }) visNetwork!: ElementRef;
//   private networkInstance: any;
//
//   constructor(private resultService:ResultService) {
//     this.artist = this.resultService.emptyArtist();
//   }
//
  constructor(){}
  ngOnInit(): void {}
//
   ngAfterViewInit(): void {}
//     console.log("graph ngAfterViewInit");
//     // create an array with nodes
//     const artists = [
//       { id: 1, label: this.artist.name, color: {border: '#DD5D08', background: '#FF9156'}, borderWidth: '3'}
//     ]
//     const relationships = this.artist.type?.toLowerCase() === "person" ? this.artist.relationships?.isMemberOf : this.artist.relationships?.hasMember;
//
//     if(relationships) {
//       relationships.forEach((rel, idx) => {
//         artists.push({id: idx + 2, label: rel.name, color: {border: '#0B99B4', background: '#31C9E9'}, borderWidth: '3'});
//       })
//     }
//     console.log(artists);
//
//     const nodes = new DataSet<any>(artists);
//
//     const links = relationships?.map((rel, idx) => {
//       return { from: '1', to: idx+2};
//     }) || []
//     console.log(links);
//     // create an array with edges
//     const edges = new DataSet<any>(links);
//
//     const data = { nodes, edges };
//
//     const container = this.visNetwork;
//     this.networkInstance = new Network(container.nativeElement, data, {
//       height: '100%',
//       width: '100%',
//       nodes: {
//         shape: 'hexagon',
//         font: {
//           color: 'black',
//         },
//       },
//       edges: {
//         smooth: false,
//         arrows: {
//           to: {
//             enabled: true,
//             type: 'vee',
//           },
//         },
//       },
//     });
//   }
}
