<table-empty *ngIf="dataSource.data.length == 0" entity="Recordings"></table-empty>

<div [ngClass]="{'hidden': recordings.length == 0}">
  <table-controls></table-controls>
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData(sort)">
    <ng-container matColumnDef="audio" >
      <mat-header-cell *matHeaderCellDef> Audio </mat-header-cell>
      <mat-cell *matCellDef="let recording">
        <audio-player *ngIf="recording.audioUrl != '' && recording.audioUrl != null" format="mini" [audioUrl]="recording.audioUrl" [trackIsrc]="recording.isrc" [trackName]="recording.title"></audio-player>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="crtc" *ngIf="crtcService.isCrtcUser()">
      <mat-header-cell *matHeaderCellDef> MAIPL </mat-header-cell>
      <mat-cell *matCellDef="let recording">
        <maipl-score [score]="crtcMaiplScore$(recording)|async"></maipl-score>
        <cancon-flag [isCancon]="recording.crtcMaplStatus?.isCanadianContent"></cancon-flag>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="q2" *ngIf="q2">
      <mat-header-cell *matHeaderCellDef mat-sort-header="q2Score"> Q2 </mat-header-cell>
      <mat-cell *matCellDef="let recording">{{recording.q2Score}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="isrc" >
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ISRC"> ISRC </mat-header-cell>
      <mat-cell *matCellDef="let recording">{{recording.isrc}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title"> Title </mat-header-cell>
      <mat-cell *matCellDef="let recording">
        <a [routerLink]="['/app-home', 'isrc', recording.isrc]">{{recording.title}} </a>
        <em class="comments">{{recording.subtitle}}</em>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="year" >
      <mat-header-cell *matHeaderCellDef mat-sort-header> Release Year </mat-header-cell>
      <mat-cell *matCellDef="let recording"> {{recording.year}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="artist" >
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Artist / Role
        <mat-spinner *ngIf="this.contributorsLoading$|async" class="data-loading" color="accent"  diameter="20" mode="indeterminate"></mat-spinner>
      </mat-header-cell>
      <mat-cell *matCellDef="let recording">
        <div *ngFor=" let artist of recording.mainArtists" class="mainArtists">
          <party-link [party]="artist" ></party-link><mat-icon>arrow_forward</mat-icon> Main Artist
        </div>
        <div *ngFor=" let artist of recording.performers" class="performers">
          <party-link [party]="artist"></party-link><mat-icon>arrow_forward</mat-icon> {{artist.role}}
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row matRipple *matRowDef="let client; columns: columns;" ></mat-row>
  </mat-table>
</div>



<!-- <div>
  <div class="tab-title d-inline">{{rows.length}} / {{recordings.length}} Recordings</div>
  <div class="ms-2 d-inline float-end" >
    <img src="assets/images/icons/excel.png" class="ms-2" width="30" (click)="exportExcel()"/>
    <img src="assets/images/icons/csv.png" class="ms-2" width="30" (click)="exportCSV()"/>
  </div>
  <table class="table table-bordered">
    <thead>
    <tr>
      <th style="width:40px"></th>
      <th class="width-1x">ISRC</th>
      <th>Recording Title</th>
      <th>Subtitle</th>
      <th class="width-1x">Year</th>
      <th>Artists / Role</th>
      <th class="width-1x">Duration</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td style="width:40px"><input type="checkbox" [(ngModel)]="filterBy.audioUrl" (change)="filter()"/></td>
      <td class="width-1x"><input type="text" placeholder="Search by ISRC" [(ngModel)]="filterBy.isrc" (keyup)="filter()"/> </td>
      <td><input type="text" placeholder="Search by title" [(ngModel)]="filterBy.title" (keyup)="filter()"/> </td>
      <td><input type="text" placeholder="Search by subtitle" [(ngModel)]="filterBy.subtitle" (keyup)="filter()"/> </td>
      <td class="width-1x"><input type="text" placeholder="Search by year" [(ngModel)]="filterBy.year" (keyup)="filter()"/> </td>
      <td><input type="text" placeholder="Search by artist/role" [(ngModel)]="filterBy.contributors" (keyup)="filter()" /> </td>
      <td class="width-1x"></td>
    </tr>
    </tbody>
  </table>
  <table class="table table-bordered scroll">
    <tbody>
    <tr *ngFor="let rec of rows" >
      <td style="width:40px; padding-left: 0px;">
        <audio-player *ngIf="rec.audioUrl != '' && rec.audioUrl != null" [radius]="20" [audioUrl]="rec.audioUrl" [trackIsrc]="rec.isrc" [trackName]="rec.title"></audio-player>
      </td>
      <td class="width-1x">
        <a *ngIf="isLinkActive(rec)" [routerLink]="['/app-home', 'isrc', rec.isrc]" >{{rec.isrc}}</a>
        <span *ngIf="!isLinkActive(rec)">{{rec.isrc}}</span>
      </td>
      <td>
        <a *ngIf="isLinkActive(rec)" [routerLink]="['/app-home', 'isrc', rec.isrc]">{{rec.title}}</a>
        <span *ngIf="!isLinkActive(rec)">{{rec.title}}</span>
      </td>
      <td>{{rec.subtitle}}</td>
      <td class="width-1x">{{rec.year}}</td>
      <td>
        <ul class="list-unstyled" style="margin-bottom: 0px">
          <li *ngIf="rec.mainArtists && rec.mainArtists.length==0 && rec.performers && rec.performers.length == 0" style="width:100%;height:30px;">
            <div class="placeholder"></div>
            <i class="ion ion-md-arrow-forward mx-1"></i>
            <div class="placeholder"></div>
          </li>
          <li *ngFor=" let artist of rec.mainArtists">
            <strong><party-link [party]="artist" ></party-link> <i class="ion ion-md-arrow-forward mx-1"></i> Main Artist</strong>
          </li>
          <li *ngFor=" let artist of rec.performers">
            <party-link [party]="artist"></party-link> <i class="ion ion-md-arrow-forward mx-1"></i> {{artist.role}}
          </li>
        </ul>
      </td>
      <td class="width-1x">{{rec.duration | duration}}</td>
    </tr>
    </tbody>
  </table>
</div> -->
