<div class="mat-typography">
  <h2 mat-dialog-title>Export {{data.rows.length}} {{data.exportType}}s of {{data.entityName}}</h2>
  <div mat-dialog-content>

    <div style="line-height: 40px">
      What format do you want to export the data in ?
    </div>
  </div>
  <div mat-dialog-actions align='end'>
    <button mat-button mat-dialog-close>Close</button>
    <button mat-raised-button color="accent" (click)="exportAsExcel()" [disabled]="!canExportExcel()">Excel</button>
    <button mat-raised-button color="accent" (click)="exportAsCSV()" [disabled]="!canExportCSV()">CSV</button>
    <button mat-raised-button color="accent" (click)="exportAsJson()" [disabled]="!canExportJson()">JSON</button>
  </div>
</div>
