import { ApiSearchService } from './../services/api-search.service';
import { ResultService } from './../services/result.service';
import { inject, OnChanges } from '@angular/core';
import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Recording, Work } from "../model";
import { MatTableDataSource } from '@angular/material/table';
import { TableControlsComponent } from '../table-controls/table-controls.component';
import { MatSort } from '@angular/material/sort';

import {environment} from "../../environments/environment";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'table-works',
  templateUrl: './table-works.component.html',
  styleUrls: ['./table-works.component.scss']
})
export class TableWorksComponent implements AfterViewInit, OnChanges{
  @Input() works:Array<Work> = [];
  @Input() q2:boolean = false;
  @Input() recording:Recording|undefined;

  apiSearchService:ApiSearchService = inject(ApiSearchService)

  @ViewChild(TableControlsComponent) tableControls!: TableControlsComponent;
  @ViewChild(MatSort) sort!: MatSort;

  public dataSource = new MatTableDataSource<Work>([]);
  columns = ['bowi', 'iswc', 'title', 'artist'];

  contributorsLoading$ = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  ngOnChanges() {
    setTimeout(() => {
      this.tableControls.resetFilter();
      this.loadWorks();
    }, 1);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sort.sort({id: 'title', start: 'asc', disableClear: false})

    this.tableControls.paginator$?.subscribe((paginator) => {
      this.dataSource.paginator = paginator
    });

    this.tableControls.filter$.subscribe((filter) => {
      this.dataSource.filter = filter;
      this.tableControls.filteredDataCount = this.dataSource.filteredData.length;
    });

    if(this.q2 && environment.q2Score) this.initQ2Score();
  }

  initQ2Score() {
    this.columns.unshift('q2');
  }

  loadWorks() {
    // this.dataSource.data = this.works;
    // this.tableControls.filteredDataCount = this.dataSource.data.length;
    // this.tableControls.totalDataCount = this.dataSource.data.length;
    this.dataSource.data = this.works;
    this.tableControls.totalDataCount = this.dataSource.data.length;
    this.tableControls.filteredDataCount = this.dataSource.data.length;
    if(this.recording !== undefined){
      this.contributorsLoading$.next(true);
      this.fetchPaginatedWorks(this.recording.isrc, 0);
    } else {
      this.dataSource.data = this.works;
      this.tableControls.filteredDataCount = this.dataSource.data.length;
      this.tableControls.totalDataCount = this.dataSource.data.length;
    }
  }

  fetchPaginatedWorks(isrc: string, offset: number): void {
    this.apiSearchService.lookupWorksByRecordingId(isrc, offset)
      .subscribe((works: Work[]) => {
        if (works.length > 0) {
          offset += works.length;
          this.updateRowsWithContributors(works);
          this.fetchPaginatedWorks(isrc, offset);
        } else {
          this.contributorsLoading$.next(false);
        }
      })
  }

  updateRowsWithContributors(worksWithContrib: Work[]) {
      let updatedWorks:Work[] = [];
      worksWithContrib.forEach((work:Work) => {
        let match = this.dataSource.data.find((row:Work) =>
          ((row.iswc && (row.iswc === work.iswc)) || (row.bowi && (row.bowi === work.bowi)))
        );
        if(match) {
          match.contributors = work.contributors;
        }
      })
    return updatedWorks;
  }


}
