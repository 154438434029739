<div class="mat-typography">
  <h1 mat-dialog-title>Contact Us</h1>
  <div mat-dialog-content>
    <h3>You need to commit the changes or they will not be taken into account.</h3>
    <h3>If you turn off the edit mode, the current uncommitted changes will be discarded. </h3>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="accent" (click)="discardChanges()">Discard Changes</button>
  </div>
</div>
