import { RecordingOfWork } from './../model';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsController } from './../_common/tabs.controller';
import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import {Artist, Recording, Work} from "../model";
import {ResultService} from "../services/result.service";
import { TableRecordingsComponent } from '../table-recordings/table-recordings.component';
import { MatDialog } from '@angular/material/dialog';
import { ExportService } from '../services/export.service';

@Component({
  selector: 'app-work',
  templateUrl: './app-work.component.html',
  styleUrls: ['./app-work.component.css']
})
export class AppWorkComponent  extends TabsController implements OnInit, AfterViewInit {
  @Input() work:Work;
  @Input() recordings:Array<RecordingOfWork>;

  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;
  @ViewChild(TableRecordingsComponent) tableRecordingsComponent!: TableRecordingsComponent;

  recordingsLength:number|null = null;

  constructor(private resultService:ResultService,
    private exportService: ExportService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute) {
    super(router, route);
    this.work = this.resultService.emptyWork();
    this.recordings = [];
  }

  extractRecordingContributors() {
    this.recordings.forEach((rec:Recording) => {
      if(rec.mainArtists === null || typeof rec.mainArtists === "undefined") rec.mainArtists = [];
      rec.contributors?.forEach((contrib:Artist) => {
        if(contrib.contributorType === "MainArtist") {
          rec.mainArtists?.push(contrib);
        }
      })
    })
  }

  ngOnInit(): void {
    this.readActiveTabFromParam(this.matTabGroup);
    this.extractRecordingContributors();
  }

  ngAfterViewInit(): void {
    this.readActiveTabFromParam(this.matTabGroup);
  }

  onRecordingsComplete(event:any){
    this.recordingsLength = event;
  }


  exportWork() {
    this.exportService.exportWorkAsExcel(this.work, this.recordings, this.work.contributors || [], this.work.title)
  }

  // canExport(): boolean {
  //   return this.matTabGroup?.selectedIndex != null  && this.matTabGroup.selectedIndex >= 1 && this.matTabGroup.selectedIndex <= 2;
  // }

  // openExportDialog() {
  //   let exportType = null;
  //   let rows: Recording[]|null = [];
  //   let exportFn:{
  //     excel: null|((artistName:string, data:(any[])) => void),
  //     csv: null|((artistName:string, data:(any[])) => void)
  //   } = { excel: null, csv: null};

  //   switch(this.matTabGroup.selectedIndex) {
  //     case 1:
  //       exportType = "Recording";
  //       rows = [...this.tableRecordingsComponent.dataSource.filteredData];
  //       exportFn = {
  //         excel: this.exportService.exportRecordingsOfWorkAsExcel,
  //         csv:  this.exportService.exportRecordingsOfWorkAsCSV
  //       }
  //       break;
  //     break;
  //   }
  //   this.dialog.open(DialogExportComponent, {
  //     panelClass: "dialog",
  //     data: {entityName: this.work.title, exportType, rows, exportFn} as DialogExportData,
  //   });
  // }

}
