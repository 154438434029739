<div class="mat-typography">
    <div mat-dialog-title>MAIPL Score</div>

    <div mat-dialog-content>
      <mat-stepper style="height:100%" color="accent">
        <mat-step style="height:100%">
          <div class="step-content">
            <ng-template matStepLabel>Maipl Status</ng-template>
            <maipl-edit-panel
              [recording]="recording" [works]="works"></maipl-edit-panel>
              <div class="spacer"></div>
            <div mat-dialog-actions align="end" style="margin-bottom: 10px;">
              <button mat-button (click)="cancelAndClose()">Cancel</button>
              <button mat-raised-button color="warn" matStepperNext
                [matBadge]="changeRequestsCount$|async" matBadgeColor="accent">Review Changes</button>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div class="step-content">
            <ng-template matStepLabel>
              <span>Verify and commit your changes</span></ng-template>
            <change-requests [comments]="crComments"></change-requests>
            <div class="spacer"></div>
            <div mat-dialog-actions align="end" style="margin-bottom: 10px;">
              <button mat-button matStepperPrevious>Back</button>
              <button mat-raised-button (click)="discardAllChangeCandidates()">Discard</button>
              <button mat-raised-button color="warn" (click)="commitAllChangeCandidates()">Commit Changes</button>
            </div>
          </div>
        </mat-step>

      </mat-stepper>
    </div>
    <!--  -->

    <!-- </div> -->
</div>


