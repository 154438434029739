import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpHelperService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private bffAPIUrl = this.httpHelperService.getBffDomain();  // URL to web api

  constructor(private http: HttpClient, private httpHelperService: HttpHelperService) { }

  errorHandler(err: HttpErrorResponse): Observable<never> {
    let errorMessage;
    if (err.error instanceof Error) {
      errorMessage = "An error occurred [" + err.message + "]";
    } else {
      errorMessage = "Cannot connect to local server [" + err.name + "]";
    }
    return throwError('Something bad happened; please try again later.');
  }


  contactMessage(data: any): Observable<any> {
    const url = `${this.bffAPIUrl}/api/email/send/contactUs`;
    return this.http.post<any>(url, data).pipe(
      catchError((err) => {
        return this.errorHandler(err);
      })
    );
  }

  feedbackMessage(data: any): Observable<any> {
    const url = `${this.bffAPIUrl}/api/email/send/feedback`;
    return this.http.post<any>(url, data).pipe(
      catchError((err) => {
        return this.errorHandler(err);
      })
    );
  }

  getAKeyMessage(data: any): Observable<any> {
    const url = `${this.bffAPIUrl}/api/email/send/getAKey`;
    return this.http.post<any>(url, data).pipe(
      catchError((err) => {
        return this.errorHandler(err);
      })
    );
  }
}
