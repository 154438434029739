import { MatTableDataSource } from '@angular/material/table';
import { Component, Input, ViewChild, AfterViewInit, OnChanges, ChangeDetectionStrategy, inject } from '@angular/core';
import {Artist} from "../model";
import {ArtistService} from "../services/artist.service";
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { ImageService } from '../services/image.service';

import {remove as removeDiacritics} from 'diacritics';
@Component({
  selector: 'disambig',
  templateUrl: './disambig.component.html',
  styleUrls: ['./disambig.component.scss']
})
export class DisambigComponent implements OnChanges  {

  @Input() artists: Artist[];
  @Input() searchTerm: string|null = "";
  rows: Array<Artist> = [];


  topResultsArtists!: Artist[];
  otherResultsArtists$ = new BehaviorSubject<Artist[]>([]);
  otherResultsDatasource = new MatTableDataSource<Artist>();

  @ViewChild(MatSort) set matSort (sort:MatSort) {
    this.otherResultsDatasource.sort = sort;
    this.otherResultsDatasource.sort.sort({id: 'name', start: 'asc', disableClear: false})
  };

  disambigView = "grid";

  listViewProps = {
    columns: ['cover','name', 'type', 'gender', 'nationality', 'comments'],
    handlePaginatoreEvent: (event: PageEvent) => { console.log(event)}
  }

  public imageService = inject(ImageService)
  constructor(private artistService: ArtistService, private router: Router) {
    this.artists = [];
  }


  splitTopArtists() {
    const hasSameName = (artist:Artist) => {
      return  removeDiacritics(artist.name?.toLowerCase()) === removeDiacritics(this.searchTerm?.toLowerCase()||'')
    };
    const hasNationality = (artist:Artist) => { return  artist.nationality && artist.nationality !== null};
    const hasCover = (artist:Artist) => {return  artist.cover && artist.cover !== null};

    this.topResultsArtists = this.artists.filter((artist: Artist) => hasSameName(artist) && hasNationality(artist) && hasCover(artist))
    if(this.topResultsArtists.length === 0 ) {
      this.topResultsArtists = this.artists.filter((artist: Artist) => (hasSameName(artist) && hasCover(artist)) || (hasSameName(artist) && hasNationality(artist)) )
    }
    if(this.topResultsArtists.length === 0 ) {
      this.topResultsArtists = this.artists.filter((artist: Artist) => hasCover(artist) && (artist.popularity && artist.popularity > 0))
    }

    this.topResultsArtists = this.topResultsArtists.sort((a1: Artist, a2: Artist) => {
      if(!a1.popularity || !a2.popularity) return 0;
      return a2.popularity - a1.popularity;
    });
    this.topResultsArtists = this.topResultsArtists.slice(0,5);

    this.otherResultsArtists$.next(this.artists.filter((artist: Artist) => !this.topResultsArtists.includes(artist)));
  }

  ngOnChanges(): void {
    this.otherResultsArtists$.subscribe({
      next: (artists) => {
        this.otherResultsDatasource.data = artists;
      }
    })

    this.splitTopArtists();
  }

  artistHasIds(artist: Artist): boolean {
    return this.artistService.getIdentifier(artist);
  }

  isListView(): boolean{
    return this.disambigView === "list";
  }

  isGridView(): boolean {
    return this.disambigView === "grid";
  }

  setViewToList(): void{
    this.disambigView = "list";
  }
  setViewToGrid(): void{
    this.disambigView = "grid"
  }

  selectArtist(artist: Artist) {
    this.router.navigate(['/app-home', "quansicId", artist.ids.quansicId]);
  }



}
