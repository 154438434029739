<div class="mat-typography">
  <h1 mat-dialog-title><span *ngIf="data.lockAction">Lock</span><span *ngIf="!data.lockAction">Unlock</span> {{data.artist.name}}</h1>
  <div mat-dialog-content>
    <form [formGroup]="form">
      <div style="display: grid; gap: 10px;">
        <label>Comment</label>
        <mat-form-field appearance="fill">
          <textarea matInput rows="6" formControlName="comment"></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-raised-button color="accent" (click)="lock()" *ngIf="data.lockAction">Lock</button>
    <button mat-raised-button color="accent" (click)="unlock()" *ngIf="!data.lockAction">Unlock</button>
  </div>
</div>
