import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { ApiError, Result, UserData } from "../model";
import { EmailService } from "../services/email.service";
import { LoginService } from "../services/login.service";
import { ResultService } from "../services/result.service";



interface FeedbackInterface {
  topics: TemplateInterface[];
}
interface TemplateInterface {
  id: string;
  label: string;
  description: string[];
}

export const artistFeedback: FeedbackInterface = {
  topics: [
    {id: "info", label: "Artist Information (birthdate, nationality etc)", description: []},
    {id: "identifiers", label: "Artist Identifiers", description: []},
    {id: "relationships", label: "Relationships", description: [""]},
    {id: "variants", label: "Name Variants", description: [""]},
    {id: "recordings", label: "Recordings", description: [""]},
    {id: "releases", label: "Releases", description: [""]},
    {id: "works", label: "Works", description: [""]}
    ]
};
export const recordingFeedback: FeedbackInterface = {
  topics: [
    {id: "info", label: "Recording Information", description: []},
    {id: "identifiers", label: "Recording Identifiers", description: []},
    {id: "contributors", label: "Recording Contributors", description: []},
    {id: "releases", label: "Related Releases", description: []},
    {id: "works", label: "Related Works", description: []}
  ]
};
export const releaseFeedback: FeedbackInterface = {
  topics: [
    {id: "info", label: "Release Information", description: []},
    {id: "contributors", label: "Release Contributors", description: []},
    {id: "recordings", label: "Related Recordings", description: []}
  ]
};
export const workFeedback: FeedbackInterface = {
  topics: [
    {id: "info", label: "Work Information", description: []},
    {id: "identifiers", label: "Work Identifiers", description: []},
    {id: "contributors", label: "Work Contributors", description: []},
    {id: "recordings", label: "Related Recordings", description: []}
  ]
};
export const disambigFeedback: FeedbackInterface = {
  topics: [
    {id: "missing", label: "Missing Artist", description: ["Tell us everything you know about the missing artist", "Name, date of birth, nationality, identifiers..."]},
    {id: "other", label: "Other", description: [ ]}
  ]
};

export const identifierFeedback: FeedbackInterface = {
  topics: [
    {id: "missing", label: "Missing Entity", description: ["Tell us everything you know about the missing entity"]},
    {id: "other", label: "Other", description: [ ]}
  ]
};

type Context = 'disambig'|'identifier'|'artist'|'release'|'recording'|'work';


@Component({
  templateUrl: './dialog-feedback.component.html',
  styleUrls: ['./dialog-feedback.component.scss']
})
export class DialogFeedbackComponent {
  feedbackForm: FormGroup = new FormGroup({
    company: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    purpose: new FormControl(''),
    message: new FormControl('', [Validators.required])
  });
  templateFrom: FormGroup = new FormGroup({});
  feedback!: FeedbackInterface;
  selectedTemplate!: TemplateInterface;
  context: Context|null = null;
  contextDetails = "";
  msgPlaceholder = "";
  feedbackSent = false;
  feedbackFailed = false;
  purposes: string[] = [];
  nextId = 0;
  opened = false;
  @Input() idModal: string|null = null;
  @Input() callback: ((formData: any) => Observable<any>)|null = null;


  resultSubscription!: Subscription;
  result: Result = this.resultService.defaultResult();

  constructor(private loginService: LoginService, private resultService: ResultService, private emailService: EmailService) {}

  ngOnInit(): void {
    if (this.idModal == null) { this.idModal = `panel-feedback-${this.nextId++}`; }

    this.initFormFromUserData();

    this.initResults();
  }

  ngOnDestroy(): void {
    this.resultSubscription?.unsubscribe();
  }

  initFormFromUserData(): void {
    const userData = this.loginService.getUserData() as UserData;
    if (userData){
      this.feedbackForm.get('email')?.setValue(userData.email);
      this.feedbackForm.get('name')?.setValue(userData.name);
      this.feedbackForm.get('company')?.setValue(userData.company);
    }
  }

  initResults(): void {
    if (this.resultSubscription) {this.resultSubscription.unsubscribe(); }
    this.resultSubscription = this.resultService.getResult().subscribe({
      next: (result: Result) => {
        this.result = result;
        this.setContext();
        if (result.error === ApiError.NOT_FOUND) { this.feedback = identifierFeedback; }
        if (result.isShowDisambig) { this.feedback = disambigFeedback; }
        if (result.selectedArtist) { this.feedback = artistFeedback; }
        if (result.selectedRecording) { this.feedback = recordingFeedback; }
        if (result.selectedRelease) { this.feedback = releaseFeedback; }
        if (result.selectedWork) { this.feedback = workFeedback; }
        this.feedbackForm.get('purpose')?.setValue(this.feedback?.topics[0].id);
        this.setMessagePlaceholder();
      }
    });
  }

  reset(): void{
    this.feedbackSent = false;
    this.feedbackFailed = false;
    this.feedbackForm.get('message')?.setValue(null);
    // this.ngOnInit();
  }

  submit(): void {
    this.emailService.feedbackMessage({
      email: this.feedbackForm.get('email')?.value,
      name: this.feedbackForm.get('name')?.value,
      company: this.feedbackForm.get('company')?.value,
      context: this.contextDetails,
      purpose: this.feedbackForm.get('purpose')?.value,
      message: this.feedbackForm.get('message')?.value
    }).subscribe((data: any) => {
      if (data && data.status === "OK") {
        this.feedbackSent = true;
        this.feedbackFailed = false;
      } else {
        this.feedbackSent = false;
        this.feedbackFailed = true;
      }
    });
  }


  setContext(): void{
    if (this.result.error === ApiError.NOT_FOUND && this.result.errorId) {
      // this.context = "disambig";
      this.contextDetails = `Missing Entity. ${this.result.errorId.idType} -> ${this.result.errorId.id}`;
    }
    if (this.result.artistsForDisambiguation != null) {
      const artists = this.result.artistsForDisambiguation.map((artist => `${artist.name} (${artist.id})}`)).join(" - ");
      this.contextDetails = `Name Disambig. Query: ${this.result.nameDisambigQuery} -> ${artists}`;
    }
    if (this.result.selectedArtist) {
      this.contextDetails = `Artist QID: ${this.result.selectedArtist.id} (${this.result.selectedArtist.name})`;
    }
    if (this.result.selectedRecording) {
      this.contextDetails = `Recording ISRC: ${this.result.selectedRecording.isrc} (${this.result.selectedRecording.title})`;
    }
    if (this.result.selectedRelease) {
      this.contextDetails = `Release UPC: ${this.result.selectedRelease.upc} (${this.result.selectedRelease.title})`;
    }
    if (this.result.selectedWork) {
      this.contextDetails = `Work ${this.result.selectedWork.iswc || this.result.selectedWork.bowi} (${this.result.selectedWork.title})`;
    }
    this.setMessagePlaceholder();
  }

  // buildPayload() {
  //   if(this.)
  // }

  isCompanyInvalid(): boolean {
    return false;
  }

  isNameInvalid(): boolean {
    return false;
  }

  isEmailInvalid(): boolean{
    return false;
  }
  isEmailEmpty(): boolean {
    return false;
  }
  isEmailFormatInvalid(): boolean{
    return false;
  }

  closePanel(): void {
    this.feedbackSent = false;
    this.feedbackFailed = false;
    this.opened = false;
  }

  openPanel(): void {
    this.feedbackSent = false;
    this.feedbackFailed = false;
    this.opened = true;
  }

  setMessagePlaceholder(): void {
    const template = this.feedback?.topics.find((topic) => topic.id === this.feedbackForm.get('purpose')?.value);
    if (template) {
      this.msgPlaceholder = template.description !== null ? (template.description as string[]).join("\n") : "";
    }
  }

}
