import { BehaviorSubject } from 'rxjs';
import { Component, Input, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import {Recording, Artist} from "../model";
import {DurationPipe} from "../pipes/duration.pipe";
import {ApiSearchService} from "../services/api-search.service";
import {ResultService} from "../services/result.service";
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { TableControlsComponent } from '../table-controls/table-controls.component';

import {environment} from "../../environments/environment";
import { CrtcService } from '../services/crtc.service';


@Component({
  selector: 'table-recordings',
  templateUrl: './table-recordings.component.html',
  styleUrls: ['./table-recordings.component.scss'],
  providers: [DurationPipe]
})
export class TableRecordingsComponent implements OnChanges, AfterViewInit {
  @Input() recordings:Array<Recording> = [];
  @Input() artist:Artist;
  @Input() q2:boolean = false;

  @ViewChild(TableControlsComponent) tableControls!: TableControlsComponent;
  @ViewChild(MatSort) sort!: MatSort;

  public dataSource = new MatTableDataSource<Recording>([]);
  columns = ['isrc', 'audio', 'title', 'year', 'artist'];

  contributorsLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private resultService:ResultService,
    private apiSearchService:ApiSearchService,
    public crtcService: CrtcService) {
    this.artist = this.resultService.emptyArtist();
  }

  ngOnChanges() {
    setTimeout(() => {
      this.tableControls.resetFilter();
      this.loadRecordings();
    },1);
  }

  ngAfterViewInit() {
    this.tableControls.paginator$.subscribe((paginator) => {
      this.dataSource.paginator = paginator
    });

    this.tableControls.filter$.subscribe((filter) => {
      this.dataSource.filter = filter;
      this.tableControls.filteredDataCount = this.dataSource.filteredData.length;
    });

    if(this.q2 && environment.q2Score) this.columns.unshift('q2');
    if(this.crtcService.isCrtcUser()) this.columns.unshift('crtc');
  }


  sortData(sort: Sort){
    if(!this.dataSource.sort){
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sort({id: sort.active, start: sort.direction, disableClear: false});
    }
  }

  intialSort() {
    let data = [...this.dataSource.data];
    this.dataSource.data = data.sort((a: Recording, b: Recording) => {
      const aYear = a.year ? a.year : 0;
      const bYear = b.year ? b.year : 0;
      return  bYear - aYear || a.title.localeCompare(b.title)
    });
  }


  originalSortComparator!: (recordings: Recording[], sort: MatSort) =>  Recording[];
  initialLoad = true;


  onSort(sort: Sort){
    if(this.initialLoad) {
      this.dataSource.sortData = this.originalSortComparator;
      this.initialLoad = false;
    }
  }

  loadRecordings() {
    this.dataSource.data = this.recordings;
    this.tableControls.totalDataCount = this.dataSource.data.length;
    this.tableControls.filteredDataCount = this.dataSource.data.length;
    if(this.artist.ids.quansicId !== ''){
      this.contributorsLoading$.next(true);
      this.fetchPaginatedRecordings(this.artist.ids.quansicId, 0);
    } else {
      this.dataSource.data = this.recordings;
      this.tableControls.filteredDataCount = this.dataSource.data.length;
      this.tableControls.totalDataCount = this.dataSource.data.length;
    }
  }

  fetchPaginatedRecordings(id: string, offset: number): void {
    this.apiSearchService.lookupRecordingsByQuansicId(id, offset)
      .subscribe((recordings: Recording[]) => {
        if (recordings.length > 0) {
          offset += recordings.length;
          this.updateRowsWithContributors(recordings);
          this.fetchPaginatedRecordings(id, offset);
        } else {
          this.contributorsLoading$.next(false);
        }
      })
  }

  updateRowsWithContributors(recordingsWithContrib: Recording[]) {
      let updatedRecs:Recording[] = [];
      recordingsWithContrib.forEach((recording:Recording) => {
        let match = this.dataSource.data.find((row:Recording) => row.isrc == recording.isrc);
        if(match) {
          match.mainArtists = this.getMainArtists(recording);
          match.performers = this.getPerformers(recording);
        }
      })
    return updatedRecs;
  }

  getMainArtists(recording:Recording):Artist[] {
    let mainArtists:Artist[] = [];
    recording.contributors?.forEach((contrib:Artist) => {
      if(contrib.contributorType === "MainArtist") {
        mainArtists.push(contrib);
      }
    })
    return mainArtists;
  }

  getPerformers(recording:Recording):Artist[] {
    let performers:Artist[] = [];
    recording.contributors?.forEach((contrib:Artist) => {
      if(contrib.contributorType === "NonFeaturedArtist" && contrib.name === this.artist.name){
        recording.performers?.push(contrib);
      }
    })
    return performers;
  }

  getMainArtistsString(recording:Recording):string|undefined{
    return recording.mainArtists?.map((ma:Artist) => {return ma.name; }).join("|")
  }

  getPerformersString(recording:Recording):string|undefined{
    return recording.performers?.map((p:Artist) => { return p.name + " (" + p.role + ")"}).join("|")
  }

  isLinkActive(recording:Recording) {
    return !environment.demo || (typeof recording.active !== "undefined" && recording.active);
  }
  crtcMaiplScore$(recording: Recording) {
    return this.crtcService.maiplScoreStatus$(recording)
  }
}
