
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableWorksComponent } from './../table-works/table-works.component';
import { TableReleasesComponent } from './../table-releases/table-releases.component';
import { MatTabGroup } from '@angular/material/tabs';
import { Component, Input, OnChanges, ViewChild, ViewRef, AfterViewInit, Inject, ElementRef } from '@angular/core';

import {Artist, NameVariant, Recording, Release, Work} from "../model";
import {ResultService} from "../services/result.service";
import {ExportService} from "../services/export.service";
import {ProgressService} from "../services/progress.service";
import { ImageService } from '../services/image.service';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {TabsController} from "../_common/tabs.controller";
import {LockService} from "../services/lock.service";
import { environment } from '../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { TableRecordingsComponent } from '../table-recordings/table-recordings.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogLockComponent } from '../dialog-lock/dialog-lock.component';
import { DialogCoverComponent } from '../dialog-cover/dialog-cover.component';
import { DialogArtistExportComponent, DialogArtistExportData } from '../dialog-artist-export/dialog-artist-export.component';
import { DialogRowsExportComponent, DialogRowsExportData } from '../dialog-rows-export/dialog-rows-export.component';
import { CrtcService } from '../services/crtc.service';

declare var toastr:any;

@Component({
  selector: 'app-artist',
  templateUrl: './app-artist.component.html',
  styleUrls: ['./app-artist.component.scss']
})
export class AppArtistComponent extends TabsController implements OnChanges, AfterViewInit {
  @Input() artist: Artist;
  @Input() recordings: Array<Recording>;
  @Input() releases: Array<Release>;
  @Input() works: Array<Work>;

  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;
  @ViewChild(TableReleasesComponent) tableReleasesComponent!: TableReleasesComponent;
  @ViewChild(TableRecordingsComponent) tableRecordingsComponent!: TableRecordingsComponent;
  @ViewChild(TableWorksComponent) tableWorksComponent!: TableWorksComponent;

  nameVariantSlices: NameVariant[][] = [];
  namevariants: NameVariant[][] = new Array([]);
  releasesLength: number|null = null;
  recordingsLength: number|null = null;
  worksLength: number|null = null;
  cover: string|undefined = "";

  isDataTestEnvironment: boolean = environment.dataTest;
  isLocked: boolean|null = null;

  defaultArtistCover = '/assets/images/artist_default.jpg';

  progressSubscription: Subscription;

  toasterOptions: any = {
    "preventDuplicates": true,
    "positionClass": "toast-top-center",
    "timeOut": "2000",
  }

  constructor(
    private resultService: ResultService,
    private progressService: ProgressService,
    private exportService: ExportService,
    private lockService: LockService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public imageService: ImageService,
    public crtcService: CrtcService) {

    super(router, route);
    this.artist = this.resultService.emptyArtist();
    this.recordings = [];
    this.releases = [];
    this.works = [];
    // Remove artist cover while the next one is downloading, to avoid the privous artist cover to stick on the next artist's page
    this.progressSubscription = this.progressService.getProgressTopic().subscribe(() => {
      this.cover = undefined;
    })
  }

  ngOnChanges(): void {
    this.cover = this.imageService.convertImagePath(this.artist.cover);
    this.lookupArtistLockedStatus();
  }

  ngAfterViewInit(){
    this.readActiveTabFromParam(this.matTabGroup);
  }

  lookupArtistLockedStatus(): void {
    this.lockService.isLocked(this.artist.id).subscribe((isLocked: boolean) => {
      this.isLocked = isLocked;
    })
  }


  displaySuccessLockEventMessage(): void{
    toastr.options = this.toasterOptions;
    toastr.info(`The artist ${this.artist.name} has been locked`);
  }
  displaySuccessUnlockEventMessage(): void{
    toastr.options = this.toasterOptions;
    toastr.info(`The artist ${this.artist.name} has been unlocked`);
  }
  displayErrorEventMessage(error: string): void{
    toastr.options = this.toasterOptions;
    toastr.error(`The artist ${this.artist.name} lock/unlock attempt has failed [${error}]`);
  }

  canExport(): boolean {
    return this.matTabGroup?.selectedIndex != null  && this.matTabGroup.selectedIndex >= 1 && this.matTabGroup.selectedIndex <= 3;
  }

  exportArtist(): void {
    // this.exportService.exportPartyAsJson(this.artist, this.releases, this.recordings, this.works, this.artist.name);
    this.dialog.open(DialogArtistExportComponent, {
      panelClass: "dialog",
      data: {
        artist: this.artist, releases: this.releases, recordings: this.recordings, works: this.works,
        exportFn: {
          excel: this.exportService.exportPartyAsExcel,
          json: this.exportService.exportPartyAsJson,
        }} as DialogArtistExportData,
    });
  }

  openExportRowsDialog() {
    let rows: Release[]|Recording[]|Work[]|null = [];
    let exportFn = {};
    let exportType: string|null = null;

    switch(this.matTabGroup.selectedIndex) {
      case 1:
        exportType = "Release";
        rows = [...this.tableReleasesComponent.dataSource.filteredData];
        exportFn = {
          excel: this.exportService.exportReleasesAsExcel,
          csv:  this.exportService.exportReleasesAsCSV,
        }
        break;
      case 2:
        exportType = "Recording";
        rows = [...this.tableRecordingsComponent.dataSource.filteredData];
        exportFn = {
          excel: this.exportService.exportRecordingsAsExcel,
          csv: this.exportService.exportRecordingsAsCSV
        }
        break;
      case 3:
        exportType = "Work";
        rows = [...this.tableWorksComponent.dataSource.filteredData];
        exportFn = {
          excel: this.exportService.exportWorksAsExcel,
          csv:  this.exportService.exportWorksAsCSV
        }
        break;
    }
    this.dialog.open(DialogRowsExportComponent, {
      panelClass: "dialog",
      data: {artist: this.artist, entityName: this.artist.name, exportType, rows, exportFn} as DialogRowsExportData,
    });
  }

  openLockDialog() {
    this.dialog.open(DialogLockComponent, {
      panelClass: 'dialog',
      data: {
        artist: this.artist,
        lockAction: !this.isLocked,
        successCallback: (lock: boolean) => {
          lock ? this.displaySuccessLockEventMessage() : this.displaySuccessUnlockEventMessage();
        },
        errorCallback: (err: string) => {
          this.displayErrorEventMessage(err);
        }
    }});
  }

  openCoverDialog() {
    this.dialog.open(DialogCoverComponent, {
      width: '45vw',
      data: {
        title: this.artist.name,
        cover: this.imageService.convertImagePath(this.artist.cover) || this.defaultArtistCover,
        originalUrl: this.artist.cover
      }
    })
  }

  getActiveDataSource() {
    let dataSource: MatTableDataSource<any>|null = null;
    switch(this.matTabGroup.selectedIndex) {
      case 1:
        dataSource = this.tableReleasesComponent.dataSource;
        break;
      case 2:
      dataSource = this.tableRecordingsComponent.dataSource;
      break;
      case 3:
      dataSource = this.tableWorksComponent.dataSource;
      break;
    }
  }
}


