import { Injectable } from '@angular/core';
import { Observable, Subject} from "rxjs";
import { Progress } from '../model';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  progressTopic = new Subject<Progress>();
  playbackTopic = new Subject<Progress>();

  getProgressTopic(): Observable<Progress>   {
    return this.progressTopic.asObservable();
  }

  startQueryInProgress(): void{
    this.progressTopic.next({ queryInProgress: true });
  }
  stopQueryInProgress(): void{
    this.progressTopic.next({ queryInProgress: false });
  }


  getPlaybackTopic(): Observable<Progress>{
    return this.playbackTopic.asObservable();
  }
  startAudioPlayback(trackIsrc: string): void {
    return this.playbackTopic.next({playbackInProgress: true, trackIsrc});
  }
}
