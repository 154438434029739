import { WorkOfRecording, XCrtcMaplStatus } from './../../model';
import { Country } from '@angular-material-extensions/select-country';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { of, map, tap, switchMap, zip, EMPTY, Observable } from 'rxjs';
import { XApiResponse, Work, Recording, Artist } from 'src/app/model';
import { CrtcMaplScore, CrtcService, MaiplCategory } from 'src/app/services/crtc.service';
import { HttpHelperService } from 'src/app/services/http.service';
import { CandidateChangeRequest, InlineEditService } from 'src/app/services/inline-edit.service';
import { ResultService } from 'src/app/services/result.service';


export interface ContributorOfWork {
  id: string
  work: Work
  contributor: Artist
}
@Component({
  selector: 'maipl-edit-panel',
  templateUrl: './maipl-edit-panel.component.html',
  styleUrls: ['./maipl-edit-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaiplEditPanelComponent {
  @Input('recording') recording!: Recording;
  @Input('works') works!: Work[];

  recordingContribDS: MatTableDataSource<AbstractControl> = new MatTableDataSource();
  workContribDS: MatTableDataSource<AbstractControl> = new MatTableDataSource();

  columns = {
    contributors: ['isni', 'name', 'role', 'nationality'],
    works: ['isni', 'name', 'role', 'nationality']
  }
  work: WorkOfRecording|undefined = undefined

  maiplScore$: Observable<CrtcMaplScore> = EMPTY
  isCanadianContent$: Observable<boolean> = EMPTY;


  form = new FormGroup({
    recordingContributors: new FormArray([]),
    workContributors: new FormArray([]),
  })

  constructor(
    public inlineEditService: InlineEditService,
    private crtcService: CrtcService) {}

  ngOnInit(){
    this.initMaiplScore()

    this.initContributorsForm();

    this.fetchWorkContributors();
  }

  ngAfterViewInit() {
    const rcTableContainer = document.querySelector('.recording-table-container');
    const rcHeaderRow = rcTableContainer?.querySelector('mat-header-row');
    const rcMatTable = rcTableContainer?.querySelector('mat-table');
    if (rcTableContainer && rcHeaderRow && rcMatTable) {
      rcTableContainer.insertBefore(rcHeaderRow, rcMatTable);
    }

    const wkTableContainer = document.querySelector('.work-table-container');
    const wkHeaderRow = wkTableContainer?.querySelector('mat-header-row');
    const wkMatTable = wkTableContainer?.querySelector('mat-table');
    if (wkTableContainer && wkHeaderRow && wkMatTable) {
      wkTableContainer.insertBefore(wkHeaderRow, wkMatTable);
    }
  }

  initContributorsForm() {
    const recContribCtl = this.form.get('recordingContributors') as FormArray;
    this.recording.contributors!.forEach(c => {
      const isni = (c.ids.isnis !== undefined) ? c.ids.isnis[0]: ''
      // const role = c.contributorType !== 'MainArtist' ? c.role : c.contributorType
      if(c.contributorType !== 'MainArtist') return;
      recContribCtl.push(new FormGroup({
        id: new FormControl(c.ids.quansicId),
        displayId: new FormControl(isni),
        name: new FormControl(c.name),
        role: new FormControl(c.contributorType),
        nationality: new FormControl(c.nationality),
        nationalityAlpha2: new FormControl(this.nationalityToAlpha2(c.nationality)),
      }))
    })
    this.recordingContribDS.data = recContribCtl.controls
  }

  nationalityToAlpha2(nationality: string): Country {
    return {
      alpha2Code: nationality,
      name:'', alpha3Code:'', numericCode:'', callingCode:''
    }
  }

  initMaiplScore(){
    this.maiplScore$ = this.crtcService.maiplScoreStatus$(this.recording)
    // this.crtcMaiplScore = this.crtcService.getMaiplScore(this.recording.crtcMaplStatus);
    this.isCanadianContent$ = this.crtcService.maiplCanconStatus$(this.recording)
    //this.recording.crtcMaplStatus?.isCanadianContent || false;
  }

  editMaiplScore(maiplScore: CrtcMaplScore, maiplCategory: MaiplCategory) {
    const originalValue = Boolean(maiplScore[maiplCategory])
    const newValue = Boolean(!(maiplScore[maiplCategory]))
    const changeCandidate: CandidateChangeRequest = {
      entityId: this.recording.id,
      entityType: this.recording.entityType,
      entityName: this.recording.title,
      displayId: this.recording.id,
      property: `crtcMaiplScore.${maiplCategory}`,
      originalValue: originalValue,
      newValue: newValue,
      ySources: this.recording.ySources
    };
    this.inlineEditService.pushChangeRequestCandidate(changeCandidate);
  }

  editMaiplIsCancon(crtcMaplStatus: XCrtcMaplStatus|undefined) {
    if(crtcMaplStatus === undefined) return
    const originalValue = Boolean(crtcMaplStatus.isCanadianContent)
    const newValue = Boolean(!crtcMaplStatus.isCanadianContent)
    const changeCandidate: CandidateChangeRequest = {
      entityId: this.recording.id,
      entityType: this.recording.entityType,
      entityName: this.recording.title,
      displayId: this.recording.id,
      property: `isCanadianContent`,
      originalValue: originalValue,
      newValue: newValue,
      ySources: this.recording.ySources
    };
    this.inlineEditService.pushChangeRequestCandidate(changeCandidate);
  }

  editNationality(contributor: any){
    const changeCandidate: CandidateChangeRequest = {
      entityId: contributor.id,
      entityType: 'party',
      entityName: contributor.name,
      displayId: contributor.displayId,
      property: `nationality`,
      originalValue: contributor.nationality,
      newValue: contributor.nationalityAlpha2.alpha2Code,
      ySources: {}
    };
    this.inlineEditService.pushChangeRequestCandidate(changeCandidate);
  }

  fetchWorkContributors() {
    of(this.works).pipe(
      // map(works => works.map((work: Work) => {
      //   if(work.bowi) return {id: work.bowi, type: "bowi"}
      //   if(work.iswc) return {id: work.iswc, type: "iswc"}
      //   return null;
      // })),
      // tap((works:any) => console.log(works)),
      // map(works => works.map((work:any) => `${this.httpHelperService.getBffDomain()}/api/x/lookup/work/${work.type}/${work.id}`)),
      // switchMap((urls:string[]) => zip(...urls.map((url:string) => this.http.get<XApiResponse>(url, {withCredentials: true})))),
      // tap((responses:any) => console.log("responses", responses)),
      // map((responses:XApiResponse[]) => responses.filter((response: XApiResponse) => response.status === "OK")),
      // map((responses:XApiResponse[]) => responses.map((response:XApiResponse) => {
      //   const work = response.results.work;
      //   if(work !== undefined) return this.resultService.toWorksFromXApiWorks([work])[0]
      //   else return this.resultService.emptyWork()
      // })),
      // tap((works:Work[]) => console.log("works", works)),
      map((works: WorkOfRecording[]) => [...works].sort((w1, w2) => (w2.q2Score||0) - (w1.q2Score||0))),
      map((works: WorkOfRecording[]) => works[0]), //take the first one
      map((work:WorkOfRecording|null) => {
        let contributors:ContributorOfWork[] = [];
        if(work !== null){
          this.work = work;
          if(work.contributors !== undefined){
            contributors = contributors.concat(
              work.contributors?.map(contrib => {
                return ({
                  id: `${contrib.ids.quansicId}`,
                  work: work,
                  contributor: contrib
                } as ContributorOfWork)
            }))
          }
        }
        return contributors
      }),
      // tap((contributors) => console.log("contributors", contributors)),
      tap((contributors) => {
        const workContribCtl = this.form.get('workContributors') as FormArray;
        contributors.forEach((cfw: ContributorOfWork) => {
          const isni = (cfw.contributor.ids.isnis !== undefined) ? cfw.contributor.ids.isnis[0] : ''
          workContribCtl.push(new FormGroup({
            id: new FormControl(cfw.contributor.ids.quansicId),
            displayId: new FormControl(isni),
            name: new FormControl(cfw.contributor.name),
            role: new FormControl(cfw.contributor.role),
            nationality: new FormControl(cfw.contributor.nationality),
            nationalityAlpha2: new FormControl(this.nationalityToAlpha2(cfw.contributor.nationality)),
          }))
        })
        this.workContribDS.data = workContribCtl.controls
      })
    ).subscribe()
  }
}
