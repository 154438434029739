<div class="mat-typography">
  <h1 mat-dialog-title>Change Logs</h1>
  <div mat-dialog-content>
    <div mat-list *ngIf="(changeLogs$|async) as changeLogs">
      <ng-container *ngIf="changeLogs && changeLogs.length > 0 ; else nochange">
        <mat-table [dataSource]="datasource" class="change-logs">
          <ng-container matColumnDef="property" >
            <mat-header-cell *matHeaderCellDef>Field</mat-header-cell>
            <mat-cell *matCellDef="let changeLog">{{changeLog.field.name}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="originalValue" >
            <mat-header-cell *matHeaderCellDef>Original Value</mat-header-cell>
            <mat-cell *matCellDef="let changeLog">
              <em *ngIf="changeLog.field.oldValue === null">not defined</em>
              {{changeLog.field.oldValue}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="newValue" >
            <mat-header-cell *matHeaderCellDef>New Value</mat-header-cell>
            <mat-cell *matCellDef="let changeLog">{{changeLog.field.newValue}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="user" >
            <mat-header-cell *matHeaderCellDef>User</mat-header-cell>
            <mat-cell *matCellDef="let changeLog">{{changeLog.requester.id}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="comment" >
            <mat-header-cell *matHeaderCellDef>Comment</mat-header-cell>
            <mat-cell *matCellDef="let changeLog">{{changeLog.history[0].statusComment}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="date" >
            <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
            <mat-cell *matCellDef="let changeLog">{{changeLog.history[0].timestamp|date:'dd-MM-yyyy'}}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row matRipple *matRowDef="let changeLog; columns: columns;"></mat-row>
        </mat-table>
      </ng-container>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
  </div>
</div>

<ng-template #nochange>
  No pending change
</ng-template>
