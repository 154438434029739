import { YDBService } from './y-db.service';
import { InlineEditService } from './inline-edit.service';
import { XCrtcMaplStatus, Recording } from './../model';
import { inject, Injectable } from "@angular/core";
import { map, Observable, of, share, shareReplay, zip } from 'rxjs';
import { LoginService } from '@githubquansic/web-auth/ng';

export type MaiplCategory = 'music'|'artist'|'instrument'|'performance'|'lyrics'
export type CrtcMaplScore = {
  code: string
  source: string
} & {[key in MaiplCategory]: boolean}

@Injectable({
  providedIn: 'root'
})
export class CrtcService {
  inlineEditService = inject(InlineEditService)
  yDbService = inject(YDBService)
  loginService = inject(LoginService)

  emptyMaplScore = {
    code: '',
    music: false,
    artist: false,
    instrument: false,
    performance: false,
    lyrics: false,
    source: ''
  }

  isCrtcUser(){
    const userData = this.loginService.getUserData()
    return userData?.company?.toLowerCase() === "crtc"
  }

  // changesForEntity$(entityId: string, property: string) {
  //   return this.yDbService.getActiveChangeRequestsByEntityIdAndProperty$(entityId, property)
  // }

  // changesForCategory$( recording: Recording, category: string) {
  //   return this.changesForEntity$(recording.id, category)
  // }

  maiplScoreStatus$(recording: Recording): Observable<CrtcMaplScore> {
    // this.changesForCategory$(recording, 'crtcMaiplScore.music'),
    // this.changesForCategory$(recording, 'crtcMaiplScore.artist'),
    // this.changesForCategory$(recording, 'crtcMaiplScore.instrument'),
    // this.changesForCategory$(recording, 'crtcMaiplScore.performance'),
    // this.changesForCategory$(recording, 'crtcMaiplScore.lyrics'),
    return of(this.getMaiplScore(recording.crtcMaplStatus))
    .pipe(
      // map(([origScore, crMusic, crArtist, crInstrument, crPerformer, crLyrics]) => {
      map((score) => {
        // let score = {...origScore};
        // [crMusic, crArtist, crInstrument, crPerformer, crLyrics].flat().forEach(cr => {
        //   const category: MaiplCategory = cr.property.split('.')[1] as MaiplCategory
        //   score[category] = cr.newValue as boolean
        // })
        score.code = (score.music ? 'M' :'') + (score.artist ? 'A' : '') +(score.instrument ? 'I': '') +
                     (score.performance ? 'P' : '') + (score.lyrics ? 'L' : '')
        return score
      })
    )
  }

  maiplCanconStatus$(recording: Recording): Observable<boolean> {
    return of(recording.crtcMaplStatus?.isCanadianContent)
    .pipe(
      map((isCancon) => {
        return !!isCancon;
      })
    )
  }

  getMaiplScore (crtcMaplStatus: XCrtcMaplStatus|undefined): CrtcMaplScore {
    let score: CrtcMaplScore = this.emptyMaplScore
    if(crtcMaplStatus !== undefined){
      const classifications = crtcMaplStatus.classifications
      if(classifications){
        const maiplMap  = new Map(Object.entries(classifications));
        const code = maiplMap.get('CRTC')||maiplMap.get('Quansic')||'';
        const codeLC = code.toLowerCase()
        return {
          code,
          music: codeLC.includes('m'),
          artist: codeLC.includes('a'),
          instrument: codeLC.includes('i'),
          performance: codeLC.includes('p'),
          lyrics: codeLC.includes('l'),
          source: 'Quansic'
        }
      }
    }
    return score;
  }
}
