import { CrtcMaplScore, MaiplCategory } from './../../services/crtc.service';
import { Component, Input } from "@angular/core";

@Component({
  selector: 'maipl-score',
  templateUrl: './maipl-score.component.html',
  styleUrls: ['./maipl-score.component.scss']
})
export class MaiplScoreComponent {
  @Input('score') score: CrtcMaplScore|null = null;

  isActive(category: MaiplCategory) {
    return this.score !== null && this.score[category]
  }
}
