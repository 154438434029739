import { Artist } from './../model';
import { ProgressService } from './../services/progress.service';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LockService } from '../services/lock.service';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'dialog-lock',
  templateUrl: './dialog-lock.component.html',
  styleUrls: ['./dialog-lock.component.scss']
})
export class DialogLockComponent {
  form: FormGroup = new FormGroup({
    comment: new FormControl(''),
  });

  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<DialogLockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private progressService: ProgressService,
    private lockService: LockService ) {

  }


  lock(): void {
    const comment = this.form.get('comment')?.value;
    this.progressService.progressTopic.next({queryInProgress: true})
    this.lockService.lock(this.data?.artist?.id, comment).subscribe({
      next: (result) => {
        this.progressService.progressTopic.next({queryInProgress: false})
        this.data.successCallback?.call(this, true);
      },
      error: (err) => {
        console.error('HTTP Error', err);
        this.progressService.progressTopic.next({queryInProgress: false})
        this.data.successCallback?.call(this, err)
      }
    })
  }

  unlock(): void {
    const comment = this.form.get('comment')?.value;
    this.progressService.progressTopic.next({queryInProgress: true})
    this.lockService.unlock(this.data?.artist?.id, comment).subscribe(
      res => {
        this.progressService.progressTopic.next({queryInProgress: false})
        this.data.successCallback?.call(this, false);
      },
      err => {
        console.error('HTTP Error', err);
        this.progressService.progressTopic.next({queryInProgress: false})
        this.data.successCallback?.call(this, err)
      }
    )
  }
}
