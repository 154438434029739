<div class="mat-typography">
  <h1 mat-dialog-title>Contact Us</h1>
  <div mat-dialog-content>
    <div *ngIf="!contactSent" class="contact">
      <h4 *ngIf="topic != 'registrationKey'">Whenever you face a problem or have ideas about Data Explorer's improvements, we're very much looking forward to your feedbacks!</h4>
      <form [formGroup]="contactForm">
        <div class="two-col-grid">
          <label>Company<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="company" aria-describedby="company" formControlName="company"
              [ngClass]="{ 'is-invalid': isCompanyInvalid() }">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>

          <label>Name<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="name" aria-describedby="name"
              formControlName="name"
              [ngClass]="{ 'is-invalid': isNameInvalid() }">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>

          <label>Email<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="email" aria-describedby="email"
              formControlName="email"
              [ngClass]="{ 'is-invalid': isEmailInvalid() }">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>

          <label>Purpose<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="purpose" name="purpose" class="regular">
              <mat-option *ngFor="let mp of purposes" [value]="mp.id">
                {{mp.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label class="col-form-label">Message<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <textarea matInput rows="6" formControlName="message"></textarea>
            <mat-error>This field is required.</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div *ngIf="contactSent">
      <div class="row">
        <p>Thank you for your message. We will reply to you as soon as possible.</p>
        <p><a class="link-info" (click)="reset()">Send another message</a></p>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-raised-button color="accent"  (click)="submit()" [disabled]="contactSent">Send</button>
  </div>
</div>

<!-- <div class="modal fade" id="{{idModal}}" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> Contact Us</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p *ngIf="topic != 'registrationKey'">Whenever you face a problem or have ideas about Data Explorer's improvements, we're very much looking forward to your feedbacks!</p>
        <div *ngIf="!contactSent">
          <form [formGroup]="contactForm">
            <dl class="row mt-3">
              <dd class="col-md-2 text-end"><label class="col-form-label">Company<span class="text-danger">&nbsp;*</span></label></dd>
              <dt class="col-md-8">
                <input type="text" class="form-control"  aria-label="company" aria-describedby="company"
                       formControlName="company"
                       [ngClass]="{ 'is-invalid': isCompanyInvalid() }">
                <label id="validation-company-error" class="error small form-text invalid-feedback" *ngIf="isCompanyInvalid()">This field is required.</label>
              </dt>
              <div class="col-md-2"></div>

              <dd class="col-md-2 text-end"><label class="col-form-label">Name<span class="text-danger">&nbsp;*</span></label></dd>
              <dt class="col-md-8">
                <input type="text" class="form-control"  aria-label="name" aria-describedby="name"
                       formControlName="name"
                       [ngClass]="{ 'is-invalid': isNameInvalid() }">
                <label class="error small form-text invalid-feedback" *ngIf="isNameInvalid()">This field is required.</label>
              </dt>
              <div class="col-md-2"></div>

              <dd class="col-md-2 text-end"><label class="col-form-label">Email<span class="text-danger">&nbsp;*</span></label></dd>
              <dt class="col-md-8">
                <input type="text" class="form-control"  aria-label="email" aria-describedby="email"
                       formControlName="email"
                       [ngClass]="{ 'is-invalid': isEmailInvalid() }">
                <label class="error small form-text invalid-feedback" *ngIf="isEmailInvalid() && isEmailEmpty()">This field is required.</label>
                <label class="error small form-text invalid-feedback" *ngIf="isEmailInvalid() && isEmailFormatInvalid()">This field must follow a standard email format.</label>
              </dt>
              <div class="col-md-2"></div>

              <dd class="col-md-2 text-end"><label class="col-form-label">Select</label></dd>
              <dt class="col-md-8">
                <select id="selectPurpose" class="form-select" formControlName="purpose">
                  <option *ngFor="let pp of purposes" [ngValue]="pp.id">{{pp.label}}</option>
                </select>
              </dt>
              <div class="col-md-2"></div>


              <dd class="col-md-2 text-end"><label class="col-form-label">Message</label></dd>
              <dt class="col-md-8">
                <textarea id="inputMessage" class="form-control" rows="6" formControlName="message"></textarea>
              </dt>
              <div class="col-md-2"></div>
            </dl>
          </form>
        </div>
        <div *ngIf="contactSent">
          <div class="row">
            <h3>Thank you for your message. </h3>
            <p>We will reply to you as soon as possible.</p>
            <a class="link-info" (click)="reset()">Send another message</a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="submit()">Send</button>
      </div>
    </div>
  </div>
</div> -->
