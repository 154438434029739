import { WorkOfRecording } from './../model';
import { Component, Input, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import {Artist, Recording, Release} from "../model";
import { ResultService } from "../services/result.service";
import {TabsController} from "../_common/tabs.controller";
import {ActivatedRoute, Router} from "@angular/router";
import { MatTabGroup } from '@angular/material/tabs';
import { TableReleasesComponent } from '../table-releases/table-releases.component';
import { TableWorksComponent } from '../table-works/table-works.component';
import { ExportService } from '../services/export.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCrtcComponent } from '../crtc/dialog-crtc/dialog-crtc.component';
import { CrtcService } from '../services/crtc.service';
import { LoginService } from '@githubquansic/web-auth/ng';

@Component({
  selector: 'app-recording',
  templateUrl: './app-recording.component.html',
  styleUrls: ['./app-recording.component.scss']
})
export class AppRecordingComponent extends TabsController implements AfterViewInit, OnChanges {
  @Input() recording:Recording;
  @Input() releases:Release[];
  @Input() works:WorkOfRecording[];
  @Input() artists:Array<Artist>;

  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;
  @ViewChild(TableReleasesComponent) tableReleasesComponent!: TableReleasesComponent;
  @ViewChild(TableWorksComponent) tableWorksComponent!: TableWorksComponent;

  contributors:Array<Artist>;
  mainArtists:Array<Artist> = [];
  performers:Artist[] = [];
  releasesLength:number|null = null;
  worksLength:number|null = null;

  constructor(private resultService:ResultService,
    private exportService: ExportService,
    private loginService: LoginService,
    public crtcService: CrtcService,
    private dialog: MatDialog,
    private router:Router,
    private route:ActivatedRoute) {
    super(router, route);
    this.recording = this.resultService.emptyRecording();
    this.artists = [];
    this.releases = [];
    this.works = [];
    this.contributors = [];
    this.mainArtists = [];

    this.performers = [];
  }

  ngOnChanges() {
    this.extractMainArtist();
    this.extractPerformers();

  }

  ngAfterViewInit(){
    this.readActiveTabFromParam(this.matTabGroup);
  }

  extractMainArtist() {
    const contributors:Artist[] = this.artists || [];
    this.mainArtists = contributors.filter((contributor:Artist) => {
      return contributor.contributorType == "MainArtist"
    });
  }

  extractPerformers() {
    const contributors:Artist[] = this.artists || [];
    this.performers = contributors.filter((contributor:Artist) => {
      return contributor.contributorType != "MainArtist"
    });

    this.performers.forEach((performer) => {
      performer.role = performer.role?.replace(/\|/g, ", ",)
    })
  }

  onReleasesComplete(event:any){
    this.releasesLength = event;
  }

  onWorksComplete(event:any){
    this.worksLength = event;
  }

  exportRecording() {
    this.exportService.exportRecordingAsExcel(this.recording, this.works, this.releases, this.mainArtists, this.performers, this.recording.title)
  }

  // hasPendingChangeRequest$() {
  //   return this.crtcService.hasMaplScoreChangeRequestsPending(this.recording);
  // }
  crtcMaiplScore$() {
    return this.crtcService.maiplScoreStatus$(this.recording)
  }

  openCrtcDetailsDialog() {
    this.dialog.open(DialogCrtcComponent, {
      width: '80vw',
      height: '80vh',
      data: {
        recording: this.recording,
        works: this.works
      }
    })
  }



  // canExport(): boolean {
  //   return this.matTabGroup?.selectedIndex != null  && this.matTabGroup.selectedIndex >= 1 && this.matTabGroup.selectedIndex <= 2;
  // }

  // openExportDialog() {
  //   let exportType = null;
  //   let rows: Release[]|Work[]|null = [];
  //   let exportFn:{
  //     excel: null|((artistName:string, data:(any[])) => void),
  //     csv: null|((artistName:string, data:(any[])) => void)
  //   } = { excel: null, csv: null};

  //   switch(this.matTabGroup.selectedIndex) {
  //     case 1:
  //       exportType = "Release";
  //       rows = [...this.tableReleasesComponent.dataSource.filteredData];
  //       exportFn = {
  //         excel: this.exportService.exportReleasesAsExcel,
  //         csv:  this.exportService.exportReleasesAsCSV
  //       }
  //       break;
  //     case 2:
  //       exportType = "Work";
  //       rows = [...this.tableWorksComponent.dataSource.filteredData];
  //       exportFn = {
  //         excel: this.exportService.exportWorksOfRecordingAsExcel,
  //         csv: this.exportService.exportWorksOfRecordingAsCSV
  //       }
  //     break;
  //   }
  //   this.dialog.open(DialogExportComponent, {
  //     panelClass: "dialog",
  //     data: {entityName: this.recording.title, exportType, rows, exportFn} as DialogExportData,
  //   });
  // }

}
