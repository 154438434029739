import { Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InlineEditService } from "src/app/services/inline-edit.service";

@Component({
  standalone: true,
  template: "<h1 mat-dialog-title>{{data.title}}</h1>\
  <div mat-dialog-content>{{data.content}}</div> \
  <div mat-dialog-actions>\
    <button mat-button mat-dialog-close>Cancel</button>\
    <button mat-raised-button color='warn' (click)='executeAction()'>{{data.action.label}}</button>\
  </div>\
  ",
  imports: [MatButtonModule, MatDialogModule]
})
export class ConfirmDialog{
  inlineEditService = inject(InlineEditService)
  dialogRef = inject(MatDialogRef<ConfirmDialog>)
  data:ConfirmDialogData = inject(MAT_DIALOG_DATA)

  executeAction() {
    this.data.action.callback.apply(this)
    this.dialogRef.close();
  }
}
export type ConfirmDialogData = {
  title: string
  content: string
  action: {
    label: string
    callback: Function
  }
}
