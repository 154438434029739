
 <div class="entity-page">
    <!-- <h1>Artist</h1> -->
    <div class="name-block">
      <h1>Artist:</h1>
      <div class="name">
        <h1>{{artist.name}}</h1>
        <em class="comments">{{artist.comments}}</em>
      </div>
      <button mat-icon-button class="link export" (click)="exportArtist()" title="Download artist data">
        <mat-icon [inline]="true">arrow_circle_down</mat-icon>
      </button>
      <mat-icon inline="true" *ngIf="isDataTestEnvironment" class="lock-icon">{{isLocked? 'lock' : 'lock_open'}}</mat-icon>
      <div *ngIf="isDataTestEnvironment" class="lock-btn">
        <button mat-raised-button class="link lock-btn" (click)="openLockDialog()" title="Lock/Unlock artist data">
          <span *ngIf="isLocked">Unlock</span>
          <span *ngIf="!isLocked">Lock</span>
        </button>
      </div>
    </div>
    <div class="info">
      <div (click)="openCoverDialog()" class="link">
        <img class="circle-mask circle-mask-large" [src]="imageService.convertImagePath(cover) || defaultArtistCover"/>
      </div>
      <div style="flex: 0 1 1em"></div>
      <div class="informations">
        <h2>Informations</h2>
        <div><span class="strong">Type:</span> {{artist.type}}</div>
        <div *ngIf="artist.type == 'Person'"><span class="strong">Gender:</span> {{artist.gender}}</div>
        <div><span class="strong">Nationality:</span> {{artist.nationality}}</div>
        <div><span class="strong">Date of Birth:</span> {{artist.birthdate}}</div>
        <div><span class="strong">Date of Death:</span> {{artist.deathdate}}</div>
      </div>
      <div class="identifiers">
        <h2>Identifiers</h2>
        <div class="identifiers-list two-columns">
          <div class="identifier isni"><span class="strong">ISNI:</span> <artist-identifier [values]="artist.ids.isnis" linkTemplate="https://isni.org/__ID__" idName="ISNI"></artist-identifier></div>
          <div class="identifier ipi"><span class="strong">IPI:</span>  <artist-identifier [values]="artist.ids.ipis" idName="IPI"></artist-identifier></div>
          <div class="identifier ipn"><span class="strong">IPN:</span> <artist-identifier [values]="artist.ids.ipns" idName="IPN"></artist-identifier></div>
          <div class="identifier discogsId"><span class="strong">Discogs Id:</span>  <artist-identifier [values]="artist.ids.discogsIds" linkTemplate="https://www.discogs.com/artist/__ID__" idName="Discogs ID"></artist-identifier></div>
          <div class="identifier musicbrainzId"><span class="strong">Musicbrainz Id:</span>  <artist-identifier [values]="artist.ids.musicBrainzIds" linkTemplate="https://musicbrainz.org/artist/__ID__" idName="MusicBrainz ID"></artist-identifier></div>
          <div class="identifier deezerId"><span class="strong">Deezer Id:</span> <artist-identifier [values]="artist.ids.deezerIds" linkTemplate="https://www.deezer.com/fr/artist/__ID__" idName="Deezer ID"></artist-identifier></div>
          <div class="identifier appleId"><span class="strong">Apple Id:</span> <artist-identifier [values]="artist.ids.appleIds" linkTemplate="https://music.apple.com/artist/__ID__" idName="Apple ID"></artist-identifier></div>
          <div class="identifier spotifyId"><span class="strong">Spotify Id:</span>  <artist-identifier [values]="artist.ids.spotifyIds" linkTemplate="https://open.spotify.com/artist/__ID__" idName="Spotify ID"></artist-identifier></div>
          <div class="identifier wikidataId"><span class="strong">Wikidata Id:</span>  <artist-identifier [values]="artist.ids.wikidataIds" linkTemplate="https://www.wikidata.org/wiki/__ID__" idName="Wikidata ID"></artist-identifier></div>
          <div class="identifier amazonId"><span class="strong">Amazon Id:</span>  <artist-identifier [values]="artist.ids.amazonIds" linkTemplate="https://music.amazon.com/artists/__ID__" idName="Amazon ID"></artist-identifier></div>
          <div class="identifier mergedIsni"><span class="strong">Merged ISNI:</span>  <artist-identifier [values]="artist.ids.mergedIsnis" linkTemplate="https://isni.org/__ID__" idName="Merged ISNI"></artist-identifier></div>
        </div>
      </div>
      <div class="spacer"></div>
      <div *ngIf="crtcService.isCrtcUser()">
        <changelog [entityId]="artist.ids.quansicId"></changelog>
      </div>
    </div>
    <div class="lists">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            Relationships
          </ng-template>
          <ng-container *ngTemplateOutlet="relationships;context:{artist: artist}" ></ng-container>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div>Releases</div>
            <div class="data-count" *ngIf="tableReleasesComponent">({{tableReleasesComponent.dataSource.data.length}})</div>
          </ng-template>
          <table-releases [releases]="releases"></table-releases>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div>Recordings</div>
            <div class="data-count" *ngIf="tableRecordingsComponent">({{tableRecordingsComponent.dataSource.data.length}})</div>
          </ng-template>
          <table-recordings [recordings]="recordings" [artist]="artist" ></table-recordings>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <div>Works</div>
            <div class="data-count"  *ngIf="tableWorksComponent">({{tableWorksComponent.dataSource.data.length}})</div>
          </ng-template>
          <table-works [works]="works"></table-works>
        </mat-tab>
      </mat-tab-group>
      <div class="export">
        <button mat-icon-button class="link" (click)="openExportRowsDialog()" [disabled]="!canExport()" title="Download artist data">
          <mat-icon [inline]="true">arrow_circle_down</mat-icon>
        </button>
      </div>
    </div>
 </div>

 <ng-template #relationships let-artist="artist">
  <div class="relationships" *ngIf="artist.relationships.aka.length > 0">
    <div class="title">Also Known As:</div>
    <div class="grid grid-four-col">
      <party-link [party]="rel" *ngFor="let rel of artist.relationships.aka | sortBy:'asc':'name'"></party-link>
    </div>
    <hr class="grid-col-span-2"/>
  </div>
  <div class="relationships" *ngIf="artist.relationships.isMemberOf.length > 0">
    <div class="title">Is Member Of:</div>
    <div class="grid grid-four-col" >
      <ng-container *ngFor="let rel of artist.relationships.isMemberOf | sortBy:'asc':'name'">
        <party-link [party]="rel"></party-link>
      </ng-container>
    </div>
    <hr class="grid-col-span-2"/>
  </div>
  <div class="relationships" *ngIf="artist.relationships.hasMember.length > 0">
    <div class="title">Members:</div>
    <div class="grid grid-four-col">
      <party-link [party]="rel" *ngFor="let rel of artist.relationships.hasMember | sortBy:'asc':'name'"></party-link>
    </div>
  </div>
  <div class="relationships" *ngIf="artist.namevariants != null && artist.namevariants != []">
    <div class="title">Name Variants</div>
    <div class="grid grid-four-col">
      <div *ngFor="let namevariant of artist.namevariants | sortBy:'asc':'name'">
        {{namevariant.fullname}} <span *ngIf="namevariant.language">({{namevariant.language.toLowerCase()}})</span>
      </div>
    </div>
  </div>
 </ng-template>
