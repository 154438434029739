import { InlineEditService } from './../inline-edit.service';

import { inject, Injectable } from "@angular/core";
import { getDocs, query, where } from "@firebase/firestore";
import { combineLatest, Observable, of } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { LoginService } from '@githubquansic/web-auth/ng';
import { ResultService } from "../result.service";
import { FirestoreService } from "./firestore.service";
import { Entity } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  // private loginService = inject(LoginService);
  // private firestoreService = inject(FirestoreService);
  private resultService: ResultService = inject(ResultService);
  private inlineEditService:InlineEditService = inject(InlineEditService)

  public userCatalog$: Observable<string[]> = of([
    "Quansic::isni::0000000115935851", // Gainsbourg
    "Quansic::isni::0000000123374070", // Jethro Tull
    "GBN9X1600014" //ISRC Comfortably Numb
  ]);
  public isCurrentInCatalog$: Observable<boolean> = of(false);

  constructor(){
  //   this.initCatalog()
    // this.initCurrentInCatalog();
  }

  // initCatalog() {
  //   this.userCatalog$ = combineLatest([this.firestoreService.usersMetadataDb$, of(this.loginService.getUserData())])
  //   .pipe(
  //     // tap((results: any[]) => console.log(results)),
  //     map((results: any[]) => ({collectionRef: results[0], userData: results[1]})),
  //     filter(({collectionRef, userData}) => userData !== null),
  //     take(1),
  //     tap(({collectionRef, userData}) => console.log(userData)),
  //     switchMap(({collectionRef, userData}) => {
  //       return combineLatest([
  //         userData.id ? getDocs(query(collectionRef,where('userId', '==', userData.id))): of(null),
  //         userData.company ? getDocs(query(collectionRef,where('company', '==', userData.company))): of(null)
  //       ])
  //     }),
  //     map((queryResults: any[]) => ({byUserId: queryResults[0], byCompany: queryResults[1]})),
  //     map(({byUserId, byCompany}) => {
  //       //If a catalog is found by user id
  //       if(byUserId !== null && byUserId.size > 0) {
  //         return (byUserId.docs[0].data() as any).catalog;
  //       }
  //       //if a catalog is found by company
  //       if(byCompany !== null && byCompany.size > 0) {
  //         return (byCompany.docs[0].data() as any).catalog;
  //       }
  //       return null;
  //     }),
  //     tap((catalog: any ) => console.log("catalog", catalog)),
  //   )
  // }

  // initCurrentInCatalog() {
  //   this.isCurrentInCatalog$ = combineLatest([
  //     this.userCatalog$,
  //     this.resultService.entityLoad$,
  //     // of(this.loginService.getUserData())
  //     of(this.inlineEditService.getUserDataEdit())
  //   ]).pipe(
  //     map((results: any[]) => ({catalog: results[0], currentEntity: results[1], userData: results[2]})),
  //     map(({catalog, currentEntity, userData}) => {
  //       console.log("catalog", catalog)
  //       console.log("currentEntity", currentEntity)
  //       if(userData.rights?.dataExplorer.edit?.scope === "all") return true;
  //       if(catalog?.includes(currentEntity?.id)) return true;
  //       return false;
  //     }),
  //     tap(isCurrentInCatalog => {
  //       console.log("check if current entity is in catalog, else disable the edit mode", isCurrentInCatalog);
  //       if(!isCurrentInCatalog) this.inlineEditService.disableEditMode()
  //     })
  //   )
  // }
}
