import { AfterViewInit, Component, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { BehaviorSubject} from "rxjs";

@Component({
  selector: 'table-controls',
  templateUrl: './table-controls.component.html',
  styleUrls: ['./table-controls.component.scss'],
})
export class TableControlsComponent implements OnInit, AfterViewInit{
  filterValue: string = "";
  filteredDataCount = 0;
  totalDataCount = 0;
  paginator$ = new BehaviorSubject<MatPaginator|null>(null);

  @ViewChild(MatPaginator) _paginator!: MatPaginator;

  public filter$ = new BehaviorSubject<string>("");

  ngOnInit() {
    this.filterValue = "";
    this.filter$.next("");
  }

  ngAfterViewInit(): void {
    this.paginator$.next(this._paginator);
  }

  applyFilter(event: Event){
    // const filterValue = (event.target as HTMLInputElement).value;
    this.filter$.next(this.filterValue.trim());
  }

  resetFilter(){
    this.filterValue = "";
    this.filter$.next(this.filterValue.trim());
  }
}
