import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  getBffDomain(): string {
    const apiBaseUrl = environment.apiBaseUrl;
    if (apiBaseUrl === "origin"){
       return window.location.origin;
    }
    return apiBaseUrl;
  }
}
