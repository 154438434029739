import {Component, Input, OnInit} from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.css']
})
export class CopyToClipboardComponent implements OnInit {
  @Input() value=""

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  confirmCopiedToClipboard() {
    // toastr.options = {
    //   "preventDuplicates": true,
    //   "positionClass": "toast-top-center",
    //   "timeOut": "2000",
    // }
    // toastr.info("Copied '" + this.value + "' to clipboard");

    this._snackBar.open("Copied '" + this.value + "' to clipboard", 'X', {
      duration: 5 * 1000,
      verticalPosition: 'top'
    });
  }
}
